import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { requiredFileType } from 'src/app/app-file-upload/upload-file-validators';
import { AuthService } from 'src/app/services/auth-service/auth.service';
@Component({
  selector: 'app-add-reunion',
  templateUrl: './add-reunion.component.html',
  styleUrls: ['./add-reunion.component.scss']
})
export class AddReunionComponent implements OnInit {
  BoardData={
    documentFile: null,
    type: "MEETING",
    libelle: "",
    description:"",
    document: "",
    project: {}
  };
  submitted=false;
  project_name:any;
  formAdd : FormGroup;
  dateCompteReunion: any;
  
  constructor(private formBuilder: FormBuilder,public dialogRef: MatDialogRef<any>,@Inject(MAT_DIALOG_DATA) public data: any, private auth:AuthService) {
    //console.log(data)
    this.project_name = data.projet.libelle
    this.BoardData.project=data.projet
    //console.log(localStorage.getItem("currentUser"))
   }
  

  ngOnInit() {
    // this.formAdd = this.formBuilder.group({
    //   title: ['', Validators.required],
    // });
  }
  files: File[] = [];
 
  onSelect(event) {
    //console.log(event);
    if(this.files.length == 0){
      this.files.push(event.addedFiles);
      console.log('file name '+event.addedFiles[0].name);
    }
  }
  
  onRemove(event) {
    //console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  addMeeting(){
    // this.submitted = true;

    // if (this.formAdd.invalid) {
    //   return;
    // }
    let formData = new FormData()
    formData.append('libelle',"Réunion")
    formData.append('description','')
    if(this.files.length>0){
      formData.append('documentFile',this.files[0][0])
      formData.append('originalName',this.files[0][0].name);
    }
    formData.append('dateMeeting', this.dateCompteReunion);
    formData.append('type','MEETING')
    formData.append('project',this.data.projet['@id'])
    this.auth.addBoard(formData).then((res)=>{
      //console.log(res)
      this.dialogRef.close()
    }).catch((err)=>{
      console.log(err)
    })
  }

  get f() { return this.formAdd.controls; }


  

}
