import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from 'src/app/services/auth-service/auth.service';

@Component({
  selector: 'app-subjects-list',
  templateUrl: './subjects-list.component.html',
  styleUrls: ['./subjects-list.component.scss']
})
export class SubjectsListComponent implements OnInit {
  arraySubject = []
  project_id :any
  action :any
  newProjectSubject = ""
  showAddForm = false
  project : any
  isUpdate : any
  updateOrojectSubject = ""
  isLoaded = false
  currentUser : any
  isAdmin = false
  chosenSubject : any
  updatedSubject : any
  currentUserId : any
  typeproject = "PARENT";
  constructor(@Inject(MAT_DIALOG_DATA) public data, private authService:AuthService, private dialogRef:MatDialogRef<any>) {
    console.log(data)
    this.project_id = data['idProjet']
    this.action = data['action']
    if(data['type'] === "CHILD"){
      this.typeproject = data['type'];
    }
   }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"))
    this.currentUserId = this.currentUser.id
    this.getProjectInfo()
  }


  getProjectInfo(){
    this.arraySubject = []

    if (!this.project_id) {
      return;
    }

    this.authService.getProjectInfo(this.project_id).then((res)=>{
      console.log({res})
      this.arraySubject = res['tags']
      /*this.arraySubject.sort((a, b) => {
        return <any>new Date(a.dateCreated) - <any>new Date(b.dateCreated);
      })*/

      this.arraySubject.sort((a, b) => {
        if ( a.libelle.toLowerCase() < b.libelle.toLowerCase() ){
          return -1;
        }
        if ( a.libelle.toLowerCase() > b.libelle.toLowerCase() ){
          return 1;
        }
        return 0;
      })

      // this.arraySubject.reverse()
      this.isUpdate = Array(this.arraySubject.length).fill(false)
      this.project = res
      // if(this.currentUser['id'] == this.project['creator']['id'] ){
      //   this.isAdmin = true
      // }
      this.project['userProject'].map((user)=>{
        if(user.user && (user.user.id == this.currentUserId) && user.isAdmin){
          this.isAdmin = true
        }
      })
      this.isLoaded = true
    }).catch(err=>console.log(err))
  }

  showAdd(){
    this.showAddForm = !this.showAddForm
  }

  addProjectToArray(){
    // this.arraySubject.push({libelle:this.newProjectSubject})
    if(this.newProjectSubject.trim() == "" || this.newProjectSubject.trim().length == 0){
      alert("Veuillez entrer un espace");
      return;
    }
    this.authService.addTagToProject({
      libelle:this.newProjectSubject,
      project:this.project['@id']
    }).then((res)=>{
      this.newProjectSubject = "";
      console.log({resSujet:res});
      this.chooseSujet(res);
      //this.getProjectInfo();
    }).catch(err=>{
      console.log(err)
    });
  }


  deleteTag(tag_id){
    if(confirm("Voulez vraiment suppprimer l'espace "+tag_id.libelle)) {
      this.authService.deleteTag(tag_id.id).then((res)=>{
        console.log(res)
        this.getProjectInfo();
        this.updatedSubject = tag_id;
      }).catch(err=>console.log(err))
    }
  }

  updateTag(tag, i){
    if (this.updateOrojectSubject.trim().length == 0) {
      alert("Veuillez entrer un espace");
      return;
    }
    this.authService.updateTag(tag.id,{libelle:this.updateOrojectSubject}).then((res)=>{
      console.log(res)
      //this.getProjectInfo();
      this.arraySubject[i].libelle = this.updateOrojectSubject;
      this.updatedSubject = res;
      
      /*
      this.arraySubject.sort((a, b) => {
        if ( a.libelle.toLowerCase() < b.libelle.toLowerCase() ){
          return -1;
        }
        if ( a.libelle.toLowerCase() > b.libelle.toLowerCase() ){
          return 1;
        }
        return 0;
      })*/
      this.updateOrojectSubject = "";
      this.isUpdate[i] = !this.isUpdate[i];
      //this.isUpdate = !this.isUpdate
    }).catch(err=>console.log(err))
  }

  showUpdateTag(i, libelle){
     //this.updateOrojectSubject = "";
     this.updateOrojectSubject = libelle;
     for (var index = 0; index < this.isUpdate.length; index++) {
      if(index != i){
        this.isUpdate[index] = false;
      }
    }
    this.isUpdate[i] = !this.isUpdate[i];
  }


  chooseSujet(sujet){
    this.chosenSubject = sujet
    this.dialogRef.close()
  }


  onNoClick(){
    this.dialogRef.close()
  }

}
