import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'src/app/services/message/message.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  Rtoken: any = '';
  error: any = '';
  tokenConfirm = false;
  message = 'Email Address Confirmed';
  messageEn = 'Adresse e-mail confirmée';
  lang  = 'fr';

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService ) { 
  }

  ngOnInit() {

    this.messageService.getTranslate().subscribe((data) => {
      this.lang = data;
    });

    this.Rtoken = this.route.params['value'].token;

    if (this.authService.logIn) {
      this.router.navigate(['/']);
    }

    if (this.Rtoken) {
      //console.log('Confirmation token for ' + this.Rtoken);
      this.authService.confirm(this.Rtoken)
        .pipe(first())
        .subscribe(
          data => {
            this.tokenConfirm = true;
          },
          err => {
            const error = err.error;
            const errorS = error.message;
            if ( errorS ) {
              this.error = errorS;
            } else {
              this.error = error['hydra:description'];
            }
            console.log(error);
          }
        );
    } else {
      this.router.navigate(['']);
    }
  }

}
