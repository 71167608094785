import { Injectable } from '@angular/core';
import { Observable, Subject, ReplaySubject, BehaviorSubject } from 'rxjs';import { environment } from 'src/environments/environment';
import * as socketIo from 'socket.io-client';
import { Message } from '../socket/model/message';
//const SERVER_URL = 'http://api.face2faces.fr:83/';

const SERVER_URL = environment.SERVER_URL;

@Injectable({ providedIn: 'root' })

export class MessageService {
    private socket;
    private subject = new Subject();
    private subjectbis = new Subject();
    private writeMessage = new Subject();
    private openWriteMessage = new Subject();
    private closeWriteMessage = new Subject();
    private translate = new ReplaySubject();
    private envoyerObs = new Subject();
    private guide = new Subject();
    private projectChosen = new Subject();
    private showCreateProjectTutoIsFalse = new Subject();
    private showProjectTutoIsFalse = new Subject();
    private messageIsSend = new Subject();
    private showCreateProjectTuto = true;
    private soundconfig = true;
    private showProjectTuto = true;
    private showProjectTutobis = true;
    private showNotifTuto = true;
    private reloadNotifAction = new Subject();
    private reloadPropostionNotif = new Subject();
    private reloadBoardNotif = new Subject();
    private reloadActionNotif = new Subject();
    private project_list = [];
    private projectMap = new Map<string, number>();
    private projectMapFace = new Map<string, number>();
    private libelleproject = new ReplaySubject();
    private idproject = undefined;
    private sentNotifCount = new BehaviorSubject<number>(0);
    private receiveNotifCount = new BehaviorSubject<number>(0);
    private copyNotifCount = new BehaviorSubject<number>(0);
    private commentNotifCount = new BehaviorSubject<number>(0);

    public obdisableMessage = new Subject();
    public obdisableMessage$ = this.obdisableMessage.asObservable();

    constructor(){
        //this.initSocket();
        /*
        this.socket.on('disconnect', () => {
          console.log('Déconnexion détectée. Tentative de reconnexion...');
          this.reconnect();
        });*/
    }

    private reconnect(): void {
        // Implémentez votre logique de réconnexion ici
        // Par exemple, vous pouvez essayer de vous reconnecter après un certain délai
        setTimeout(() => {
          this.socket.connect();
        }, 5000); // Réessayez après 5 secondes (ajustez selon vos besoins)
    }

    public initSocket(): void {
        this.socket = socketIo(SERVER_URL);
    }
    
    public getSocket(): any {
        return this.socket;
    }

    public send(message: Message): void {
        this.socket.emit('message', message);
    }

    public sendInstant(message: Message): void {
      this.socket.emit('message', message, message.action);
  }

    public onMessage(): Observable<Message> {
        return new Observable<Message>(observer => {
            this.socket.on('message', (data: Message) => observer.next(data));
        });
    }

    public onEvent(event: Event): Observable<any> {
        return new Observable<Event>(observer => {
            this.socket.on(event, () => observer.next());
        });
    }

    public joinRoom(matchId) {
        try {
          //this.socket = io.connect('http://localhost:3000');
          this.socket.emit('join room', matchId.toString());
        } catch (e) {
         console.log('Could not connect socket.io');
        }
    }

    //------------------------
      subscribeToRoom(roomName: string) {
        this.socket.emit('subscribe', roomName);
      }
    
      unsubscribeFromRoom(roomName: string) {
        this.socket.emit('unsubscribe', roomName);
      }
    
      sendMessage(message: string, roomName: string) {
        this.socket.emit('message', message, roomName);
      }
    
      //onMessage()
      onMessageInstant() {
        return this.socket.fromEvent('message');
      }

      connect() {
        this.socket.connect();
      }


    obsReloadActionNotif(): Observable<any> {
        return this.reloadActionNotif.asObservable();
    }

    nextReloadActionNotif() {
        this.reloadActionNotif.next();
    }

    obsReloadBoardNotif(): Observable<any> {
        return this.reloadBoardNotif.asObservable();
    }

    nextReloadBoardNotif() {
        this.reloadBoardNotif.next();
    }

    nextReloadNotifAction() {
        this.reloadNotifAction.next();
    }

    obsReloadNotifAction(): Observable<any> {
        return this.reloadNotifAction.asObservable();
    }

    nextReloadPropostionNotif() {
        this.reloadPropostionNotif.next();
    }

    obsReloadPropostionNotif(): Observable<any> {
        return this.reloadPropostionNotif.asObservable();
    }
    /*
    sendMessage(message: string) {
        this.subject.next();
    }*/

    messageUpdated() {
        this.subject.next();
    }

    needRefreshFiltre() {
        this.subjectbis.next();
    }

    messageRefreshConv() {
        this.envoyerObs.next();
    }

    getMessageRefreshConv(): Observable<any> {
        return this.envoyerObs.asObservable();
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }

    getRefreshFiltre(): Observable<any> {
        return this.subjectbis.asObservable();
    }

    getWriteMessage(): Observable<any> {
        return this.writeMessage.asObservable();
    }

    setWriteMessage(id) {
        this.writeMessage.next(id);
    }

    getWriteMessageFace(): Observable<any> {
        return this.openWriteMessage.asObservable();
    }

    openWriteMessageFace(openMode) {
       this.openWriteMessage.next(openMode);
    }

    setLoseWriteMessageFace(id){
        this.openWriteMessage.next(id);
    }

    getCloseWriteMessage(openMode){
       this.closeWriteMessage.next(openMode);
    }

    setCloseWriteMessage(): Observable<any> {
       return this.closeWriteMessage.asObservable();
    }

    setTranslate(id) {
        this.translate.next(id);
    }

    getTranslate(): Observable<any> {
        return this.translate.asObservable();
    }

    getLibelleProject(): Observable<any> {
        return this.libelleproject.asObservable();
    }

    setLibelleProject(project) {
        this.libelleproject.next(project);
    }

    getGuide(): Observable<any> {
        return this.guide.asObservable();
    }

    setGuide(id) {
        this.guide.next(id);
    }

    setProjectChosen(id) {
        this.projectChosen.next(id);
    }

    getProjectChosen() {
        return this.projectChosen.asObservable();
    }

   setShowCreateProjectTuto(value) {
        this.showCreateProjectTuto = value;
    }

    setSound(value) {
        this.soundconfig = value;
    }

    getSound() {
       return this.soundconfig;
    }

    getShowCreateProjectTuto() {
        return this.showCreateProjectTuto;
    }

    setShowProjectTuto(value) {
        this.showProjectTuto = value;
    }

    getShowProjectTuto() {
        return this.showProjectTuto;
    }

    setShowProjectTutobis(value) {
        this.showProjectTutobis = value;
    }

    getShowProjectTutobis() {
        return this.showProjectTutobis;
    }

    setShowNotifTuto(value) {
        this.showNotifTuto = value;
    }

    getShowNotifTuto() {
        return this.showNotifTuto;
    }

    getShowCreateProjectTutoIsFalse(): Observable<any> {
        return this.showCreateProjectTutoIsFalse.asObservable();
    }

    setShowCreateProjectTutoIsFalse() {
        this.showCreateProjectTutoIsFalse.next();
    }

    getShowProjectTutoIsFalse(): Observable<any> {
        return this.showProjectTutoIsFalse.asObservable();
    }

    setShowProjectTutoIsFalse() {
        this.showProjectTutoIsFalse.next();
    }

     setMessageIsSend() {
        this.messageIsSend.next();
    }

    getMessageIsSend(): Observable<any> {
        return this.messageIsSend.asObservable();
    }

    getListProject(){
        return this.project_list;
    }

    setListProject(projectList){
        this.project_list = projectList;
    }

    getProjectMap(){
        return this.projectMap;
    }

    setProjectMap(projectMap){
        this.projectMap = projectMap;
    }

    getProjectMapFace(){
        return this.projectMapFace;
    }

    setProjectMapFace(projectMapFace){
        this.projectMapFace = projectMapFace;
    }

    getProjectid(){
        return this.idproject;
    }

    setProjectid(projectid){
        this.idproject = projectid;
    }

    //****************** */
    getSentNotifCount(): Observable<number>{
        return this.sentNotifCount;
    }

    setSentNotifCount(sentNotifCount){
        this.sentNotifCount.next(sentNotifCount);
    }
    getReceiveNotifCount(): Observable<number>{
        return this.receiveNotifCount;
    }

    setRereiveNotifCount(receiveNotifCount){
        this.receiveNotifCount.next(receiveNotifCount);
    }
    getCopyNotifCount(): Observable<number>{
        return this.copyNotifCount;
    }

    setCopyNotifCount(copyNotifCount){
        this.copyNotifCount.next(copyNotifCount);
    }
    getCommentNotifCount(): Observable<number>{
        return this.commentNotifCount;
    }

    setCommentNotifCount(commentNotifCount){
        this.commentNotifCount.next(commentNotifCount);
    }

}