import { NgModule } from '@angular/core';
import {MatSortModule} from '@angular/material/sort';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {
    MatButtonModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatGridListModule,
    MatMenuModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatTabsModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatSlideToggleModule
    } from '@angular/material';

@NgModule({
    // tslint:disable-next-line:max-line-length
    imports: [MatButtonModule, MatCheckboxModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule, MatFormFieldModule, MatInputModule, MatCardModule, MatGridListModule, MatMenuModule, MatTableModule, MatPaginatorModule, MatDialogModule, MatTabsModule, MatExpansionModule, MatSlideToggleModule, MatNativeDateModule, MatDatepickerModule ],
    // tslint:disable-next-line:max-line-length
    exports: [ MatSortModule,MatButtonModule, MatSlideToggleModule, MatCheckboxModule, MatToolbarModule, MatSidenavModule, MatIconModule, MatListModule, MatFormFieldModule, MatInputModule, MatCardModule, MatGridListModule, MatMenuModule, MatTableModule, MatPaginatorModule, MatDialogModule, MatTabsModule, MatExpansionModule, MatNativeDateModule, MatDatepickerModule, MatProgressSpinnerModule]
})

export class MaterialModule {}
