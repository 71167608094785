import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/internal/operators/first';
import { MessageService } from 'src/app/services/message/message.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  RaisonSociale = '';
  AdresseSiegeSocial = '';
  CodePostalSiege = '';
  VilleSiegeSocial = '';
  Pays = '';
  siret = '';
  fname = '';
  lname = '';
  jobT = '';
  email = '';
  passwd = '';
  passwd_confirm = '';
  error = '';
  isRegister = false;
  message = "Inscription terminé ! un lien pour activer votre compte a été envoyé sur votre adresse e-mail, cliquez sur le lien pour activer le compte.";
  messageEn = "Registration finished ! a link to activate your account has been sent to your email address, click on the link to activate the account.";
  lang  = 'fr';
  pconerror = '';
  values = '';
  hide = true;
  mdpValues = '';

  constructor(
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService) { }

  ngOnInit() {
    if (this.authService.logIn) {
      this.router.navigate(['/']);
    }
    this.messageService.getTranslate().subscribe((data) => {
      this.lang = data;
    });
  }

  onKey(event: any) { // without type info
    this.mdpValues += event.target.value + ' | ';
  } 

  registerNu() {
    //console.log('Registering in ' + this.fname + ' ' + this.lname + ' ' + this.jobT + ' ' + this.email + ' ' + this.passwd);
     this.authService.register(this.fname, this.lname, this.jobT, this.email, this.passwd, null)
      .pipe(first())
      .subscribe(
        data => {
          // this.router.navigate(['/']);
          this.isRegister =  true;
        },
        err => {
          const error = err.error;
          const errorS = error.message;
          const errorD = error.detail;
          const errorH = error['hydra:description'];
          if (errorS === 'Bad credentials') {
            this.error = (this.lang == 'fr' ? 'email / mot de passe incorrect' : 'incorrect email / password');
          } else if (errorD === "L'utilisateur n'est pas encore activé. pour l'activer, vous devez cliquer sur le lien de confirmation du mail que vous avez reçu") {
            this.error = errorD;
          } else if (errorH) {
            this.error = errorH;
          }
          //console.log(error);
        }
    );

  }

}
