import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterTag'
})

export class FilterPipeTag implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) { return []; }
    if (!searchText) { return items; }
    searchText = searchText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return items.filter(it => {
		if (it['tag']) {
			return it['tag']['libelle'].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText) || it['tag']['libelle'].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText);
		}
		else
		{
			return it['libelle'].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText) || it['libelle'].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText);
		}	
    });
  }
}
