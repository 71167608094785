import { Component, AfterViewInit, ViewChild, Input,ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { merge, Observable, of as observableOf, Subject } from 'rxjs';
import { catchError, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MessageService } from 'src/app/services/message/message.service';
import { FormControl } from '@angular/forms';
import { AddPlanActionComponent } from '../add-plan-action/add-plan-action.component';
import { UpdateReunionDialogComponent } from '../update-reunion-dialog/update-reunion-dialog.component';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'app-plan-action',
  templateUrl: './plan-action.component.html',
  styleUrls: ['./plan-action.component.scss']
})
export class PlanActionComponent implements AfterViewInit, OnDestroy {

  messages: any = [];
  resultsLength = 0;
  perPage = 30;
  isLoadingResults = false;
  isRateLimitReached = false;
  lastDate = new Date('01/01/4970');
  filterChoosen = null;

 
  
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Output() EventLaunchSearch = new EventEmitter();
  @Output() EventLaunchSearchDoc = new EventEmitter();
  @Input() ged: boolean = false;

  transmitter = null;
  recipient = null;
  projectId = null;
  dateBefore = null;
  dateAfter = null;
  tag = null;
  statut = null;
  type = null;
  keyWord = null;
  smartNumber = null;
  modeDoc = false;
  contacts: Array<any> = [];
  projectLists: Array<any> = [];
  projectTags: any;
  currentUser = null;
  saveCtc = null;
  saveProject = null;
  saveTheme   = null;
  ID:any
  projectFull:any
  showTool:any
  displayedColumns: string[] = [
    'Date',
    'Numéro',
    'Titre',
    'Actions',
    'Action'
  ];
  dataSource = []
  constructor(private route:ActivatedRoute,
    private authService:AuthService,
    private router:Router,
    private dialog:MatDialog,
    private messageService:MessageService) { }

    ngAfterViewInit() {
    this.route.paramMap.subscribe(params => {
      this.ID = params.get('ID');
    })
    this.getCurrentProject()
    this.getAllMessages(null)
  }
  ngOnDestroy(){
    
  }

  getCurrentProject(){
    if (!this.ID) {
      return;
    }

    this.authService.getTheProject(this.ID)
      .subscribe(res => {
        this.projectFull = res;})
  }

  backToProject(){
    this.router.navigateByUrl('/project/'+this.ID)
  }

  openDialog(): void {
      
    this.showTool = false; 
    this.messageService.setShowCreateProjectTuto(false);
    this.messageService.setShowCreateProjectTutoIsFalse();

    const dialogRef = this.dialog.open(AddPlanActionComponent, {
      width: '700px',
      data: {projetName: this.projectFull.libelle}
    });

    dialogRef.afterClosed().subscribe(result => {
       /*console.log('this.objectif '+result.objectif);
       console.log('this.projetName '+result.projetName);*/
      
    })
}





public getAllMessages(searchChoose) {

  // this.paginator.pageIndex = 0;
  // this.filterChoosen = searchChoose;

  // merge(this.paginator.page)
  //   .pipe(
  //     startWith({}),
  //     switchMap(() => {
  //       this.isLoadingResults = true;
  //       return this.authService.getAllMessages(this.paginator.pageIndex, this.perPage, this.filterChoosen);
  //     }),
  //     map(data => {
  //       // Flip flag to show that loading has finished.
  //       this.isLoadingResults = false;
  //       this.isRateLimitReached = false;    

  //       this.resultsLength = data['hydra:totalItems']; 

  //       return data['hydra:member'];
  //     }),
  //     catchError(() => {
  //       this.isLoadingResults = false;
  //       // Catch if the GitHub API has reached its rate limit. Return empty data.
  //       this.isRateLimitReached = true;
  //       return observableOf([]);
  //     })
  //   ).subscribe(data => { 
       
  //     //console.log('contenu de data '+ JSON.stringify(data));
  //     this.messages = [];
  //     this.lastDate = new Date('01/01/4970');
      
  //     data.forEach(element => {
  //       console.log({element})
  //       this.messages.push(this.dataMap(element));
  //     });

  //     let afterIsNewDay = false;
  //     this.messages.reverse().forEach((element, index) => {
  //       if(afterIsNewDay || index == 0){
  //         this.messages[index]["afterIsNewDay"] = true;
  //         afterIsNewDay = false;
  //         //console.log("afterIsNewDay")
  //       }
  //       if(element.isNewDay == true){
  //         afterIsNewDay = true;
  //       }
  //     });

  //     this.dataSource = this.messages.reverse(); 
       

  //   });

  this.dataSource=[]
  for(let i=0;i<7;i++){
    let isNew 
    let actions = true
    if(i==2){
      isNew = false
      actions = false
    } else if(i==4) {
      isNew = false  
      actions = false
    }else{
      isNew = true
      actions = true
    }
    this.dataSource.push({
      'id': i,
      'date':new Date(),
      'sender':new Date(),
      'isNewDay':isNew,
      'Action':'test',
      'actions':actions,
      'numero':i,
      'titre':'test'
    })
  }

}




dataMap(data: any) {

  var strId = data['project']['@id']; 
  var splittedID = strId.split("/"); 
  var idProject = splittedID[splittedID.length-1];  
  var listTag : any[] = data['tags'];
  var date = data['dateCreated'];
  var isNewDay = this.isNewDay(date);

  var typeMessage = 'QUESTION';

  if (data['type'] == 'QUESTION') {
    typeMessage = 'Question';
  }
  else if (data['type'] == 'COMMENT'){
    typeMessage = 'Commentaire';
  }
  else if (data['type'] == 'ANSWER'){
    typeMessage = 'Réponse';
  }

  const message: any = {
    'id': idProject,
    'project': data['project'],
    'tag': (listTag[0] ? listTag[0].libelle  : '' ),
    'date': date,
    'typeParse': typeMessage,
    'type': data['type'],
    'private': data['isPrivate'],
    'sender': (data['author'] ? data['author']['fullName'] : ''),
    'recipient': (data['recipient'] ? data['recipient']['fullName'] : ''),
    'read': data['isMessageAnswered'],
    'deadline': data['deadline'],
    'number': data['number'],
    'smartNumber': data['smartNumber'],
    'filegeneric': data['filegeneric'],
    'isMessageDisabled': data['isMessageDisabled'],
    'isMessageAnswered': (data['isMessageAnswered'] === true ? 'Répondu' : 'Non répondu'),
    'isNewDay' : isNewDay
  };

  return message;
}


isRoundFirst(afterIsNewDay, isNewDay){
  if(afterIsNewDay && isNewDay){
    return "divcontaine afterIsNewDayBL afterIsNewDayTL";
  }else if(afterIsNewDay){
    return "divcontaine afterIsNewDayBL";
  }else if(isNewDay){
    return "divcontaine afterIsNewDayTL";
  }
  return "divcontaine";
}

isRoundLast(afterIsNewDay, isNewDay){
  if(afterIsNewDay && isNewDay){
    return "divcontaine afterIsNewDayBR afterIsNewDayTR";
  }else if(afterIsNewDay){
    return "divcontaine afterIsNewDayBR";
  }else if(isNewDay){
    return "divcontaine afterIsNewDayTR";
  }
  return "divcontaine";
}

isNewDay(messageDate){
  let currentDate = new Date(messageDate.substring(0,10)); 
  //console.log('this.lastDate ==> '+this.lastDate);
  //console.log('currentDate ==> '+currentDate);
  if(currentDate < this.lastDate)
  {
    // console.log('Une section ==> '+ currentDate);
    this.lastDate = currentDate;
    return true;
  } 
  else
  { 
    this.lastDate = currentDate; 
    return false;
  }
}

getContact(){
  this.loadContact();
}

loadContact(url?: string){
  url = url ? url : undefined;
  this.authService.getContacts(url).subscribe(res => {
    this.contacts = this.contacts.concat(res['hydra:member']);
    
    //this.contacts = res['hydra:member'];
    if(this.contacts.length && this.contacts[0].id != this.currentUser.id){
      this.contacts.unshift(this.currentUser);
    }
    this.contacts.sort(this.compare);

    if(res['hydra:view']['hydra:next']){
      this.loadContact(res['hydra:view']['hydra:next'].replace("/api", ""));
    }
  }); 
}

compare( a, b ) {
  if ( a.familyName < b.familyName ){
    return -1;
  }
  if ( a.familyName > b.familyName ){
    return 1;
  }
  return 0;
}

loadProject(url?: string){
  url = url ? url : undefined;
  this.authService.getProjects(url).subscribe(res => {
    this.projectLists = this.projectLists.concat(res['hydra:member']);
    if(res['hydra:view']['hydra:next']){
      this.loadProject(res['hydra:view']['hydra:next'].replace("/api", ""));
    }
  }); 
}

getSujet(idProject){
  this.projectId = idProject;
  this.authService.getTheProject(idProject).subscribe(res => {
    this.projectTags = res['tags'];
  }, (error) => {
     //console.log(' getTheProject subscribe error '+error.statusText);
  });
}







public refreshInfoFilter(){
  this.getContact();
  this.loadProject();  
}


openUpdateDialog(element) {
  const dialogRef = this.dialog.open(UpdateReunionDialogComponent, {
    width: '700px',
    data: { projetName: this.projectFull.libelle, element: element , type:'plan'}
  });

  dialogRef.afterClosed().subscribe(result => {
    /*console.log('this.objectif '+result.objectif);
    console.log('this.projetName '+result.projetName);*/

  })
}
openDeleteDialog(element) {
  const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
    width: '700px',
    data: { projetName: this.projectFull.libelle, element: element , type:'plan' }
  });

  dialogRef.afterClosed().subscribe(result => {
    /*console.log('this.objectif '+result.objectif);
    console.log('this.projetName '+result.projetName);*/

  })
}

specifyPlan(reunion){
  this.router.navigateByUrl('/project/' + this.ID + '/meet/' + reunion.id)
}
}
