import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/services/message/message.service';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {
  email = '';
  error = '';
  errorrenew = '';
  passIsRecover = false;
  lang  = 'fr';
  step1 = true;
  Rtoken: any = '';
  password: any = '';
  passwd_confirm = '';
  isNew = false;
  hide = true;

  constructor( private authService: AuthService, private router: Router, private messageService: MessageService
   ) { }

  ngOnInit() {
    this.step1 = true;
    if (this.authService.logIn) {
      this.router.navigate(['/']);
    }
    this.messageService.getTranslate().subscribe((data) => {
      this.lang = data;
    });
  }

  recoverPass() {
    //console.log( 'recovering password for ' + this.email );
    this.authService.recoverPw(this.email)
      .pipe(first())
      .subscribe(
        data => {
          this.error = '';
          this.passIsRecover = true;
          this.step1 = false;
        },
        err => {
          const error = err.error;
          const errorS = error.message;
          this.error = error['hydra:description'];
        }
      );
  }

  setnewPass() {
    if( this.password != this.passwd_confirm) {
      alert("les mots de passe sont incompatibles");
      return;
    }
    //console.log('Canging password with ' + this.password);
    this.authService.setNew( this.password, this.Rtoken )
      .pipe(first())
      .subscribe(
        () => {
          this.errorrenew = '';
          this.isNew = true;
          // console.log(data);
        },
        err => {
          const error = err.error;
          this.errorrenew = error['hydra:description'];
          //console.log(this.error);
        }
      );
  }

}
