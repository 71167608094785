import { Component, ElementRef, Input, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatPaginator, MatTable } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { MessageService } from 'src/app/services/message/message.service';
import { AddReunionComponent } from '../add-reunion/add-reunion.component';
import { merge, Observable, of as observableOf, Subject } from 'rxjs';
import { catchError, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { UpdateReunionDialogComponent } from '../update-reunion-dialog/update-reunion-dialog.component';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { DeleteActionComponent } from '../delete-action/delete-action.component';
import { SubjectsListComponent } from '../subjects-list/subjects-list.component';
import { DeleteDocumentConfirmationComponent } from '../delete-document-confirmation/delete-document-confirmation.component';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-actions-by-meet',
  templateUrl: './actions-by-meet.component.html',
  styleUrls: ['./actions-by-meet.component.scss']
})
export class ActionsByMeetComponent implements OnInit {
  docsUrl = environment.server_url
  actionBody = {
    libelle: "",
    description: "",
    documentFile: [],
    document: "",
    deadline: "",
    relance1: "",
    relance2: "",
    relance3: "",
    status: "WAITING",
    board: "",
    manager: {},
    resource: {},
    tag: {},
    priority:""
  }
  team: any
  tags: any
  messages: any = [];
  isLoadingResults = false;
  isRateLimitReached = false;
  resultsLength = 0;
  perPage = 30;
  ID: any
  lastDate = new Date('01/01/4970');
  lastDateHeader = new Date("01/01/2000"); 
  projectFull: any
  currentMeet: any
  @Input() meet;
  positionClick: any
  displayClick = 'none'
  updateElement = {
    libelle: "",
    description: "",
    documentFile: [],
    document: "",
    deadline: "",
    relance1: "",
    relance2: "",
    relance3: "",
    status: "WAITING",
    board: "",
    manager: {},
    resource: {},
    tag: {},
    priority:""
  }
  countTasks = []
  countTasksGreen = []
  updateElementData: any
  updateActionId: any
  displayedColumns: string[] = [
    'delai',
    //'Priorite',
    'date',
    'Ligne',
    //'numero',
    'manager',
    'responsable',
    'sous-canal',
    'Action',
    //'soustaches',
    'document',
    'status',
    'actions'
  ];
  Task_id: any
  dataSource = []
  positionTaches: any
  displayTaches = 'none'
  leftPos: any
  board_id: any
  isUpdate = false
  adminsTeam = []
  //@ViewChild('updateBlock', { read: ElementRef }) elementView: ElementRef;
  marginHeight: any
  viewHeight: number;
  displayFileName: any
  currentUser: any
  currentUserId: any
  line: any
  @ViewChild(MatTable, {static: true}) table: MatTable<any>;
  isAdmin = false
  board: any
  boardName: any
  canFace : any
  hiddenCreateActon = false;
  priorityArray: any = [];

  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private messageService: MessageService,
    private elRef: ElementRef) {

    this.currentMeet = this.meet;

  }



  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUserId = this.currentUser.id;

    for (var i = 1; i < 100; i++) {
      this.priorityArray.push(""+i+"");
    }

    this.route.queryParams.subscribe(queryParams => {
      //console.log("////", queryParams)
      if (queryParams.action) {
        this.showTaches({ pageY: 400 }, { action_id: queryParams.action })
      }
      if (queryParams.line) {
        this.line = queryParams.line
      }
    });
    this.route.paramMap.subscribe(params => {
      //console.log({ actionsparams: params })
      this.ID = params.get('ID');
      this.board_id = params.get('IDMeet');
      this.getCurrentProject();
      if (this.board_id) {
        this.authService.getBoardbyId(this.board_id).subscribe((res) => {
          //console.log({ boarddd: res })
          this.boardName = res;
          this.actionBody.board = res['@id'];
          this.board = res['@id'];
        },(error) => {
           //console.log(' getTheProject subscribe error '+error.statusText);
        }); 
      };
      this.getAllMessages(1);
    })
    
    /*this.getCurrentProject()

    if (this.board_id) {
     
      this.authService.getBoardbyId(this.board_id).subscribe((res) => {
        //console.log({ boarddd: res })
        this.boardName = res
        this.actionBody.board = res['@id']
        this.board = res['@id']
      },(error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
      }); 

     
    }
    this.getAllMessages(null)*/


  }
  
  routeToFace2Facde(element) {
    this.router.navigate(['meet', this.ID, 'face2face', element.action_id])
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1.fullName === o2.fullName
  }
  compareTags(o1: any, o2: any): boolean {
    return o1.id === o2.id
  }

  move(event, element) {
    this.isUpdate = true
    this.updateElementData = element
    //console.log({mydata:this.updateElementData})
    this.updateElement.board = this.board
    this.updateElement.tag = element.sujet
    this.updateElement.status = element.managerAction
    this.updateElement.deadline = element.delai
    this.updateElement.manager = element.manager
    this.updateElement.resource = element.responsable
    this.updateElement.relance1 = element.relance1
    this.updateElement.relance2 = element.relance2
    this.updateElement.relance3 = element.relance3
    this.updateElement.priority = element.priority    
    // this.updateElement.description = element.Action
    this.updateElement.libelle = element.Action
    this.updateActionId = element.action_id   
    //console.log({element:this.updateElement})
    //console.log({ top: event.pageX, left: event.pageY })
    this.leftPos = event.pageX + 'px'
    let pos = event.pageY - 290
    this.positionClick = pos + 20 +'px'
    this.displayClick = 'block'
  }

  showTaches(event, element) {
    //console.log(element)
    this.Task_id = element.action_id
    //console.log({ parent: this.Task_id })
    // this.viewHeight = this.elementView.nativeElement.offsetHeight;
    //console.log(event)
    let pos = event.pageY - 250
    this.positionTaches = pos + 10 + 'px'
    this.displayTaches = 'block'
    let margin = 400 + pos
    this.marginHeight = margin + 'px'
  }

  cancelTache() {
    this.displayTaches = 'none'
    this.marginHeight = "0px"
    this.getAllMessages(1)
  }

  cancelupdate() {
    this.isUpdate=false
    this.displayClick = 'none'
  }

  hasLine(element){
    if(this.line && element.action_id == this.line){
        return 'LineTrue';
    }
    else
    {
      return 'LineFalse';
    }
  }

  getCollaborateurPriority(collab){
    this.priorityArray = [];
    for (var i = 1; i < 100; i++) {
      this.priorityArray.push(""+i+"");
    }
    for (var i = 0; i < this.dataSource.length; i++) {
      if(this.dataSource[i].responsable && (this.dataSource[i].responsable.id == collab.id))
      {
        const index = this.priorityArray.indexOf(this.dataSource[i].priority);
        if (index > -1) {
          this.priorityArray.splice(index, 1);
        }
      }
    }
  }

  getCurrentProject() {

    if (!this.ID) {
      return;
    }

    this.authService.getTheProject(this.ID)
      .subscribe(res => {

        this.projectFull = res;
        this.projectFull['userProject'].map((userItem) => {
          if (userItem.user && (userItem.user.id == this.currentUserId) && userItem.isAdmin) {
            this.isAdmin = true
          }
        })

        this.tags = this.projectFull.tags;
        //this.team = this.projectFull.userProject

        this.team = [];

        for (var i = 0; i <  this.projectFull.userProject.length; i++) {
          if (this.projectFull.userProject[i].user) {
            this.team.push(this.projectFull.userProject[i]);
          } 
        }

        this.adminsTeam = this.projectFull.userProject.filter(item => item.isAdmin );

        //console.log({userTeam : this.team});

      })
  }

  nextPage(event) {
    //console.log(event);
    this.getAllMessages(event.pageIndex + 1);
  }

  public getAllMessages(pageIndex) {

    if (!this.ID || !this.board_id) {
      return;
    }
  

    this.dataSource = []
    this.messages = []
    this.countTasks = []
    this.countTasksGreen = []
    this.authService.getBoardDetails(this.ID, this.board_id, this.perPage, pageIndex).then((res) => {
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
      this.resultsLength = res['hydra:totalItems'];
      this.dataSource = res['hydra:member']
      //this.lastDate = new Date('01/01/4970');
       this.lastDate = new Date('01/01/2000');
      this.lastDateHeader = new Date("01/01/2000");
      this.dataSource.forEach(element => {
        if (element["deadline"]) {
          if (this.isNewDayHeader(element["deadline"]) == true) { 
            this.messages.push({
              'headerSection': true,
              'isNewDay': true,
              'delai': element["deadline"],
              'date': element["dateCreated"]
            });
          }
          this.messages.push(this.dataMap(element));
        }
      });
      let afterIsNewDay = false;
      this.messages.reverse().forEach((element, index) => {
        if (afterIsNewDay || index == 0) {
          this.messages[index]["afterIsNewDay"] = true;
          afterIsNewDay = false;
        }
        if (element.isNewDay == true) {
          afterIsNewDay = true;
        }
      });
      this.dataSource = this.messages.reverse();

      this.countTasks = Array(this.dataSource.length).fill(0)
      this.canFace = Array(this.dataSource.length).fill(true)
      this.countTasksGreen = Array(this.dataSource.length).fill(0)
      this.dataSource.map((item, i) => {
        if (item.headerSection == false) {
          this.authService.getActionTasks(item.action_id).then((res) => {
            //console.log(i,res['hydra:totalItems'])
            if(res['hydra:totalItems']>0){
              this.canFace[i] = false
            }
            let row = 1
            let x = 0     
            res['hydra:member'].map((it) => { 
              if(it.status == 'DONE'){
                this.countTasksGreen[i]++
              }      
              while (x < res['hydra:member'].length) {   
                if (res['hydra:member'][x].row == row) {
                  this.countTasks[i]++
                  row++;
                  x = 0;
                } else {
                  x++
                }
              }
            })
          })
        }
      })
      this.countTasks = this.countTasks.reverse()
      this.canFace = this.canFace.reverse()
      this.countTasksGreen = this.countTasksGreen.reverse()


      /*for (var i = 0; i < this.dataSource.length; i++) {
        const index = this.priorityArray.indexOf(this.dataSource[i].priority);
        if (index > -1) {
          this.priorityArray.splice(index, 1);
        }
      }*/

  
      this.table.renderRows();

    }).catch((err) => {
      this.isLoadingResults = false;
      this.isRateLimitReached = true;
      return observableOf([]);
    })
    //this.table.renderRows();
  }
  backToProject() {
    this.router.navigateByUrl('/reunion/' + this.ID)
  }

  fcthiddenCreateActon(){
    if (this.hiddenCreateActon == false)
      this.hiddenCreateActon = true;
    else
      this.hiddenCreateActon = false;
  }

  isRoundFirst(headerSection ,afterIsNewDay, isNewDay) {
    if (headerSection == true) {
      return 'tdClasstrue';
    }
    else if (afterIsNewDay && isNewDay) {
      return "tdClassfalse afterIsNewDayBL afterIsNewDayTL";
    } else if (afterIsNewDay) {
      return "tdClassfalse afterIsNewDayBL";
    } else if (isNewDay) {
      return "tdClassfalse afterIsNewDayTL";
    }
    return "tdClassfalse";

  }

  isRoundLast(headerSection, afterIsNewDay, isNewDay) {
    if (headerSection == true) {
      return 'tdClasstrue';
    }
    else if (afterIsNewDay && isNewDay) {
      return "tdClassfalse afterIsNewDayBR afterIsNewDayTR";
    } else if (afterIsNewDay) {
      return "tdClassfalse afterIsNewDayBR";
    } else if (isNewDay) {
      return "tdClassfalse afterIsNewDayTR";
    }
    return "tdClassfalse";
  }

/*
  isRoundFirst(afterIsNewDay, isNewDay) {
    if (afterIsNewDay && isNewDay) {
      return "divcontaine afterIsNewDayBL afterIsNewDayTL";
    } else if (afterIsNewDay) {
      return "divcontaine afterIsNewDayBL";
    } else if (isNewDay) {
      return "divcontaine afterIsNewDayTL";
    }
    return "divcontaine";
  }

  isRoundLast(afterIsNewDay, isNewDay) {
    if (afterIsNewDay && isNewDay) {
      return "divcontaine afterIsNewDayBR afterIsNewDayTR";
    } else if (afterIsNewDay) {
      return "divcontaine afterIsNewDayBR";
    } else if (isNewDay) {
      return "divcontaine afterIsNewDayTR";
    }
    return "divcontaine";
  }
*/

getMediaLink(mediaUrl){
  return this.authService.getMediaDataPAD(mediaUrl);
}


dataMap(data: any) {

    var strId = data['project']['@id'];
    var splittedID = strId.split("/");
    var idProject = splittedID[splittedID.length - 1];
    var date = data['dateCreated'];
    var isNewDay = this.isNewDay(date);

    var nbProposition = 0;
    // this.getDetailAction(data['resource'],data['manager'],data)
    for (var i = 0; i < data["messages"].length; i++) {
      if(data["messages"][i].type == "QUESTION"){
        nbProposition +=1;
      }
    }

    //console.log({ sujet: data })
    const message: any = {
      'date': date,
      'isNewDay': isNewDay,
      'board_id': data['@id'],
      'numero': data['line'],
      'sujet': data['tag'],
      'Action': data['libelle'],
      'responsable': data['resource'],
      'manager': data['manager'],
      'delai': data['deadline'],
      'doc': data['document'],
      'responsableAction': data['status'],
      'managerAction': data['status'],
      'action_id': data['id'],
      'creator_id': data['creator']['id'],
      'status': data['status'],
      'nbProposition': nbProposition,
      'messages':data['messages'],
      'headerSection' : false,
      'relance1': data['relance1'],
      'relance2': data['relance2'],
      'relance3': data['relance3'],
      'priority': data['priority']
    };
    //console.log({ message })
    return message;


  }

  isNewDayHeader(messageDate) {
    let currentDate = new Date(messageDate.substring(0, 10));
    if (currentDate > this.lastDateHeader) {
      this.lastDateHeader = currentDate;
      return true;
    } else {
      this.lastDateHeader = currentDate;
      return false;
    }
  }

  isNewDay(messageDate) {
    let currentDate = new Date(messageDate.substring(0, 10));
    //if (currentDate < this.lastDate) {
    if (currentDate > this.lastDate) {
      this.lastDate = currentDate;
      return true;
    }
    else {
      this.lastDate = currentDate;
      return false;
    }
  }


  addActionbis(){

    /*if (!this.actionBody.deadline && !this.actionBody.priority) {
      alert('Veuillez choisir une date limite ou une priorité');
      return;
    }*/

    if (!this.actionBody.deadline) {
      alert('Veuillez choisir une date limite');
      return;
    } 

    if (!this.actionBody.libelle) {
      alert('Veuillez entrer une action');
      return;
    }

    if (!this.actionBody.tag) {
      alert('Veuillez choisir un thème');
      return;
    }

    if (!this.actionBody.manager) {
      alert('Veuillez choisir un Manager');
      return;
    }

    if (!this.actionBody.resource) {
      alert('Veuillez choisir un porteur');
      return;
    }


    /*if (!this.actionBody.priority) {
      alert('Veuillez choisir priorité');
      return;
    }*/

    //console.log('valeur de this.actionBody.priority => '+ this.actionBody.priority);

    let formData = new FormData()
    formData.append('libelle', this.actionBody.libelle)
    formData.append('description', this.actionBody.libelle)
    if (this.actionBody.deadline) {
      formData.append('deadline', this.actionBody.deadline)
    }
    if (this.actionBody.relance1) {
      formData.append('relance1', this.actionBody.relance1)
    }
    if (this.actionBody.relance2) {
      formData.append('relance2', this.actionBody.relance2)
    }
    if (this.actionBody.relance3) {
      formData.append('relance3', this.actionBody.relance3)
    }
    formData.append('status', this.actionBody.status)
    formData.append('board', this.actionBody.board)
    formData.append('manager', this.actionBody.manager['@id'])
    formData.append('resource', this.actionBody.resource['@id'])
    formData.append('tag', this.actionBody.tag['@id'])
    if (this.actionBody.priority) {
      formData.append('priority', this.actionBody.priority)
    }
    if (this.actionBody.documentFile[0]) {
      formData.append('documentFile', this.actionBody.documentFile[0]);
    }
    this.authService.addAction(formData).then((res) => {
      //console.log(res)
      this.actionBody.libelle = "";
      this.actionBody.documentFile = [];
      this.displayFileName = "";
      this.getAllMessages(1);
      this.messageService.nextReloadActionNotif();
    }).catch((err) => {
      //console.log(err)
    })

  }

  addAction() {

    /*if (!this.actionBody.deadline && !this.actionBody.priority) {
      alert('Veuillez choisir une date limite et une priorité');
      return;
    }*/

    if (!this.actionBody.deadline) {
      alert('Veuillez choisir une date limite');
      return;
    }

    if (!this.actionBody.libelle) {
      alert('Veuillez entrer une action');
      return;
    }

    if (!this.actionBody.tag) {
      alert('Veuillez choisir un thème');
      return;
    }

    if (!this.actionBody.manager) {
      alert('Veuillez choisir un Manager');
      return;
    }

    if (!this.actionBody.resource) {
      alert('Veuillez choisir un porteur');
      return;
    }
    /*if (!this.actionBody.priority) {
      alert('Veuillez choisir priorité');
      return;
    }*/

    //console.log('valeur de this.actionBody.priority => '+ this.actionBody.priority);

    let formData = new FormData()
    formData.append('libelle', this.actionBody.libelle)
    formData.append('description', this.actionBody.libelle)
    if (this.actionBody.deadline) {
      formData.append('deadline', this.actionBody.deadline)
    }
    if (this.actionBody.relance1) {
      formData.append('relance1', this.actionBody.relance1)
    }
    if (this.actionBody.relance2) {
      formData.append('relance2', this.actionBody.relance2)
    }
    if (this.actionBody.relance3) {
      formData.append('relance3', this.actionBody.relance3)
    }
    formData.append('status', this.actionBody.status)
    formData.append('board', this.actionBody.board)
    formData.append('manager', this.actionBody.manager['@id'])
    formData.append('resource', this.actionBody.resource['@id'])
    formData.append('tag', this.actionBody.tag['@id'])
    if (this.actionBody.priority) {
      formData.append('priority', this.actionBody.priority)
    }
    if (this.actionBody.documentFile[0]) {
      formData.append('documentFile', this.actionBody.documentFile[0]);
    }
    this.authService.addAction(formData).then((res) => {
      //console.log(res)
      this.actionBody = {
        libelle: "",
        description: "",
        documentFile: [],
        document: "",
        deadline: "",
        relance1: "",
        relance2: "",
        relance3: "",
        status: "WAITING",
        board: this.board,
        manager: {},
        resource: {},
        tag: {},
        priority:""
      }
      this.displayFileName = ""
      this.getAllMessages(1);
      this.messageService.nextReloadActionNotif();
    }).catch((err) => {
      //console.log(err)
    })
  }

  resetAddForm() {
    this.actionBody = {
      libelle: "",
      description: "",
      documentFile: [],
      document: "",
      deadline: "",
      relance1: "",
      relance2: "",
      relance3: "",
      status: "WAITING",
      board: this.board,
      manager: {},
      resource: {},
      tag: {},
      priority:""
    }
  }

  appendDeadline(event) {
    //console.log(event)
    this.actionBody.deadline = event.value
  }

  responsableSansManager() {

    if (!this.team) {
      return [];
    }

    var arrayResponsableSansManager = [];
    for (var i = 0; i < this.team.length; i++) {
      if(this.team[i].user['@id'] != this.actionBody.manager['@id']){
        arrayResponsableSansManager.push(this.team[i]);
      }
    }
    return arrayResponsableSansManager;
  }

  managerSansResponsable() {

    if (!this.adminsTeam) {
      return [];
    }

    var arrayManagerSansResponsable = [];
    for (var i = 0; i < this.adminsTeam.length; i++) {
      if(this.adminsTeam[i].user['@id'] != this.actionBody.resource['@id']){
        arrayManagerSansResponsable.push(this.adminsTeam[i]);
      }
    }
    return arrayManagerSansResponsable;
  }


  responsableSansManagerbis() {

    if (!this.team) {
      return [];
    }

    var arrayResponsableSansManager = [];
    for (var i = 0; i < this.team.length; i++) {
      if(this.team[i].user['@id'] != this.updateElement.manager['@id']){
        arrayResponsableSansManager.push(this.team[i]);
      }
    }
    return arrayResponsableSansManager;
  }

  managerSansResponsablebis() {

    if (!this.adminsTeam) {
      return [];
    }

    var arrayManagerSansResponsable = [];
    for (var i = 0; i < this.adminsTeam.length; i++) {
      if(this.adminsTeam[i].user['@id'] != this.updateElement.resource['@id']){
        arrayManagerSansResponsable.push(this.adminsTeam[i]);
      }
    }
    return arrayManagerSansResponsable;
  }

  onSelectResponse(event){
    event.addedFiles.map((item)=>{
      this.actionBody.documentFile = [];
      this.actionBody.documentFile.push(item);
    })   
  }

  onRemove(event) {
    this.actionBody.documentFile.splice(this.actionBody.documentFile.indexOf(event), 1);
  }

  onSelectResponseUpdate(event){
    event.addedFiles.map((item)=>{
      this.updateElement.documentFile = [];
      this.updateElement.documentFile.push(item);
    })   
  }

  onRemoveUpdate(event) {
    this.updateElement.documentFile.splice(this.updateElement.documentFile.indexOf(event), 1);
  }

  readURL(input) {
    //console.log(input.files)
    if (input.files.length > 0) {
      this.actionBody.documentFile = input.files[0]
    }
    this.displayFileName = input.files[0].name

  }

  readURLUpdate(input, element) {
    //console.log(input.files)
    let formData = new FormData()
    formData.append("documentFile", input.files[0])
    this.authService.appendDocumentAction(element.action_id, formData).then((res) => {
      //console.log(res)
      this.getAllMessages(1)
    }).catch(err => console.log(err))
  }

  deleteActionDocument(element) {

    const dialogRef = this.dialog.open(DeleteDocumentConfirmationComponent, {
      width: '700px',
      data: { projet: this.projectFull, element: element, type: "action" }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getAllMessages(1)
      /*console.log('this.objectif '+result.objectif);
      console.log('this.projetName '+result.projetName);*/

    })
  }


  updateAction() {
    //console.log({tag:this.updateElement.tag})
    // let formData = new FormData()
    // formData.append('libelle', this.updateElement.libelle)
    // // formData.append('description', this.updateElement.libelle)
    // // formData.append('deadline', "")
    // formData.append('status', this.updateElement.status)
    // formData.append('board', this.updateElement.board)
    // formData.append('manager', this.updateElement.manager['@id'])
    // formData.append('resource', this.updateElement.resource['@id'])
    // formData.append('tag', this.updateElement.tag['@id'])

    if (this.updateElement.documentFile[0]) 
    {
      this.authService.updateAction(this.updateActionId, {
        libelle:this.updateElement.libelle,
        description:this.updateElement.libelle,
        deadline:this.updateElement.deadline,
        board:this.updateElement.board,
        manager:this.updateElement.manager['@id'],
        resource:this.updateElement.resource['@id'],
        tag:this.updateElement.tag['@id'],
        relance1:this.updateElement.relance1,
        relance2:this.updateElement.relance2,
        relance3:this.updateElement.relance3,
        priority:this.updateElement.priority,
        documentFile:this.updateElement.documentFile[0]
      }).then((res) => {
        //console.log(res)
        this.displayClick = 'none'
        this.isUpdate = false
        this.getAllMessages(1)
      }).catch((err) => {
        console.log(err)
      })
    }
    else 
    {
      this.authService.updateAction(this.updateActionId, {
        libelle:this.updateElement.libelle,
        description:this.updateElement.libelle,
        deadline:this.updateElement.deadline,
        board:this.updateElement.board,
        manager:this.updateElement.manager['@id'],
        resource:this.updateElement.resource['@id'],
        tag:this.updateElement.tag['@id'],
        relance1:this.updateElement.relance1,
        relance2:this.updateElement.relance2,
        relance3:this.updateElement.relance3,
        priority:this.updateElement.priority
      }).then((res) => {
        //console.log(res)
        this.displayClick = 'none'
        this.isUpdate = false
        this.getAllMessages(1)
      }).catch((err) => {
        console.log(err)
      })
    }
  }

  openDeleteDialog(element) {
    const dialogRef = this.dialog.open(DeleteActionComponent, {
      width: '700px',
      data: { projetName: this.projectFull.libelle, element: element }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getAllMessages(1)
      /*console.log('this.objectif '+result.objectif);
      console.log('this.projetName '+result.projetName);*/

    })
  }


  openAddSubjectModal() {
    const dialogRef = this.dialog.open(SubjectsListComponent, {
      width: '700px',
      data: { idProjet: this.ID }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed result '+result);
      //TO Do dialog ajout membre 
      //console.log(dialogRef.componentInstance.chosenSubject);
      if (dialogRef.componentInstance.chosenSubject) {
        this.actionBody.tag = dialogRef.componentInstance.chosenSubject;
      }
    });
  }

  updateOpenAddSubjectModal() {
    const dialogRef = this.dialog.open(SubjectsListComponent, {
      width: '700px',
      data: { idProjet: this.ID }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed result '+result);
      //TO Do dialog ajout membre 
      //console.log(dialogRef.componentInstance.chosenSubject);
      this.updateElement.tag = dialogRef.componentInstance.chosenSubject;
    });
  }


  goToEcheance(): void{

    
    const dialogRef = this.dialog.open(DeadLineDialog, {
      width: '500px',
      data:{}
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed result => '+ result);
      if (result) {
        
        this.actionBody.deadline = result.deadline;

        if (result.relaunchArray.length == 1 ) {
          this.actionBody.relance1 = result.relaunchArray[0];
        }
        else  if (result.relaunchArray.length == 2 ) {
          this.actionBody.relance1 = result.relaunchArray[0];
          this.actionBody.relance2 = result.relaunchArray[1];
        }
        else  if (result.relaunchArray.length == 3 ) {
          this.actionBody.relance1 = result.relaunchArray[0];
          this.actionBody.relance2 = result.relaunchArray[1];
          this.actionBody.relance3 = result.relaunchArray[2];
        }
        
        //console.log({actionBody: this.actionBody});

      }
    });

  }

   goToEcheancebis(): void{

    
    const dialogRef = this.dialog.open(DeadLineDialog, {
      width: '500px',
      data:{}
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed result => '+ result);
      if (result) {
        
        this.updateElement.deadline = result.deadline;

        if (result.relaunchArray.length == 1 ) {
          this.updateElement.relance1 = result.relaunchArray[0];
        }
        else  if (result.relaunchArray.length == 2 ) {
          this.updateElement.relance1 = result.relaunchArray[0];
          this.updateElement.relance2 = result.relaunchArray[1];
        }
        else  if (result.relaunchArray.length == 3 ) {
          this.updateElement.relance1 = result.relaunchArray[0];
          this.updateElement.relance2 = result.relaunchArray[1];
          this.updateElement.relance3 = result.relaunchArray[2];
        }
        
        //console.log({updateElement: this.updateElement});

      }
    });

  }


}


@Component({
  templateUrl: './deadLineDialog.html',
  styleUrls: ['./deadLineDialog.scss'],
  providers: [DatePipe]
})
export class DeadLineDialog {
  
  deadline: Date | null;
  relaunchSevenDaysBefore: Date | null;
  relaunchThreeDaysBefore: Date | null;
  relaunchOnenDayBefore: Date | null;

  constructor(
    public dialogRef: MatDialogRef<DeadLineDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private datePipe: DatePipe) {


  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  validate(): void {

    if (!this.deadline) {
      alert('veuillez choisir une échéance');
      return;
    }

    var deadLineJson : any = {
      'deadline': this.deadline,
      'relaunchArray': [],
    };

    if (this.relaunchSevenDaysBefore) {
      deadLineJson['relaunchArray'].push(this.datePipe.transform(this.relaunchSevenDaysBefore, 'yyyy-MM-dd'));
    }

    if (this.relaunchThreeDaysBefore) {
      deadLineJson['relaunchArray'].push(this.datePipe.transform(this.relaunchThreeDaysBefore, 'yyyy-MM-dd'));
    }

    if (this.relaunchOnenDayBefore) {
      deadLineJson['relaunchArray'].push(this.datePipe.transform(this.relaunchOnenDayBefore, 'yyyy-MM-dd'));
    }

    console.log({dateD:deadLineJson});

    this.dialogRef.close(deadLineJson);
  
  }
 

}

