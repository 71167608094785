import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-instant',
  templateUrl: './search-instant.component.html',
  styleUrls: ['./search-instant.component.scss']
})
export class SearchInstantComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
