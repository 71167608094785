import { Component, ElementRef, Input, Output, OnInit, AfterViewInit, ViewChild, OnDestroy} from '@angular/core';
import { MatDialog, MatPaginator, MatTable } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { MessageService } from 'src/app/services/message/message.service';
import { AddReunionComponent } from 'src/app/project/dashboard/add-reunion/add-reunion.component';
import { merge, Observable, of as observableOf, Subject } from 'rxjs';
import { catchError, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { UpdateReunionDialogComponent } from 'src/app/project/dashboard/update-reunion-dialog/update-reunion-dialog.component';
import { DeleteConfirmationDialogComponent } from 'src/app/project/dashboard/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { DeleteActionComponent } from 'src/app/project/dashboard/delete-action/delete-action.component';
import { SubjectsListComponent } from 'src/app/project/dashboard/subjects-list/subjects-list.component';
import { DeleteDocumentConfirmationComponent } from 'src/app/project/dashboard/delete-document-confirmation/delete-document-confirmation.component';
import { environment } from 'src/environments/environment';
import { FormControl } from "@angular/forms";
import { PageEvent } from "@angular/material/paginator";

@Component({
  selector: 'app-notifs-actions-bis',
  templateUrl: './notifs-actions-bis.component.html',
  styleUrls: ['./notifs-actions-bis.component.scss']
})
export class NotifsActionsBisComponent implements OnInit {
  
  messages: any = [];
  dataSource: any;
  resultsLength = 0;
  perPage = 10;
  isLoadingResults = false;
  isRateLimitReached = false;
  lastDate = new Date("01/01/2000");
  lastDateHeader = new Date("01/01/2000"); 
  filterChoosen = null;
  redirectA: any;
  docsUrl = environment.server_url;
  displayedColumns: string[] = [
    //"Priority",
    "Délai",
    "Date",
    "Ligne",
    //"Responsable",
    "Manager",
    "Projet",
    "Sous-canal",
    "Action",
    //"Statut",
    "actions",
    "actionsPAD"
  ];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Input() ged: boolean = false;

  transmitter = null;
  recipient = null;
  projectId = null;
  dateBefore = null;
  dateAfter = null;
  tag = null;
  statut = null;
  type = null;
  keyWord = null;
  smartNumber = null;
  modeDoc = false;
  contacts: Array<any> = [];
  projectLists: Array<any> = [];
  projectTags: any;
  currentUser = null;
  saveCtc = null;
  saveProject = null;
  saveTheme = null;

  projectFilter = "";
  managerFilter = "";
  responsableFilter = "";
  statusFilter :any;
  subjectFilter = "";
  startDateFilter = "";
  EndDateFilter = "";
  DocumentFilter = "";
  pageEvent: PageEvent;
  projectBoard: any;
  lineFilter: any;
  public recipientCtrl: FormControl = new FormControl();
  public projectCtrl: FormControl = new FormControl();
  public themeCtrl: FormControl = new FormControl();
  public lineCtrl: FormControl = new FormControl();
  public startCtrl: FormControl = new FormControl();
  public endCtrl: FormControl = new FormControl();
  lang  = 'fr';
  protected _onDestroy = new Subject<void>();
  @ViewChild(MatTable, {static: true}) table: MatTable<any>;
  pageSize = 10;
  initedData = [];
  firstCall = true;

  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private messageService: MessageService,
    private elRef: ElementRef) {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    delete currentUser.token;
    delete currentUser.refresh_token;
    delete currentUser.roles;
    this.currentUser = currentUser;

  }

  ngOnInit() {
    this.firstCall = true;
    this.getAllActions(null);
    this.messageService.nextReloadNotifAction();

    this.messageService.getTranslate().subscribe((data) => {
      this.lang = data;
    });

  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngAfterViewInit() {
   
  }

  getAllActions(searchChoose) {

    this.paginator.pageIndex = 0;
    this.filterChoosen = searchChoose;

    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.authService.getAllActionsNotRead(this.currentUser.id, this.paginator.pageIndex, this.perPage, this.filterChoosen);
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;    

          this.resultsLength = data['hydra:totalItems']; 

          return data['hydra:member'];
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => { 
        
        //console.log({ dataAll });
        this.messages = [];
        //this.lastDate = new Date("01/01/4970");
        this.lastDate = new Date("01/01/2000");
        this.lastDateHeader = new Date("01/01/2000"); 
        data.forEach((element) => {
          if (element["deadline"]) {
            if (this.isNewDayHeader(element["deadline"]) == true) {
              this.messages.push({
                headerSection: true,
                isNewDay: true,
                deadline: element["deadline"],
                date: element["dateCreated"]
              });
            }
            this.messages.push(this.dataMap(element));
          }
        });
        
        let afterIsNewDay = false;
        this.messages.reverse().forEach((element, index) => {
          if (afterIsNewDay || index == 0) {
            this.messages[index]["afterIsNewDay"] = true;
            afterIsNewDay = false;
          }
          if (element.isNewDay == true) {
            afterIsNewDay = true;
          }
        });

        //console.log({ allProps: this.allPropos });
        this.dataSource = this.messages.reverse();
        
        /*if (this.firstCall == true ) {// permet de tout mettre a read 
          this.firstCall = false;
          this.authService.takeActionsBisNotificationsOff().subscribe((res) => {
            this.messageService.nextReloadNotifAction();
          }); 
        }*/

      });

  }

  dataMap(data: any) {
  
    var listTag = data["tag"];
   
    var isNewDay = this.isNewDay(data["deadline"]);

    // this.getDetailAction(data['resource'],data['manager'],data)

    var nbpropositions = 0;
    // this.getDetailAction(data['resource'],data['manager'],data)
    for (var i = 0; i < data["messages"].length; i++) {
      if(data["messages"][i].type == "QUESTION"){
        nbpropositions +=1;
      }
    }

    const message: any = {
      projectId: data["project"]["@id"].replace('/api/projects/', ''),
      project: data["project"],
      tag: listTag ? listTag.libelle : "",
      date: data["dateCreated"],
      type: data["@type"],
      isNewDay: isNewDay,
      Manager: data["manager"],
      Responsable: data["resource"],
      sujet: data["tag"],
      statut: data["status"],
      Action: data["libelle"],
      deadline: data["deadline"],
      actionDetail: data,
      smartNumber: data["line"],
      line: data["line"],
      messages: data["messages"],
      action_id: data['id'],
      isOpenProp: true,
      nbProposition: nbpropositions,
      headerSection: false,
      priority: (data["priority"] ? data["priority"] : "")
    };

    return message;
  }

  redirectActionRes(action) {
    this.authService
      .getRiderectAction(action.actionDetail.board, environment.server_url)
      .then((res) => {
        console.log({Action:res});
        location.href =
          "/#/project/" +
          action["projectId"] +
          "/meet/" +
          res["id"] +
          "?line=" +
          action.actionDetail.id;
      });
  }

  isRoundFirst(headerSection ,afterIsNewDay, isNewDay) {
    if (headerSection == true) {
      return 'tdClasstrue';
    }
    else if (afterIsNewDay && isNewDay) {
      return "tdClassfalse afterIsNewDayBL afterIsNewDayTL";
    } else if (afterIsNewDay) {
      return "tdClassfalse afterIsNewDayBL";
    } else if (isNewDay) {
      return "tdClassfalse afterIsNewDayTL";
    }
    return "tdClassfalse";

  }

  isRoundLast(headerSection, afterIsNewDay, isNewDay) {
    if (headerSection == true) {
      return 'tdClasstrue';
    }
    else if (afterIsNewDay && isNewDay) {
      return "tdClassfalse afterIsNewDayBR afterIsNewDayTR";
    } else if (afterIsNewDay) {
      return "tdClassfalse afterIsNewDayBR";
    } else if (isNewDay) {
      return "tdClassfalse afterIsNewDayTR";
    }
    return "tdClassfalse";
  }

  getSatutMessageResponsableWaitting(element){
    if (element.messages.length == 0) {
      if (this.lang == 'fr') {
        return "À proposer N°1";
      }
      else if (this.lang == 'en') {
        return "Suggestion N°1";
      }
      else
      {
        return "À proposer N°1";
      }
    }
    else if (element.messages[0] && (element.messages[element.messages.length-1].actionStatus == 'CANCELED')) 
    {
      if (this.lang == 'fr') {
        return "À proposer N° "+(element.nbProposition+1);
      }
      else if (this.lang == 'en') {
        return "Suggestion N° "+(element.nbProposition+1);
      }
      else
      {
        return "À proposer N° "+(element.nbProposition+1);
      }
    }
    else
    {
      if (this.lang == 'fr') {
        return "En attente validation  "+(element.nbProposition > 0 ? "N°"+element.nbProposition : "N°1");
      }
      else if (this.lang == 'en') {
        return "Awaiting approval  "+(element.nbProposition > 0 ? "N°"+element.nbProposition : "N°1");
      }
      else
      {
        return "En attente validation  "+(element.nbProposition > 0 ? "N°"+element.nbProposition : "N°1");
      }
    }
  }

  getSatutMessageManagerWaitting(element){
    if (element.messages[element.messages.length-1] && (element.messages[element.messages.length-1].type == 'QUESTION')) {
      if (this.lang == 'fr') {
        return  "À valider "+(element.nbProposition > 0 ? "N°"+element.nbProposition : "N°1");
      }
      else if (this.lang == 'en') {
        return  "To be validated "+(element.nbProposition > 0 ? "N°"+element.nbProposition : "N°1");
      }
      else
      {
        return  "À valider "+(element.nbProposition > 0 ? "N°"+element.nbProposition : "N°1");
      }
    }
    else
    {
      if (this.lang == 'fr') {
        return "En attente N° "+(element.nbProposition+1);
      }
      else if (this.lang == 'en') {
        return "Waiting N° "+(element.nbProposition+1);
      }
      else
      {
        return "En attente N° "+(element.nbProposition+1);
      }
    }
  }


   isNewDayHeader(messageDate) {
    let currentDate = new Date(messageDate.substring(0, 10));
    if (currentDate > this.lastDateHeader) {
      this.lastDateHeader = currentDate;
      return true;
    } else {
      this.lastDateHeader = currentDate;
      return false;
    }
  }


  isNewDay(messageDate) {
    let currentDate = new Date(messageDate.substring(0, 10));
    //console.log('this.lastDate ==> '+this.lastDate);
    //console.log('currentDate ==> '+currentDate); 
    if (currentDate > this.lastDate) {
      //console.log('Une section ==> '+ currentDate);
      this.lastDate = currentDate;
      return true;
    } else {
      this.lastDate = currentDate;
      return false;
    }
  }

  routeToFace2Face(element) {
    //console.log('projectid==>'+element.projectId);
    //this.router.navigate(['meet', element.projectId, 'face2face', element.action_id])

    var lastProposition = null;

    if (element.messages.length > 0) {
      lastProposition = {numberAction: 0};
      for (var i = 0; i < element.messages.length; i++) {
        if((element.messages[i].type == 'QUESTION') && (element.messages[i].numberAction >= lastProposition.numberAction)){
            lastProposition = element.messages[i];
        }
      } 
    }

    if (lastProposition != null && element.statut != 'DONE') {
      location.href = "/#/meet/" + element.projectId + "/face2face/" + element.action_id + "?face=" + lastProposition.id;
    }
    else
    {
      location.href = "/#/meet/" + element.projectId + "/face2face/" + element.action_id;
    }

  }

}
