import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingScreenService {

  private _loading = true;
  private _cnx$ = new BehaviorSubject<boolean>(false);
  loadingStatus: Subject<any> = new Subject();

  get loading(): boolean {
    return this._loading;
  }

  set loading(value) {
    this._loading = value;
    this.loadingStatus.next(value);
  }

  setConnexionAlert(bool: boolean) {
    this._cnx$.next(bool);
  }

  getCnx(){
    return this._cnx$.asObservable();
  }

  startLoading() {
    this.loading = true;
  }

  stopLoading() {
    this.loading = false;
  }
}

