import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter} from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { MessageService } from 'src/app/services/message/message.service';

export interface searchFilter {
  transmitter: string;
  recipient: string;
  projectId: string;
  dateBefore: any;
  dateAfter: any;
  tag: string;
  statut: boolean;
  type: string;
  keyWord: string;
  smartNumber: string;
}


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
	
  //@ViewChild('selectProject') selectProject: ElementRef;
  
  public iDProjet: any;

  @Output() EventLaunchSearch = new EventEmitter();
  @Output() EventLaunchSearchDoc = new EventEmitter();

  transmitter = null;
  recipient = null;
  projectId = null;
  dateBefore = null;
  dateAfter = null;
  tag = null;
  statut = null;
  type = null;
  keyWord = null;
  smartNumber = null;
  modeDoc = false;
  openMode = false;
  contacts: Array<any> = [];
  projectLists: Array<any> = [];
  projectTags: any;
  chosenFilter: searchFilter;
  

  constructor(private authService: AuthService, private router: Router,private messageService: MessageService) {}

  ngOnInit() {
    if(!this.iDProjet)// no id project if it's the dashboard
    {
  	  this.getContact();
  	  this.loadProject();
    }
    else
    {
      this.getContact();
      this.getSujet(this.iDProjet);
    }

    this.authService.obprojectChosen.subscribe((idMessage) =>{ 
      this.smartNumber = idMessage;
      //console.log('dans le smarnumber idmessage ==> '+idMessage);
    });

    this.messageService.getWriteMessageFace().subscribe(
      (openMode)=>{
        this.openMode = openMode;
      }
    );
  }
 

  getContact(){

    if(!this.iDProjet) 
    {
  	  /*this.authService.getContacts().subscribe( dataContact => {
        this.contacts = dataContact['hydra:member'];
      });*/
      this.loadContact();
    }
    else
    {
      this.authService.getTheProject(this.iDProjet)
      .subscribe(res => {      
        var tmpArrayUser = res['userProject'];
        this.contacts = [];
        for (var i = 0; i < tmpArrayUser.length; i++) {
          if(tmpArrayUser[i].user){
            this.contacts.push(tmpArrayUser[i].user);
          }
        }
      }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
      });
    }
  }

  loadContact(url?: string){
    url = url ? url : undefined;
    this.authService.getContacts(url).subscribe(res => {
      this.contacts = this.contacts.concat(res['hydra:member']);
      //this.contacts = res['hydra:member'];
      if(res['hydra:view']['hydra:next']){
        this.loadContact(res['hydra:view']['hydra:next'].replace("/api", ""));
      }
    }); 
  }

  /*loadProject(){
    this.authService.getProjects().subscribe(res => {
      this.projectLists = res['hydra:member'];
      //console.log('me voila!!!');
    }); 
  }*/

  loadProject(url?: string){
    url = url ? url : undefined;
    this.authService.getProjects(url).subscribe(res => {
      this.projectLists = this.projectLists.concat(res['hydra:member']);
      if(res['hydra:view']['hydra:next']){
        this.loadProject(res['hydra:view']['hydra:next'].replace("/api", ""));
      }
    }); 
  }

  getSujet(idProject){
    this.projectId = idProject;
    this.authService.getTheProject(idProject)
      .subscribe(res => {
        this.projectTags = res['tags'];
      }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
      });
  }

  launchSearch(){

    this.modeDoc = false;

    this.chosenFilter = { 
      transmitter: this.transmitter,
      recipient: this.recipient,
      projectId: this.projectId,
      dateBefore: this.dateBefore,
      dateAfter: this.dateAfter,
      tag: this.tag,
      statut: this.statut, 
      type: this.type,
      keyWord: (this.keyWord ? this.keyWord.trim() : null),
      smartNumber: this.smartNumber
    };
    //console.log(' launchSearch '+ JSON.stringify(this.chosenFilter) );
    this.EventLaunchSearch.emit(this.chosenFilter);

  }

  launchSearchDoc(){

    this.modeDoc = true;

    this.chosenFilter = { 
      transmitter: this.transmitter,
      recipient: this.recipient,
      projectId: this.projectId,
      dateBefore: this.dateBefore,
      dateAfter: this.dateAfter,
      tag: this.tag,
      statut: this.statut,
      type: this.type, 
      keyWord: (this.keyWord ? this.keyWord.trim() : null),
      smartNumber: this.smartNumber
    };
    //console.log(' launchSearchDoc '+ JSON.stringify(this.chosenFilter) );
    this.EventLaunchSearchDoc.emit(this.chosenFilter);

  }

  public resetSearch(){

    this.transmitter = null;
    this.recipient = null;
    this.projectId = null;
    this.dateBefore  = null;
    this.dateAfter  = null;
    this.tag = null;
    this.statut = null;
    this.type = null;
    this.keyWord = null;
    this.smartNumber = null;

    if(this.iDProjet)
    {
      this.getContact();
      this.getSujet(this.iDProjet);
    }

    if(this.modeDoc == false) 
    {
      this.launchSearch();
    }
    else
    {
      this.launchSearchDoc();
    }

  }

  public projecthasChange(newIdProject){

    //console.log(' projecthasChange => '+newIdProject);
    
    this.iDProjet = newIdProject;
    this.transmitter = null;
    this.recipient = null;
    this.projectId = null;
    this.dateBefore  = null;
    this.dateAfter  = null;
    this.tag = null;
    this.statut = null;
    this.type = null;
    this.keyWord = null;
    this.smartNumber = null;

    if(this.iDProjet)
    {
      this.getContact();
      this.getSujet(this.iDProjet);
    }

  }

  public refreshInfoFilter(){
    if(!this.iDProjet)// no id project if it's the dashboard
    {
      this.getContact();
      this.loadProject();
    }
    else
    {
      this.getContact();
      this.getSujet(this.iDProjet);
    }
  }

}
