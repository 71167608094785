import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from 'src/app/services/auth-service/auth.service';
@Component({
  selector: 'app-delete-confirmation-dialog',
  templateUrl: './delete-confirmation-dialog.component.html',
  styleUrls: ['./delete-confirmation-dialog.component.scss']
})
export class DeleteConfirmationDialogComponent implements OnInit {

  element:any
  project_name:any
  type:any
  constructor(public dialogRef: MatDialogRef<any>,@Inject(MAT_DIALOG_DATA) public data: any, private auth:AuthService) {
    //console.log({data})
    this.element=data.element
    this.project_name = data.projetName
    this.type=data.type
  }
  
  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  deleteBoard(){
    this.auth.deleteBoard(this.element.board_id).then((res)=>{
      //console.log(res)
      this.dialogRef.close()
    }).catch((err)=>{
      //console.log(err)
    })
  }

}
