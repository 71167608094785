import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { registerLocaleData, HashLocationStrategy, LocationStrategy, DatePipe, PathLocationStrategy } from '@angular/common';
import {
  MatButtonModule,
  MatCardModule,
  MatGridListModule,
  MatMenuModule,
  MatTableModule,
  MatPaginatorModule,
  MatDialogModule,
  MatTabsModule,
  MatExpansionModule,
  MatProgressSpinnerModule,
  MatBadgeModule,
  MatStepperModule,
  MatProgressBarModule, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS, MatSlideToggleModule, MatSlideToggle, MatRadioButton, MatRadioModule, MatTooltipModule
} from '@angular/material';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';

import localeEn from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';

import { AppRoutingModule, routingComponents } from './services/routing-service/app-routing.module';

import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MaterialModule } from './material';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSelectModule } from '@angular/material/select';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { RegisterComponent } from './auth/register/register.component';
import { LoginComponent } from './auth/login/login.component';
import { ContactComponent } from './pages/contact/contact.component';
import { LegalComponent } from './pages/legal/legal.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { RecoverComponent } from './auth/recover/recover.component';
import { SetnewComponent } from './auth/setnew/setnew.component';
import { TermsOfServiceComponent } from './pages/terms-of-service/terms-of-service.component';
import { croppedimagedialog, ProfileComponent } from './pages/profile/profile.component';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { LoadingScreenInterceptor } from './helpers/loading.interceptor';
import { ConfirmComponent } from './auth/confirm/confirm.component';
import { RenewConfirmationComponent } from './auth/renew-confirmation/renew-confirmation.component';
import { DashboardComponent } from './project/dashboard/dashboard/dashboard.component';
import { DashboardnavComponent, dashboarddialogCompo, tutoCreateProjectdialog, dashboardmemberdialog } from './project/dashboard/nav/dashboardnav.component';
import { DashboardcontentComponent } from './project/dashboard/content/dashboardcontent.component';
import { SearchComponent } from './project/dashboard/search/search.component';
import { DashboardMenuComponent, DashboardDialogComponent, DashboardDialogNotif, InvitationDialogComponent, commonGroupeComponent, tutoStepdialog, DashboardDialogProjectNotif } from './components/dashboard-menu/dashboard-menu.component';
import { UnRegMenuComponent } from './components/un-reg-menu/un-reg-menu.component';
import { SidebarMenuComponent } from './components/sidebar-menu/sidebar-menu.component';
import { FilterPipeProject } from './services/filterPipe/filterProject.pipe';
import { FilterPipeContact } from './services/filterPipe/filterContact.pipe';
import { FilterPipeContactCompany } from './services/filterPipe/filterCompanyContact.pipe';
import { SingleProjectComponent, DialogMemberListe, DialogAddMember, projectInfoDialog, transfertdialog, subjectInfodialog, infoWriteMessageDialog, DeadLineConvDialog, ProfileupdDialog, SubjectListeDialog, CopyListeDialog, RecipientListeDialog } from './project/dashboard/single-project/single-project.component';
import { AppFileUploadComponent } from './app-file-upload/app-file-upload.component';
import { DragDropDirective } from './directives/DragDropDirective';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatChipsModule } from '@angular/material/chips';
import { ConfirmationDialogComponent } from './components/shared/confirmation-dialog/confirmation-dialog.component';
import { LightboxModule } from 'ngx-lightbox';
import { GedComponent, TruncatePipe, scrollPjDialog} from './project/dashboard/ged/ged.component';
import { ActivateAccountcmpComponent } from './activate-accountcmp/activate-accountcmp.component';
import { CustomAccordionComponent } from './custom-accordion/custom-accordion.component';
import { ReadMoreComponent } from './project/dashboard/read-more/read-more.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatFormFieldModule } from '@angular/material';
import { StatistiqueComponent } from './statistique/statistique.component';
import { DiscoverviewComponent } from './discoverview/discoverview.component';
import { PlanActionComponent } from './project/dashboard/plan-action/plan-action.component';
import { AddReunionComponent } from './project/dashboard/add-reunion/add-reunion.component';
import { JournalActionsComponent } from './project/dashboard/journal-actions/journal-actions.component';
import { AddPlanActionComponent } from './project/dashboard/add-plan-action/add-plan-action.component';
import { ReunionByProjectComponent } from './project/dashboard/reunion-by-project/reunion-by-project.component';
import { UpdateReunionDialogComponent } from './project/dashboard/update-reunion-dialog/update-reunion-dialog.component';
import { DeleteConfirmationDialogComponent } from './project/dashboard/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { ActionsByMeetComponent, DeadLineDialog } from './project/dashboard/actions-by-meet/actions-by-meet.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { TachesActionsComponent } from './project/dashboard/taches-actions/taches-actions.component';
import { Face2faceComponent } from './project/dashboard/face2face/face2face.component';
import { DeleteActionComponent } from './project/dashboard/delete-action/delete-action.component';
import { SubjectsListComponent } from './project/dashboard/subjects-list/subjects-list.component';
import { DeleteDocumentConfirmationComponent } from './project/dashboard/delete-document-confirmation/delete-document-confirmation.component';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { NotifsActionsBisComponent } from './notifs-actions-bis/notifs-actions-bis.component';
import { CompteRenduRenunionComponent } from './compte-rendu-renunion/compte-rendu-renunion.component';
import { DialogVideo, DialogVideo2 } from './pages/home/home.component';
import { JournalReunionComponent } from './journal-reunion/journal-reunion.component';
import { NotificationSettingComponent } from './notification-setting/notification-setting.component';
import { JournalActionBisComponent } from './components/journal-action-bis/journal-action-bis.component';
import { EntrepriseManagerComponent } from './entreprise-manager/entreprise-manager.component';
import { CompanyMemberComponent, addMemberCompany } from './company-member/company-member.component';
import { FaceTofacesProposalComponent } from './face-tofaces-proposal/face-tofaces-proposal.component';
import { FacetofacesReunionComponent } from './facetofaces-reunion/facetofaces-reunion.component';
import { SocketService } from 'src/app/services/socket/socket.service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FilterPipeTag } from './services/filterPipe/filterTag.pipe';
import { OtherOptionsComponent } from './project/dashboard/other-options/other-options.component';
import { JournalWorkflowComponent } from './components/journal-workflow/journal-workflow.component';
import { JournalServiceComponent } from './components/journal-service/journal-service.component';
import { JournalKpiComponent } from './components/journal-kpi/journal-kpi.component';
import { SearchInstantComponent } from './project/dashboard/single-project/search-instant/search-instant.component';
import { HomesComponent } from './project/dashboard/homes/homes.component';
import { SubjectDuppComponent } from './project/dashboard/subject-dupp/subject-dupp.component';
import { TransferComponent } from './project/dashboard/single-project/transfer/transfer.component';
import { OutsideClickDirective } from './project/dashboard/single-project/outsideClickDirective';
import { ClickableLinkPipe } from './services/filterPipe/clickableLink.pipe';
import { DashboardinstcontentComponent } from './project/dashboard/instant/dashboardinstcontent/dashboardinstcontent.component';
import { DriveComponent } from './project/dashboard/drive/drive.component';
import { RedirectComponent } from './auth/redirect/redirect.component';
import { LoadingBarComponent } from './project/dashboard/loading-bar/loading-bar.component';
import { CustomAccordionFaceComponent, VideoDialog } from './custom-accordion-face/custom-accordion-face.component';
import { NgxEmojiPickerModule } from 'ngx-emoji-picker';
import { PickerModule } from '@ctrl/ngx-emoji-mart';

registerLocaleData(localeFr, 'fr-FR', localeFrExtra);
registerLocaleData(localeEn, 'en-GB', localeEnExtra);
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};
@NgModule({
  declarations: [
    AppComponent,
    VideoDialog,
    HeaderComponent,
    FooterComponent,
    routingComponents,
    RegisterComponent,
    LoginComponent,
    OutsideClickDirective,
    ContactComponent,
    LegalComponent,
    PrivacyComponent,
    RecoverComponent,
    SetnewComponent,
    TermsOfServiceComponent,
    ProfileComponent,
    LoadingScreenComponent,
    ConfirmComponent,
    RenewConfirmationComponent,
    DashboardComponent,
    DashboardnavComponent,
    DashboardcontentComponent,
    DashboardinstcontentComponent,
    SearchComponent,
    DashboardMenuComponent,
    UnRegMenuComponent,
    SidebarMenuComponent,
    DashboardDialogComponent,
    tutoCreateProjectdialog,
    tutoStepdialog,
    DashboardDialogNotif,
    DashboardDialogProjectNotif,
    //DashboardDialogNotifReunion,
    transfertdialog,
    addMemberCompany,
    dashboardmemberdialog,
    croppedimagedialog,
    InvitationDialogComponent,
    FilterPipeProject,
    ClickableLinkPipe,
    FilterPipeContact,
    FilterPipeTag,
    FilterPipeContactCompany,
    commonGroupeComponent,
    SingleProjectComponent,
    dashboarddialogCompo,
    AppFileUploadComponent,
    DialogMemberListe,
    SubjectListeDialog,
    CopyListeDialog,
    RecipientListeDialog,
    ProfileupdDialog,
    DialogAddMember,
    projectInfoDialog,
    subjectInfodialog,
    infoWriteMessageDialog,
    DeadLineConvDialog,
    DragDropDirective,
    ConfirmationDialogComponent,
    GedComponent,
    TruncatePipe,
    ActivateAccountcmpComponent,
    CustomAccordionComponent,
    ReadMoreComponent,
    StatistiqueComponent,
    DiscoverviewComponent,
    PlanActionComponent,
    AddReunionComponent,
    JournalActionsComponent,
    AddPlanActionComponent,
    ReunionByProjectComponent,
    UpdateReunionDialogComponent,
    DeleteConfirmationDialogComponent,
    ActionsByMeetComponent,
    TachesActionsComponent,
    Face2faceComponent,
    DeleteActionComponent,
    SubjectsListComponent,
    SubjectDuppComponent,
    DeleteDocumentConfirmationComponent,
    NotifsActionsBisComponent,
    CompteRenduRenunionComponent,
    DialogVideo,
    DialogVideo2,
    JournalReunionComponent,
    DeadLineDialog,
    scrollPjDialog,
    NotificationSettingComponent,
    JournalActionBisComponent,
    EntrepriseManagerComponent,
    CompanyMemberComponent,
    FaceTofacesProposalComponent,
    FacetofacesReunionComponent,
    OtherOptionsComponent,
    JournalWorkflowComponent,
    JournalServiceComponent,
    JournalKpiComponent,
    SearchInstantComponent,
    TransferComponent,
    HomesComponent,
    SubjectDuppComponent,
    TransferComponent,
    DashboardinstcontentComponent,
    DriveComponent,
    RedirectComponent,
    LoadingBarComponent,
    CustomAccordionFaceComponent
  ],
  imports: [
    BrowserModule,
    PickerModule,
    NgxEmojiPickerModule,
    MatProgressSpinnerModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    MaterialModule,
    FlexLayoutModule,
    MatSelectModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    MatStepperModule,
    MatChipsModule,
    LightboxModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    MatInputModule,
    PerfectScrollbarModule,
    MatProgressBarModule,
    NgxDropzoneModule,
    ImageCropperModule,
    // configure the imports
    HttpClientModule,
    NgxEmojiPickerModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    DashboardDialogComponent,
    VideoDialog,
    DashboardDialogNotif,
    DashboardDialogProjectNotif,
    //DashboardDialogNotifReunion,
    transfertdialog,
    addMemberCompany,
    dashboardmemberdialog,
    croppedimagedialog,
    commonGroupeComponent,
    InvitationDialogComponent,
    //dashboarddialogCompo,
    tutoCreateProjectdialog,
    tutoStepdialog,
    DialogMemberListe,
    SubjectListeDialog,
    CopyListeDialog,
    RecipientListeDialog,
    ProfileupdDialog,
    DialogAddMember,
    projectInfoDialog,
    subjectInfodialog,
    infoWriteMessageDialog,
    DeadLineConvDialog,
    ConfirmationDialogComponent,
    AddReunionComponent,
    AddPlanActionComponent,
    UpdateReunionDialogComponent,
    DeleteConfirmationDialogComponent,
    JournalWorkflowComponent,
    JournalKpiComponent,
    JournalServiceComponent,
    DeleteActionComponent,
    SubjectsListComponent,
    SubjectDuppComponent,
    OtherOptionsComponent,
    DeleteDocumentConfirmationComponent,
    DialogVideo,
    DialogVideo2,
    DeadLineDialog,
    scrollPjDialog,
    StatistiqueComponent,
    DriveComponent,
    ProfileComponent,
    NotificationSettingComponent,
    TransferComponent
  ],
  exports: [MatButtonModule, MatBadgeModule, MatSlideToggle, MatRadioModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true,
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    },
    {
      provide: LocationStrategy, useClass: PathLocationStrategy
    },
    DatePipe,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    SocketService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
