import {
  Component,
  AfterViewInit,
  ViewChild,
  Input,
  ElementRef,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MatPaginator, MatTable, MatTableDataSource, MatDialog } from "@angular/material";
import { AuthService } from "src/app/services/auth-service/auth.service";
import { forkJoin, merge, Observable, of as observableOf, Subject } from "rxjs";
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {
  catchError,
  map,
  startWith,
  switchMap,
  takeUntil,
} from "rxjs/operators";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { MessageService } from "src/app/services/message/message.service";
import { FormControl } from "@angular/forms";
import { PageEvent } from "@angular/material/paginator";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { environment } from "src/environments/environment";
import { DeleteActionComponent } from 'src/app/project/dashboard/delete-action/delete-action.component';


export interface searchFilter {
  transmitter: string;
  recipient: string;
  trieType: string;
  projectId: string;
  dateBefore: any;
  dateAfter: any;
  tag: string;
  statut: boolean;
  type: string;
  keyWord: string;
  smartNumber: string;
  isActionMessage: boolean;
}

@Component({
  selector: 'app-journal-action-bis',
  templateUrl: './journal-action-bis.component.html',
  styleUrls: ['./journal-action-bis.component.scss'],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ]
})
export class JournalActionBisComponent  implements AfterViewInit, OnDestroy, OnInit {
  messages: any = [];
  dataSource: any;
  resultsLength = 0;
  managerLength = 0;
  porterLength = 0;
  perPage = 20;
  isLoadingResults = false;
  isRateLimitReached = false;
  lastDate = new Date("01/01/3000");
  lastDateHeader = new Date("01/01/3000");
  filterChoosen = null;
  redirectA: any;
  docsUrl = environment.server_url;
  secteur = "";
  displayedColumns: string[] = [
    "Date",
    "Manager",
    "Responsable",
    "Projet",
    "Sous-canal",
    /* "Délai", */
    //"Priority",
    //"ligne",
    //"Action",
    "actions",
    "tache_number",
    "deadline",
    "Statut"
    //"actionsPAD"
  ];

  /*displayedColumnsMsg: string[] = [
    "type",
    "date",
    "responsable",
    "manager",
    "message",
    "statut",
    "consult",
  ];*/

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Output() EventLaunchSearch = new EventEmitter();
  @Output() EventLaunchSearchDoc = new EventEmitter();
  @Input() ged: boolean = false;

  transmitter = null;
  recipient = null;
  projectId = null;
  trieType = null;
  dateBefore = null;
  dateAfter = null;
  tag = null;
  statut = null;
  type = null;
  keyWord = null;
  smartNumber = null;
  modeDoc = false;
  contacts: Array<any> = [];
  projectLists: Array<any> = [];
  contactsExp: Array<any> = [];
  contactsRecip: Array<any> = [];
  projectTags: any;
  chosenFilter: searchFilter;
  currentUser = null;
  saveCtc = null;
  saveCtcbis = null;
  saveProject = null;
  saveTheme = null;
  hiddenSearchEngin = true;
  projectFilter = "";
  managerFilter = "";
  responsableFilter = "";
  statusFilter :any;
  subjectFilter = "";
  startDateFilter = "";
  EndDateFilter = "";
  DocumentFilter = "";
  pageEvent: PageEvent;
  projectBoard: any;
  lineFilter: any;
  public recipientCtrl: FormControl = new FormControl();
  public emmetterCtrl: FormControl = new FormControl();
  public projectCtrl: FormControl = new FormControl();
  public themeCtrl: FormControl = new FormControl();
  public lineCtrl: FormControl = new FormControl();
  public startCtrl: FormControl = new FormControl();
  public endCtrl: FormControl = new FormControl();
  lang  = 'fr';
  protected _onDestroy = new Subject<void>();
  @ViewChild(MatTable, {static: true}) table: MatTable<any>;
  pageSize = 10;
  initedData = [];
  allPropos = [];
  typeJournal = 0;
  typetask = 0;
  firstLoad = true;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private dialog: MatDialog
  ) {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    delete currentUser.token;
    delete currentUser.refresh_token;
    delete currentUser.roles;
    this.currentUser = currentUser;
    this.secteur = localStorage.getItem('secteur');
    // this.responsableFilter = "/api/users/" + this.currentUser['id']
    // this.managerFilter = "/api/users/" + this.currentUser['id']
  }

  ngOnInit() {
    // this.getAll(1)
    // this.getAllMessages(null);
    this.managerFilter = null;
    this.statusFilter = 'WAITING';
    //this.trieType = 'dateEcheance';
    this.trieType = 'dateEcheance';
    this.projectLists = [];
    this.saveCtc = null;
    this.saveCtcbis = null;
    this.saveProject = null;
    this.saveTheme = null;
    this.ged = false;

   // if (JSON.parse(localStorage.getItem('actionJournal')) && JSON.parse(localStorage.getItem('actionJournal')).projectFilter ) {
   /* if (JSON.parse(localStorage.getItem('actionJournalbis'))) {
      //console.log('Chargement filtre !!');
      this.loadProjectFiltre();
    }
    else
    {
      //console.log("rechargement de l'ecran");
      this.initData(1);
      this.getContact();
      this.loadProject();
    }*/

    /*
    this.getContact();
    this.loadProject();
    */

    //this.myTodoList();
    //this.managerLength = this.countmyCheckList();
    //this.porterLength = this.CountmyTodoList();
    this.CountmyTodoList();
    this.countmyCheckList();
    console.log("manager "+this.managerLength);
    console.log("porter "+this.porterLength);

    this.authService.obprojectChosen.subscribe((idMessage) => {
      this.smartNumber = idMessage;
      //console.log('dans le smarnumber idmessage ==> '+idMessage);
    });

    this.messageService.getTranslate().subscribe((data) => {
      this.lang = data;
    })

  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  initData(pageIndex) {
    //console.log({ pageIndex });
    this.dataSource = [];
    this.messages = [];
    this.allPropos = [];
    /*if(this.statusFilter == 'UNDONE'){
      this.statusFilter = ["WAITING","REFUSED"]
    }*/
    //console.log(this.startDateFilter)
    //console.log(this.EndDateFilter)

    let testIntvalue: any;
    testIntvalue = this.projectFilter;

    if (testIntvalue == -1) {
      this.projectFilter = "";
    }

    if(this.statusFilter == 'ALL_MANAGER' || this.statusFilter == 'WAITING_MANAGER' 
      || this.statusFilter == 'TO_VALIDATE_MANAGER' || this.statusFilter == 'REFUSED_MANAGER'
      || this.statusFilter == 'DONE_MANAGER' || this.statusFilter == 'WAITING_PORTEUR'
      || this.statusFilter == 'WAITING_BIS_PORTEUR' || this.statusFilter == 'REFUSED_PORTEUR'
      || this.statusFilter == 'DONE_BIS_PORTUER' || this.statusFilter == 'DONE_PORTEUR'
      || this.statusFilter == 'ALL_PORTEUR'){
    
      this.projectFilter = null;
      this.responsableFilter = null;
      this.managerFilter = null;
      this.startDateFilter = null;
      this.EndDateFilter = null;
      this.DocumentFilter = null;
      //this.trieType = 'dateEcheance';
      this.trieType = 'dateEcheance';
      this.lineFilter = null;
      this.tag = null;

    }

    var jsonElement = {
      perPage:this.perPage,
      pageIndex:pageIndex,
      projectFilter:this.projectFilter,
      responsableFilter:this.responsableFilter,
      managerFilter:this.managerFilter,
      startDateFilter:this.startDateFilter,
      EndDateFilter:this.EndDateFilter,
      DocumentFilter:this.DocumentFilter,
      statusFilter:this.statusFilter,
      lineFilter:this.lineFilter,
      tag:this.tag,
      transmitter: this.transmitter,
      recipient: this.recipient,
      trieType: this.trieType,
      projectId: this.projectId,
      dateBefore: this.dateBefore,
      dateAfter: this.dateAfter,
      statut: this.statut,
      type: this.type,
      keyWord: this.keyWord ? this.keyWord.trim() : null,
      smartNumber: this.smartNumber,
      isActionMessage:true
    }

    localStorage.setItem('actionJournalbis', JSON.stringify(jsonElement));

    //console.log('voilà status ==> '+this.statusFilter);

    this.authService
      .getAllActions(
        this.perPage,
        pageIndex,
        this.projectFilter,
        this.responsableFilter,
        this.managerFilter,
        this.startDateFilter,
        this.EndDateFilter,
        this.DocumentFilter,
        this.statusFilter,
        this.lineFilter,
        this.tag,
        this.trieType
      )
      .then((data) => {
        //console.log({ actions11111: data });
        var dataAll = [];
        let d = data["hydra:member"];
        //console.log({ dddddddd: d });

        //console.log({ dataAll });
        this.resultsLength = data["hydra:totalItems"];
        this.dataSource = data["hydra:member"];
        /*
        this.dataSource.sort(function(o1,o2){
          //console.log("date loc = "+o2["dateCreated"].substring(0, 10));
          //let currentDate = new Date(messageDate.substring(0, 10));
          if (o1["dateCreated"].substring(0, 10) == o2["dateCreated"].substring(0, 10)) {
            
            if (o1["deadline"] > o2["deadline"])    return -1;
            else if(o1["deadline"] < o2["deadline"]) return  1;
            else return  0;
            
          } else return  0;
        });*/
        //this.lastDate = new Date("01/01/4970");
        this.lastDate = new Date("01/01/3000");
        this.lastDateHeader = new Date("01/01/3000");
        
        this.dataSource.forEach((element) => {
          if (element["deadline"]) {
            /*
            if (this.isNewDayHeader(element["deadline"]) == true) {
              this.messages.push({
                headerSection: true,
                isNewDay: true,
                deadline: element["deadline"],
                date: element["dateCreated"]
              });
            }*/
            this.messages.push(this.dataMap(element));
          }
        });
        let afterIsNewDay = false;
        this.messages.reverse().forEach((element, index) => {
          if (afterIsNewDay || index == 0) {
            this.messages[index]["afterIsNewDay"] = true;
            afterIsNewDay = false;
          }
          if (element.isNewDay == true) {
            afterIsNewDay = true;
          }
        });

        //console.log({ allProps: this.allPropos });
        this.dataSource = this.messages.reverse();

        if ((this.typeJournal == 1 || this.typeJournal == 2) && (this.statusFilter == "myTodoList" || this.statusFilter == "myCheckList")) {
          this.responsableFilter = "";
          this.managerFilter = "";
          this.statusFilter = "";
        }

      });
  }

  countData(typetask){
    this.dataSource = [];
    this.messages = [];
    this.allPropos = [];
    let testIntvalue: any;
    testIntvalue = this.projectFilter;

    if (testIntvalue == -1) {
      this.projectFilter = "";
    }
    this.authService
      .getAllActions(
        this.perPage,
        1,
        this.projectFilter,
        this.responsableFilter,
        this.managerFilter,
        this.startDateFilter,
        this.EndDateFilter,
        this.DocumentFilter,
        this.statusFilter,
        this.lineFilter,
        this.tag,
        this.trieType
      )
      .then((data) => {
        if(typetask == "myTodoList") {
          this.porterLength = data["hydra:totalItems"];
        } else {
          this.managerLength = data["hydra:totalItems"];
          if(this.porterLength > 0){
            this.myTodoList();
          } else this.myCheckList();
        }
        //this.resultsLength = data["hydra:totalItems"];
        
    console.log("manager3 "+this.managerLength);
    console.log("porter3 "+this.porterLength);//myCheckList
    

      });
      //return -1;
  }

  ngAfterViewInit() {
    this.recipientCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterRecipient();
      });

    this.emmetterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterEmmetter();
      });

    this.projectCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProject();
      });

    this.themeCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.themeProject();
      });
  }

  getProjectBoards(project) {
    this.authService.getActionsByProject(project,1,100,null,null).then((res) => {
      //console.log(res);
      this.projectBoard = res["hydra:member"];
    });
  }

  nextPage(event) {
    //console.log(event);
    this.initData(event.pageIndex + 1);
  }

  fcthiddenSearchEngin(){
    if (this.hiddenSearchEngin == false)
      this.hiddenSearchEngin = true;
    else
      this.hiddenSearchEngin = false;
  }

  loadFilterElement(){
    //console.log("on loadFilterElement");
    if (this.firstLoad == true) {
      this.getContact();
      this.loadProject();
    }
    this.firstLoad = false;
  }

  getAll(pageIndex) {
    this.dataSource = [];
    this.messages = [];
    this.allPropos = [];

    let testIntvalue: any;
    testIntvalue = this.projectFilter;

    if (testIntvalue == -1) {
      this.projectFilter = "";
    }

   /* if(this.statusFilter == 'COPY'){
      this.responsableFilter = null;
      this.managerFilter = null;
    }*/


    if(this.statusFilter == 'ALL_MANAGER' || this.statusFilter == 'WAITING_MANAGER' 
      || this.statusFilter == 'TO_VALIDATE_MANAGER' || this.statusFilter == 'REFUSED_MANAGER'
      || this.statusFilter == 'DONE_MANAGER' || this.statusFilter == 'WAITING_PORTEUR'
      || this.statusFilter == 'WAITING_BIS_PORTEUR' || this.statusFilter == 'REFUSED_PORTEUR'
      || this.statusFilter == 'DONE_BIS_PORTUER' || this.statusFilter == 'DONE_PORTEUR'
      || this.statusFilter == 'ALL_PORTEUR'){
    
      this.projectFilter = null;
      this.responsableFilter = null;
      this.managerFilter = null;
      this.startDateFilter = null;
      this.EndDateFilter = null;
      this.DocumentFilter = null;
      //this.trieType = 'dateEcheance';
      this.trieType = 'dateEcheance';
      this.lineFilter = null;
      this.tag = null;

    }

    this.authService
      .getAllActions(
        this.perPage,
        pageIndex,
        this.projectFilter,
        this.responsableFilter,
        this.managerFilter,
        this.startDateFilter,
        this.EndDateFilter,
        this.DocumentFilter,
        this.statusFilter,
        this.lineFilter,
        this.tag,
        this.trieType
      )
      .then((res) => {
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
        this.resultsLength = res["hydra:totalItems"];
        var dataSourceT = res["hydra:member"];
        // if(this.statusFilter){
        //   dataSourceT.sort((a, b) => {
        //     return <any>new Date(a.deadline) - <any>new Date(b.deadline);
        //   })
        // }else{
        //   dataSourceT.sort((a, b) => {
        //     return <any>new Date(b.dateCreated) - <any>new Date(a.dateCreated);
        //   })
        // }
        dataSourceT.sort((a, b) => {
          return <any>new Date(a.dateCreated) - <any>new Date(b.dateCreated);
        });

        /*dataSourceT.map((item, i) => {
          this.allPropos.push([]);
          item.messages.map((msg) => {
            this.allPropos[i].push(msg);
            if (msg.answer) {
              msg.answer.numberAction = msg.numberAction;
              this.allPropos[i].push(msg.answer);
            }
          });
        });*/
        //this.lastDate = new Date("01/01/4970");
        this.lastDate = new Date("01/01/3000");
        this.lastDateHeader = new Date("01/01/3000");
        dataSourceT.forEach((element) => {
          if (element["deadline"]) {
            if (this.isNewDayHeader(element["dateCreated"]) == true) {
              this.messages.push({
                headerSection: true,
                isNewDay: true,
                deadline: element["deadline"],
                date: element["dateCreated"]
              });
            }
            this.messages.push(this.dataMap(element));
          }
        });
        let afterIsNewDay = false;
        this.messages.reverse().forEach((element, index) => {
          if (afterIsNewDay || index == 0) {
            this.messages[index]["afterIsNewDay"] = true;
            afterIsNewDay = false;
          }
          if (element.isNewDay == true) {
            afterIsNewDay = true;
          }
        });
        this.dataSource = this.messages.reverse();
      })
      .catch((err) => {
        this.isLoadingResults = false;
        this.isRateLimitReached = true;
        return observableOf([]);
      });
    // this.table.renderRows();
  }

  protected filterRecipient() {
    // if (this.saveCtc == null) {
    //   this.saveCtc = this.contacts;
    // }
    // let search = this.recipientCtrl.value;
    // if (!search) {
    //   this.contacts = this.saveCtc;
    //   return;
    // } else {
    //   search = search.toLowerCase();
    //   this.contacts = this.saveCtc.filter(bank => bank.givenName.toLowerCase().indexOf(search) > -1);
    // }

    if(this.saveCtc == null){
      this.saveCtc = this.contactsExp;
    }

    let search = this.recipientCtrl.value;
    if (!search) {
      this.contactsExp = this.saveCtc;
      return;
    } else {
      search = search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      this.contactsExp = this.saveCtc.filter(bank => bank.givenName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 || bank.familyName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 );
    }

  }

  protected filterEmmetter() {
    // if (this.saveCtc == null) {
    //   this.saveCtc = this.contacts;
    // }
    // let search = this.recipientCtrl.value;
    // if (!search) {
    //   this.contacts = this.saveCtc;
    //   return;
    // } else {
    //   search = search.toLowerCase();
    //   this.contacts = this.saveCtc.filter(bank => bank.givenName.toLowerCase().indexOf(search) > -1);
    // }

    if(this.saveCtcbis == null){
      this.saveCtcbis = this.contactsRecip;
    }

    let search = this.emmetterCtrl.value;
    if (!search) {
      this.contactsRecip = this.saveCtcbis;
      return;
    } else {
      search = search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      this.contactsRecip = this.saveCtcbis.filter(bank => bank.givenName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 || bank.familyName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 );
    }

  }


  public getAllMessages(searchChoose) {
    // this.paginator.pageIndex = 0;
    // this.filterChoosen = searchChoose;
    // merge(this.paginator.page)
    //   .pipe(
    //     startWith({}),
    //     switchMap(() => {
    //       this.isLoadingResults = true;
    //       return this.authService.getAllMessages(this.paginator.pageIndex, this.perPage, this.filterChoosen);
    //     }),
    //     map(data => {
    //       this.isLoadingResults = false;
    //       this.isRateLimitReached = false;
    //       this.resultsLength = data['hydra:totalItems'];
    //       return data['hydra:member'];
    //     }),
    //     catchError(() => {
    //       this.isLoadingResults = false;
    //       this.isRateLimitReached = true;
    //       return observableOf([]);
    //     })
    //   ).subscribe(data => {
    //     this.messages = [];
    //     this.lastDate = new Date('01/01/4970');
    //     data.forEach(element => {
    //       console.log({element})
    //       this.messages.push(this.dataMap(element));
    //     });
    //     console.log({messages:this.messages})
    //     let afterIsNewDay = false;
    //     this.messages.reverse().forEach((element, index) => {
    //       if(afterIsNewDay || index == 0){
    //         this.messages[index]["afterIsNewDay"] = true;
    //         afterIsNewDay = false;
    //       }
    //       if(element.isNewDay == true){
    //         afterIsNewDay = true;
    //       }
    //     });
    //     this.dataSource = this.messages.reverse();
    //     console.log({data:this.dataSource})
    //   });
    // this.dataSource = []
    // for (let i = 0; i < 7; i++) {
    //   let stat
    //   let isNew
    //   if (i == 2) {
    //     stat = 'Non Validé'
    //     isNew = false
    //   } else if (i == 4) {
    //     stat = 'Non Validé'
    //     isNew = false
    //   } else {
    //     stat = 'Validé'
    //     isNew = true
    //   }
    //   this.dataSource.push({
    //     'id': i,
    //     'date': new Date(),
    //     'project': 'test',
    //     'sender': new Date(),
    //     'isNewDay': isNew,
    //     'sujet': 'test',
    //     'Action': 'test',
    //     'Responsable': 'test',
    //     'Ressource': 'test',
    //     'Manager': 'test',
    //     'statut': stat,
    //     'actions': 'message'
    //   })
    // }
  }

  protected filterProject() {
    if (this.saveProject == null) {
      this.saveProject = this.projectLists;
    }

    let search = this.projectCtrl.value;
    if (!search) {
      this.projectLists = this.saveProject;
      return;
    } else {
      search = search.toLowerCase();
      this.projectLists = this.saveProject.filter(
        (bank) => bank.libelle.toLowerCase().indexOf(search) > -1
      );
    }
  }

  protected themeProject() {
    // console.log("calling themeProject");
    if (this.saveTheme == null) {
      this.saveTheme = this.projectTags;
      // console.log("in themeProject", this.saveTheme);
    } else {
      // console.log("else themeProject", this.projectTags);
    }

    let search = this.themeCtrl.value;
    if (!search) {
      this.projectTags = this.saveTheme;
      return;
    } else {
      search = search.toLowerCase();
      this.projectTags = this.saveTheme.filter(
        (bank) => bank.libelle.toLowerCase().indexOf(search) > -1
      );
    }
  }

 /* dataMap(data: any) {
    //console.log({ data });
    var strId = data["project"]["@id"];
    var splittedID = strId.split("/");
    var idProject = splittedID[splittedID.length - 1];
    var listTag = data["tag"];
    var isNewDay = this.isNewDay(data["deadline"]);
    var typeMessage = "Action";
    var nbpropositions = 0;
    // this.getDetailAction(data['resource'],data['manager'],data)
    for (var i = 0; i < data["messages"].length; i++) {
      if(data["messages"][i].type == "QUESTION"){
        nbpropositions +=1;
      }
    }

    const message: any = {
      id: idProject,
      project: data["project"],
      tag: listTag ? listTag.libelle : "",
      date: data["dateCreated"],
      update: data["dateModified"],
      type: data["@type"],
      isNewDay: isNewDay,
      Manager: data["manager"],
      Responsable: data["resource"],
      sujet: data["tag"],
      statut: data["status"],
      Action: data["libelle"],
      deadline: data["deadline"],
      actionDetail: data,
      smartNumber: data["line"],
      messages: data["messages"],
      isOpenProp: true,
      line: data["line"],
      nbProposition: nbpropositions,
      headerSection: false,
      recall:data["recall"],
      priority: (data["priority"] ? data["priority"] : ""),
      action_id:data["id"]
    };

    return message;


  }*/

  dataMap(data: any) {
    //console.log({ data });
    var strId = data["project"]["@id"];
    var splittedID = strId.split("/");
    var idProject = splittedID[splittedID.length - 1];
    var listTag : any[] = data['tags'];
    var isNewDay = this.isNewDay(data["dateCreated"]);

    // this.getDetailAction(data['resource'],data['manager'],data)

    const message: any = {
      id: idProject,
      project: data["project"],
      tag: listTag[0] ? listTag[0]["libelle"] : "",
      date: data["dateCreated"],
      update: data["dateModified"],
      type: data["type"],
      isNewDay: isNewDay,
      Manager: (data['type'] == 'ANSWER' ? data['recipient'] : data['author']),
      Responsable: (data['type'] == 'ANSWER' ? data['author']  : data['recipient']),
      sujet: listTag[0] ? listTag[0]["libelle"] : "",
      statut: data["actionStatus"],
      Action: data["text"],
      deadline: data["deadline"],
      actionDetail: data,
      smartNumber: data["smartNumber"],
      number: data["number"],
      consult: data["smartNumber"],
      messages: data["text"],
      isOpenProp: true,
      line: data["line"],
      isRecipientRead: data["isRecipientRead"],
      nbProposition: (data['numberProposal'] ? data['numberProposal'] : 1),
      lastProposal: (data['lastProposal'] ? data['lastProposal'] : null),
      headerSection: false,
      recall: "",
      priority: (data["priority"] ? data["priority"] : ""),
      action_id:data["id"],
      isProposal: (data['isProposal'] ? data['isProposal'] : null)
    };

    return message;


  }

  expandRow(element) {
    element.isOpenProp = !element.isOpenProp;
  }

  redirectAction(action) {
    /*this.authService.getActionTasks(action.actionDetail.id).then((res) => {
      if(res['hydra:totalItems']>0){
        this.redirectActionRes(action)
      }else{
        this.authService
        .getRiderectAction(action.actionDetail.board)
        .then((result) => {
          location.href =
            "/#/meet/" +
            result["project"]["id"] +
            "/face2face/" +
            action.actionDetail.id;
        });
      }
    })*/


   /* var lastProposition = null;

    if (action.messages.length > 0) {
      lastProposition = {numberAction: 0};
      for (var i = 0; i < action.messages.length; i++) {
        if((action.messages[i].type == 'QUESTION') && (action.messages[i].numberAction >= lastProposition.numberAction)){
            lastProposition = action.messages[i];
        }
      }
    }

     this.authService.getRiderectAction(action.actionDetail.board, environment.server_url).then((result) => {

        if (lastProposition != null && action.statut != 'DONE') {
          location.href = "/#/meet/"+result["project"]["id"]+"/face2face/"+action.actionDetail.id+"?face="+lastProposition.id;
        }
        else
        {
          location.href ="/#/meet/"+result["project"]["id"]+"/face2face/"+action.actionDetail.id;
        }


      }); */

      if (action.type == 'COMMENT' && !action.isProposal)
      {
        this.router.navigate(['/faceTofacesProposal/details/' + action.id + '/' + action.number + '/' + action.type]);
      }
      else if (action.type == 'COMMENT' && action.isProposal)
      {
        this.router.navigate(['/faceTofacesProposal/details/' + action.id + '/' + action.number + '/' + action.type+ '/' + action.isProposal]);
      }
      else
      {
        this.router.navigate(['faceTofacesProposal/details/' + action.id + '/' + action.consult + '/' + action.type]);
      }


  }

  redirectActionRes(action) {
    this.authService
      .getRiderectAction(action.actionDetail.board, environment.server_url)
      .then((res) => {
        //console.log({Action:res});
        location.href =
          "/#/project/" +
          action["id"] +
          "/meet/" +
          res["id"] +
          "?line=" +
          action.actionDetail.id;
      });
  }


  sendRecall(action) {
    console.log({actioninfo:action});
    this.authService.recallAction(action.actionDetail["id"]).subscribe((res) => {
      alert('Rappel envoyé');
      this.initData(this.paginator.pageIndex+1);
    }, (error) => {
        alert(error['error']['hydra:description']);
    });

  }


  openDeleteDialog(element) {
    const dialogRef = this.dialog.open(DeleteActionComponent, {
      width: '700px',
      data: { projetName: '', element: element }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.initData(this.paginator.pageIndex+1);
      this.messageService.nextReloadActionNotif();
     // this.getAllMessages(1)
      /*console.log('this.objectif '+result.objectif);
      console.log('this.projetName '+result.projetName);*/
    })
  }

  isRoundFirst(headerSection ,afterIsNewDay, isNewDay) {
    if (headerSection == true) {
      return 'tdClasstrue';
    }
    else if (afterIsNewDay && isNewDay) {
      return "tdClassfalse afterIsNewDayBL afterIsNewDayTL";
    } else if (afterIsNewDay) {
      return "tdClassfalse afterIsNewDayBL";
    } else if (isNewDay) {
      return "tdClassfalse afterIsNewDayTL";
    }
    return "tdClassfalse";

  }

  isRoundLast(headerSection, afterIsNewDay, isNewDay) {
    if (headerSection == true) {
      return 'tdClasstrue';
    }
    else if (afterIsNewDay && isNewDay) {
      return "tdClassfalse afterIsNewDayBR afterIsNewDayTR";
    } else if (afterIsNewDay) {
      return "tdClassfalse afterIsNewDayBR";
    } else if (isNewDay) {
      return "tdClassfalse afterIsNewDayTR";
    }
    return "tdClassfalse";
  }

  isNewDayHeader(messageDate) {
    let currentDate = new Date(messageDate.substring(0, 10));
    if (currentDate < this.lastDateHeader) {
      this.lastDateHeader = currentDate;
      return true;
    } else {
      this.lastDateHeader = currentDate;
      return false;
    }
  }

  isNewDay(messageDate) {
    let currentDate = new Date(messageDate.substring(0, 10));
    //console.log('this.lastDate ==> '+this.lastDate);
    //console.log('currentDate ==> '+currentDate);
    if (currentDate > this.lastDate) {
      //console.log('Une section ==> '+ currentDate);
      this.lastDate = currentDate;
      return true;
    } else {
      this.lastDate = currentDate;
      return false;
    }
  }

  getContact() {
    this.contacts = [];
    this.loadContact();
  }

  loadContact(url?: string) {
    url = url ? url : undefined;
    this.authService.getContacts(url).subscribe((res) => {
      this.contacts = this.contacts.concat(res["hydra:member"]);

      //this.contacts = res['hydra:member'];
      if (this.contacts.length && this.contacts[0].id != this.currentUser.id) {
        this.currentUser["@id"] = "/api/users/"+this.currentUser.id;
        this.contacts.unshift(this.currentUser);
      }

      this.contacts.sort(this.compare);

      if (res["hydra:view"]["hydra:next"]) {
        this.loadContact(res["hydra:view"]["hydra:next"].replace("/api", ""));
      }
    });


    this.authService.getContactsManager().subscribe(res => {
      this.contactsExp = res['hydra:member'];
      this.contactsExp.sort(this.compare);
      this.managerFilter = null;
      //this.getPorteurByManager(this.managerFilter);
    }, (error) => {
    });

    this.authService.getContactsPorteur().subscribe(res => {
      this.contactsRecip = res['hydra:member'];
      this.contactsRecip.sort(this.compare);
    }, (error) => {
    });


  }

  compare(a, b) {
    if (a.givenName.toLowerCase() < b.givenName.toLowerCase()) {
      return -1;
    }
    if (a.givenName.toLowerCase() > b.givenName.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  loadProject(url?: string) {
    var urltmp = url ? url : undefined;
    this.authService.getProjects(urltmp).subscribe((res) => {
      this.projectLists = this.projectLists.concat(res["hydra:member"]);
      this.projectLists = this.projectLists.sort(function (a, b) {
          if (a.libelle.toLowerCase() < b.libelle.toLowerCase()) { return -1; }
          if (a.libelle.toLowerCase() > b.libelle.toLowerCase()) { return 1; }
          return 0;
      })
      if (res["hydra:view"]["hydra:next"]) {
        this.loadProject(res["hydra:view"]["hydra:next"].replace("/api", ""));
      }
    });
  }

/*
  loadProjectFiltre(url?: string) {
    var urltmp = url ? url : undefined;
    this.authService.getProjects(urltmp).subscribe((res) => {
      this.projectLists = this.projectLists.concat(res["hydra:member"]);
      if (res["hydra:view"]["hydra:next"]) {
        this.loadProjectFiltre(res["hydra:view"]["hydra:next"].replace("/api", ""));
      }
      else
      {
        var jsonElement =  JSON.parse(localStorage.getItem('actionJournalbis'));
        var indexPage = <number>jsonElement.pageIndex;

        if (jsonElement.projectFilter) {

          this.projectFilter = jsonElement.projectFilter;
          this.projectId = jsonElement.projectFilter;

          this.getProjectBoards(this.projectFilter);
          //console.log('fin chargement !!! projectFilter '+ this.projectFilter);;
          this.authService.getTheProject(this.projectFilter).subscribe(
            (res) => {
              this.projectTags = res["tags"];
              this.contacts = [];
              var contactsProject = res["userProject"];
              for (var i = 0; i < contactsProject.length; i++) {
                this.contacts.push(contactsProject[i].user);
              }
              this.responsableFilter = jsonElement.responsableFilter;
              this.managerFilter = jsonElement.managerFilter;
              this.startDateFilter = jsonElement.startDateFilter;
              this.EndDateFilter = jsonElement.EndDateFilter;
              this.DocumentFilter = jsonElement.DocumentFilter;
              this.statusFilter = jsonElement.statusFilter;
              this.lineFilter = jsonElement.lineFilter;
              this.tag = jsonElement.tag;
              this.initData(indexPage);
            },
            (error) => {
              //console.log(' getTheProject subscribe error '+error.statusText);
            }
          );
        }
        else{
          this.getContact();
          this.responsableFilter = jsonElement.responsableFilter;
          this.managerFilter = jsonElement.managerFilter;
          this.startDateFilter = jsonElement.startDateFilter;
          this.EndDateFilter = jsonElement.EndDateFilter;
          this.DocumentFilter = jsonElement.DocumentFilter;
          this.statusFilter = jsonElement.statusFilter;
          this.lineFilter = jsonElement.lineFilter;
          this.tag = jsonElement.tag;
          this.initData(indexPage);
        }
      }
    });
  }*/

  getInfoProjet(idProject) {


    //this.resetSearch();

    if (idProject == -1) {
      this.contacts = [];
      this.getContact();
      return;
    }


    this.projectId = idProject;
    this.projectFilter = idProject;
    this.getProjectBoards(idProject);
    this.authService.getTheProject(idProject).subscribe(
      (res) => {
        this.projectTags = res["tags"];
        this.contacts = [];
        var contactsProject = res["userProject"];
        for (var i = 0; i < contactsProject.length; i++) {
          this.contacts.push(contactsProject[i].user);
        }
      },
      (error) => {
        //console.log(' getTheProject subscribe error '+error.statusText);
      }
    );
    this.authService.getManagerByProject(idProject).subscribe(res => {
      this.contactsExp = res['hydra:member'];
      this.contactsExp.sort(this.compare);
    }, (error) => {
       //console.log(' getTheProject subscribe error '+error.statusText);
    });

    this.authService.getPorteurByProject(idProject).subscribe(res => {
      this.contactsRecip = res['hydra:member'];
      this.contactsRecip.sort(this.compare);
    }, (error) => {
       //console.log(' getTheProject subscribe error '+error.statusText);
    });
   // this.search();
  }

  getTransmitte(transmitterData){
    this.transmitter  = transmitterData;
  }


  getRecipient(recipientData){
    this.recipient  = recipientData;
    this.getPorteurByManager(recipientData);
  }

  getPorteurByManager(manager_id){
    if(this.projectId){
      this.authService.getContactsByManagerInProjet(this.projectId, manager_id).subscribe(res => {
        this.contactsRecip = res['hydra:member'];
        this.contactsRecip.sort(this.compare);
        //this.selectRecipient.open();
      }, (error) => {
      });
    } else {
    this.authService.getContactsByManager(manager_id).subscribe(res => {
      this.contactsRecip = res['hydra:member'];
      this.contactsRecip.sort(this.compare);
    }, (error) => {
    });
  }

  }

  includePorteur(porteur){
    for(var i = 0; i < this.contactsRecip.length; i++) {
        if (this.contactsRecip[i].id == porteur.id) {
            return true;
        }
    }
    return false;
  }

  getTrie(trieType){
     this.trieType = trieType;
  }

  search() {
    if (this.modeDoc) {
      this.launchSearchDoc();
    } else {
      this.initData(1);
    }
  }


  launchSearch() {

    this.modeDoc = false;
    this.hiddenSearchEngin = true;
    //this.typeJournal = 0;
    this.chosenFilter = {
      transmitter: this.transmitter,
      recipient: this.recipient,
      trieType: this.trieType,
      projectId: this.projectId,
      dateBefore: this.dateBefore,
      dateAfter: this.dateAfter,
      tag: this.tag,
      statut: this.statut,
      type: this.type,
      keyWord: this.keyWord ? this.keyWord.trim() : null,
      smartNumber: this.smartNumber,
      isActionMessage: true
    };
    //console.log(' launchSearch '+ JSON.stringify(this.chosenFilter) );
    this.EventLaunchSearch.emit(this.chosenFilter);

    this.initData(1);
  }

  launchSearchDoc() {

    this.modeDoc = true;
    //this.typeJournal = 0;
    this.chosenFilter = {
      transmitter: this.responsableFilter,
      recipient: this.managerFilter,
      trieType:null,
      projectId: this.projectFilter,
      dateBefore: this.EndDateFilter,
      dateAfter: this.startDateFilter,
      tag: this.tag,
      statut: this.statut,
      type: this.type,
      keyWord: this.keyWord ? this.keyWord.trim() : null,
      smartNumber: this.smartNumber,
      isActionMessage: true
    };
    //console.log(' launchSearchDoc '+ JSON.stringify(this.chosenFilter) );
    this.EventLaunchSearchDoc.emit(this.chosenFilter);
  }

  myCheckList() {
    this.typeJournal = 2;
    this.modeDoc = false;
    this.projectFilter = null;
    this.responsableFilter = null;
    this.managerFilter = this.currentUser.id;
    this.startDateFilter = null;
    this.EndDateFilter = null;
    this.DocumentFilter = null;
    this.statusFilter = "myCheckList";
    //this.trieType = 'dateEcheance';
    this.trieType = 'dateEcheance';
    this.lineFilter = null;
    this.tag = null;

    this.initData(1);

  }

  myTodoList() {

    this.typeJournal = 1;
    this.modeDoc = false;
    this.projectFilter = null;
    this.responsableFilter = this.currentUser.id;
    this.managerFilter = null;
    this.startDateFilter = null;
    this.EndDateFilter = null;
    this.DocumentFilter = null;
    this.statusFilter = "myTodoList";
    //this.trieType = 'dateEcheance';
    this.trieType = 'dateEcheance';
    this.lineFilter = null;
    this.tag = null;
    

    this.initData(1);

  }


  CountmyTodoList() {

    this.typetask = 1;
    this.modeDoc = false;
    this.projectFilter = null;
    this.responsableFilter = this.currentUser.id;
    this.managerFilter = null;
    this.startDateFilter = null;
    this.EndDateFilter = null;
    this.DocumentFilter = null;
    this.statusFilter = "myTodoList";
    //this.trieType = 'dateEcheance';
    this.trieType = 'dateEcheance';
    this.lineFilter = null;
    this.tag = null;
    this.countData(this.statusFilter);
  }

  countmyCheckList() {
    this.typetask = 2;
    this.modeDoc = false;
    this.projectFilter = null;
    this.responsableFilter = null;
    this.managerFilter = this.currentUser.id;
    this.startDateFilter = null;
    this.EndDateFilter = null;
    this.DocumentFilter = null;
    this.statusFilter = "myCheckList";
    //this.trieType = 'dateEcheance';
    this.trieType = 'dateEcheance';
    this.lineFilter = null;
    this.tag = null;
    this.countData(this.statusFilter);
    console.log("manager2 "+this.managerLength);
    console.log("porter2 "+this.porterLength);

  }


  public resetSearch() {
    // this.transmitter = null;
    // this.recipient = null;
    // this.projectId = null;
    // this.dateBefore = null;
    // this.dateAfter = null;
    // this.tag = null;
    // this.statut = null;
    // this.type = null;
    // this.keyWord = null;
    // this.smartNumber = null;

    //this.typeJournal = 0;
    this.projectFilter = null;
    this.responsableFilter = null;
    this.managerFilter = null;
    this.startDateFilter = null;
    this.EndDateFilter = null;
    this.DocumentFilter = null;
    if (this.typeJournal === 2){
      this.statusFilter = 'myCheckList';
    } else this.statusFilter = 'myTodoList';
    //this.statusFilter = 'WAITING';
    //this.trieType = 'dateEcheance';
    this.trieType = 'dateEcheance';
    this.lineFilter = null;
    this.tag = null;
    // this.initData(1)
    this.search();

    //this.getPorteurByManager(this.managerFilter);

    // reset filtre par défaut sans les dépence 
    this.authService.getContactsPorteur().subscribe(res => {
      this.contactsRecip = res['hydra:member'];
      this.contactsRecip.sort(this.compare);
    }, (error) => {
    });

    // if (this.modeDoc == false) {
    //   this.launchSearch();
    // }
    // else {
    //   this.launchSearchDoc();
    // }
  }

  public refreshInfoFilter() {
    this.getContact();
    this.resetSearch();
    this.projectLists = [];
    this.loadProject();
  }
}

