import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { requiredFileType } from 'src/app/app-file-upload/upload-file-validators';
import { AuthService } from 'src/app/services/auth-service/auth.service';
@Component({
  selector: 'app-add-plan-action',
  templateUrl: './add-plan-action.component.html',
  styleUrls: ['./add-plan-action.component.scss']
})
export class AddPlanActionComponent implements OnInit {
  BoardData={
    documentFile: "",
    type: "PAD",
    libelle: "",
    description:"",
    document: "",
    project: {}
  }
  submitted=false
  formAdd: FormGroup;
  project_name : any
  ID:any
  constructor(public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private router:Router, private auth:AuthService) {
      //console.log(data)
      this.project_name = data.projet.libelle
      this.BoardData.project = data.projet
      this.ID = data.id
     }
  

  ngOnInit() {
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  redirect(){
    this.dialogRef.close();
    this.router.navigateByUrl('/project/' + this.ID + '/meet/' + 1)
  }


  addPlan(){
    
    let formData = new FormData()
    formData.append('libelle',"PAD")
    formData.append('description','test')
    // formData.append('documentFile',null)
    formData.append('type','PAD')
    formData.append('project',this.data.projet['@id'])
    this.auth.addBoard(formData).then((res)=>{
      //console.log(res)
      this.dialogRef.close()
    }).catch((err)=>{
      //console.log(err)
    })
  }

  get f() { return this.formAdd.controls; }

 

}
