import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth-service/auth.service';

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss']
})
export class LoadingBarComponent implements OnInit, OnDestroy {
  progress = 0;
  progressint = 0;
  private progressSubscription: Subscription;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.progressSubscription = this.authService.progress$.subscribe(progress => {
      this.progress += progress;
      this.progressint = Math.floor(this.progress);
    });
  }
  ngOnDestroy(): void {
    this.progressSubscription.unsubscribe();
  }
}