import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatTable } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { MessageService } from 'src/app/services/message/message.service';
import { AddReunionComponent } from 'src/app/project/dashboard/add-reunion/add-reunion.component';
import { merge, Observable, of as observableOf, Subject } from 'rxjs';
import { catchError, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { UpdateReunionDialogComponent } from 'src/app/project/dashboard/update-reunion-dialog/update-reunion-dialog.component';
import { DeleteConfirmationDialogComponent } from 'src/app/project/dashboard/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { AddPlanActionComponent } from 'src/app/project/dashboard/add-plan-action/add-plan-action.component';
import {environment} from 'src/environments/environment'
import { DeleteDocumentConfirmationComponent } from 'src/app/project/dashboard/delete-document-confirmation/delete-document-confirmation.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-compte-rendu-renunion',
  templateUrl: './compte-rendu-renunion.component.html',
  styleUrls: ['./compte-rendu-renunion.component.scss']
})
export class CompteRenduRenunionComponent implements OnInit {
  
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  messages: any = [];
  resultsLength = 0;
  perPage = 30;
  docsUrl = environment.server_url
  isLoadingResults = false;
  isRateLimitReached = false;
  lastDate = new Date('01/01/4970');
  filterChoosen = null;
  transmitter = null;
  recipient = null;
  projectId = null;
  dateBefore = null;
  dateAfter = null;
  tag = null;
  statut = null;
  type = null;
  keyWord = null;
  smartNumber = null;
  modeDoc = false;
  contacts: Array<any> = [];
  projectLists: Array<any> = [];
  projectTags: any;
  currentUser = null;
  currentUserId : any
  saveCtc = null;
  saveProject = null;
  saveTheme = null;
  displayedColumns: string[] = [
    'Date',
    'DateReunion',
    'Compte_rendu',
    //"actions",
    'Action'
  ];
  dataSource = []
  arrayNotifBoard: any;
  ID: any
  projectFull: any
  showTool: any
  @Output() specifyMeeting = new EventEmitter();
  typeElementArray = []
  @ViewChild(MatTable, {static: true}) table: MatTable<any>;
  cantUpdate = false;
  showAddPAD = false;
  isAdmin = false;
  selectedPdf = null;

  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private messageService: MessageService,
    private auth:AuthService, 
    protected _sanitizer: DomSanitizer) { 
  }

  ngOnInit() {
    
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"))
    this.currentUserId = this.currentUser.id
    if(this.currentUser.roles[0] == 'ADMIN'){
      this.cantUpdate = true
    }
    this.route.paramMap.subscribe(params => {
      this.ID = params.get('ID');
    })
    this.getCurrentProject()
    this.getAllMessages(null)
  }

  getCurrentProject() {

    if (!this.ID) {
      return;
    }

    this.authService.getTheProject(this.ID)
      .subscribe(res => {

        this.projectFull = res;
        console.log({currentProject:this.projectFull})
        this.projectFull['userProject'].map((user)=>{
          if(user.user && (user.user.id == this.currentUserId) && user.isAdmin){
            this.isAdmin = true
          }
        })
        // if(this.projectFull['creator']['id'] == this.currentUserId){
        //   this.showAddPAD = true
        // }
      })
  }

  backToProject() {
    this.router.navigateByUrl('/project/' + this.ID)
  }

  changeDate(){
    this.getActionsByProject(1, this.dateBefore , this.dateAfter);
  }

  specifyMeet(reunion){
    this.router.navigateByUrl('/project/' + this.ID + '/meet/' + reunion)
  }

  
  redirectReunions(){
    this.router.navigateByUrl('/reunion/'+this.ID)
  }

  getFileExtension(filename){
    return filename.substring(filename.lastIndexOf('.')+1, filename.length) || filename;
  }

  getTypeFilePDF(filename){
    let fileExetension = this.getFileExtension(filename);
    if (fileExetension.toLowerCase() == 'pdf') {
      return true;
    }
    else
    {
      return false;
    }
  }

  getPdfLink(mediaUrl){
      return this._sanitizer.bypassSecurityTrustResourceUrl(this.docsUrl + '/media/pad/' + mediaUrl);
  }

  getPdf(media: any){
      this.selectedPdf=media;
  }
     
  closeModal(){
    this.selectedPdf=null;
  }

  openDialog(): void {

    this.showTool = false;
    this.messageService.setShowCreateProjectTuto(false);
    this.messageService.setShowCreateProjectTutoIsFalse();

    const dialogRef = this.dialog.open(AddReunionComponent, {
      width: '700px',
      data: { projet: this.projectFull }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getAllMessages(null)
      
      /*console.log('this.objectif '+result.objectif);
      console.log('this.projetName '+result.projetName);*/

    })
  }

  addPlan(){
    
    let formData = new FormData()
    formData.append('libelle',"PAD")
    formData.append('description','test')
    // formData.append('documentFile',null)
    formData.append('type','PAD')
    formData.append('project',this.projectFull['@id'])
    this.auth.addBoard(formData).then((res)=>{
      console.log({res})
      // this.getAllMessages(null)
      this.router.navigateByUrl('/project/' + this.ID + '/meet/' + res['id'])
    }).catch((err)=>{
      console.log(err)
    })
  }

  openPlanDialog(): void {

    this.addPlan()

    // this.showTool = false;
    // this.messageService.setShowCreateProjectTuto(false);
    // this.messageService.setShowCreateProjectTutoIsFalse();

    // const dialogRef = this.dialog.open(AddPlanActionComponent, {
    //   width: '700px',
    //   data: { projet: this.projectFull,id: this.projectFull.id}
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   this.getAllMessages(null)
     
    //   // this.specifyMeet({id:1})
    //   /*console.log('this.objectif '+result.objectif);
    //   console.log('this.projetName '+result.projetName);*/

    // })
  }





  public getAllMessages(searchChoose) {
  
    if (!this.ID) {
      return;
    }

    this.arrayNotifBoard = [];

    this.authService.getboardsNotifications(this.ID).then((res) => {
        this.arrayNotifBoard = res;
        this.getActionsByProject(1, null, null)
    })
    
  }

  nextPage(event) {
    //console.log(event);
    this.getActionsByProject(event.pageIndex+1, null, null);
  }

  getActionsByProject(pageIndex, dateBefore, dateAfter){

    this.dataSource =[]
    this.messages = []

    this.authService.getActionsByProject(this.ID, pageIndex, this.perPage, dateBefore, dateAfter).then((res) => {
      
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
      this.resultsLength = res['hydra:totalItems'];
      this.dataSource = res['hydra:member']
      this.dataSource.sort((a, b) => {
        return <any>new Date(b.dateCreated) - <any>new Date(a.dateCreated);
      })

      this.lastDate = new Date('01/01/4970');
    
      this.dataSource.forEach(element => {
       // console.log({ element })
        this.messages.push(this.dataMap(element));
      });
      let afterIsNewDay = false;
      this.messages.reverse().forEach((element, index) => {
        if (afterIsNewDay || index == 0) {
          this.messages[index]["afterIsNewDay"] = true;
          afterIsNewDay = false;
        }
        if (element.isNewDay == true) {
          afterIsNewDay = true;
        }
      });
         
      this.dataSource = this.messages.reverse();

      this.table.renderRows();

    }).catch((err) => {
      this.isLoadingResults = false;
      this.isRateLimitReached = true;
      return observableOf([]);
    })

  }

  getActionsNotif(board_id){
    for (var i = 0; i < this.arrayNotifBoard.length; i++) {
      if(this.arrayNotifBoard[i].idboard == board_id){
        return this.arrayNotifBoard[i];
      }
    }
    return null;
  }


  dataMap(data: any) {

    var strId = data['project']['@id'];
    var splittedID = strId.split("/");
    var idProject = splittedID[splittedID.length - 1];
    var date = data['dateCreated'];
    var isNewDay = this.isNewDay(date);

    const message: any = {
      'id': idProject,
      'project': data['project'],
      'date': date,
      'dateMeeting': data['dateMeeting'],
      'originalName': data['originalName'],
      'type':data['type'],
      'isNewDay': isNewDay,
      'Titre':data['libelle'],
      'Compte_rendu':data['document'],
      'board_id':data['id'],
      'creator_id':data['creator']['id']
    };
    
    return message;
  }


  isRoundFirst(afterIsNewDay, isNewDay) {
    if (afterIsNewDay && isNewDay) {
      return "divcontaine afterIsNewDayBL afterIsNewDayTL";
    } else if (afterIsNewDay) {
      return "divcontaine afterIsNewDayBL";
    } else if (isNewDay) {
      return "divcontaine afterIsNewDayTL";
    }
    return "divcontaine";
  }

  isRoundLast(afterIsNewDay, isNewDay) {
    if (afterIsNewDay && isNewDay) {
      return "divcontaine afterIsNewDayBR afterIsNewDayTR";
    } else if (afterIsNewDay) {
      return "divcontaine afterIsNewDayBR";
    } else if (isNewDay) {
      return "divcontaine afterIsNewDayTR";
    }
    return "divcontaine";
  }

  isNewDay(messageDate) {
    let currentDate = new Date(messageDate.substring(0, 10));
    // console.log('this.lastDate ==> '+this.lastDate);
    // console.log('currentDate ==> '+currentDate);
    if (currentDate < this.lastDate) {
      // console.log('Une section ==> '+ currentDate);
      this.lastDate = currentDate;
      return true;
    }
    else {
      this.lastDate = currentDate;
      return false;
    }
  }

  getContact() {
    this.loadContact();
  }

  loadContact(url?: string) {
    url = url ? url : undefined;
    this.authService.getContacts(url).subscribe(res => {
      this.contacts = this.contacts.concat(res['hydra:member']);

      //this.contacts = res['hydra:member'];
      if (this.contacts.length && this.contacts[0].id != this.currentUser.id) {
        this.contacts.unshift(this.currentUser);
      }
      this.contacts.sort(this.compare);

      if (res['hydra:view']['hydra:next']) {
        this.loadContact(res['hydra:view']['hydra:next'].replace("/api", ""));
      }
    });
  }

  compare(a, b) {
    if (a.familyName < b.familyName) {
      return -1;
    }
    if (a.familyName > b.familyName) {
      return 1;
    }
    return 0;
  }

  loadProject(url?: string) {
    url = url ? url : undefined;
    this.authService.getProjects(url).subscribe(res => {
      this.projectLists = this.projectLists.concat(res['hydra:member']);
      if (res['hydra:view']['hydra:next']) {
        this.loadProject(res['hydra:view']['hydra:next'].replace("/api", ""));
      }
    });
  }

  getSujet(idProject) {
    this.projectId = idProject;
    this.authService.getTheProject(idProject).subscribe(res => {
      this.projectTags = res['tags'];
    }, (error) => {
      //console.log(' getTheProject subscribe error '+error.statusText);
    });
  }

  openUpdateDialog(element) {
    const dialogRef = this.dialog.open(UpdateReunionDialogComponent, {
      width: '700px',
      data: { projetName: this.projectFull.libelle, element: element, type:element.type }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getAllMessages(null)
      /*console.log('this.objectif '+result.objectif);
      console.log('this.projetName '+result.projetName);*/

    })
  }
  openDeleteDialog(element) {
    console.log({element})
    let type
    if(element.type == 'PAD'){
      type = 'plan'
    }else{
      type = 'reunion'
    }
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      width: '700px',
      data: { projetName: this.projectFull.libelle, element: element, type:type }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getAllMessages(null)
      /*console.log('this.objectif '+result.objectif);
      console.log('this.projetName '+result.projetName);*/

    })
  }


  public refreshInfoFilter() {
    this.getContact();
    this.loadProject();
  }

  updateTable(element){
    // let elementF = element
    // this.authService.updateBoard(element.board_id,{document:null}).then((res)=>{
    //   console.log(res)
    //   this.getAllMessages(null)
    //   this.table.renderRows()
    // }).catch((err)=>{
    //   console.log(err)
    // })
    const dialogRef = this.dialog.open(DeleteDocumentConfirmationComponent, {
      width: '700px',
      data: { projet: this.projectFull, element: element, type:"board" }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getAllMessages(null)
      /*console.log('this.objectif '+result.objectif);
      console.log('this.projetName '+result.projetName);*/

    })
  }

  readUrl(event,element){
    console.log(event.files)
    let formData = new FormData()
    formData.append("documentFile",event.files[0])
    this.authService.appendDocumentBoard(element.board_id,formData).then((res)=>{
      console.log(res)
      this.getAllMessages(null)
    }).catch(err=>console.log(err))
  }

}
