import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { MessageService } from '../services/message/message.service';

@Component({
  selector: 'app-notification-setting',
  templateUrl: './notification-setting.component.html',
  styleUrls: ['./notification-setting.component.scss']
})
export class NotificationSettingComponent implements OnInit {

 	notifCopy: boolean = true;
 	mailCopy: boolean = true;
 	notifsound: boolean = true;
 	appCopy: boolean = true;
 	showLoadbar: boolean = true;

	constructor(public authService: AuthService, public messageService: MessageService, public dialogRef: MatDialogRef<NotificationSettingComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		this.notifCopy = true;
		this.mailCopy = true;
		this.appCopy = true;
		this.showLoadbar = true;
	}

	onNoClick(): void {
		this.dialogRef.close();
		//this.projectDetails = this.firstFormGroup.get('projetName').value;
		  
	}

	ngOnInit() {
		this.notifsound = this.messageService.getSound();
		this.authService.getNotificationSetting().subscribe(
		  res => {  
		  	this.showLoadbar = false;
		    var setting = res['hydra:member'];
		    if (setting.length > 0) {
		    	for (var i = 0; i < setting.length; i++) {
		    		if(setting[i].propertyID == 'NOTIF_COPY_ENABLE'){
		    			this.notifCopy = setting[i].value ? true : false;
		    		}
		    		if(setting[i].propertyID == 'MAIL_COPY_ENABLE'){
		    			this.mailCopy = setting[i].value ? true : false;
		    		}
		    		if(setting[i].propertyID == 'APP_COPY_ENABLE'){
		    			this.appCopy = setting[i].value ? true : false;
		    		}	
		    	}
		    }
		    else
		    {
		    	this.notifCopy = true;
		    	this.mailCopy = true;
		    	this.appCopy = true;
		    }
		  },
		  (error) => {
		    alert('Erreur ! : ' + error)
		  }
		);
	}


	notif_copy_enable(value: boolean){
		console.log('value =>'+value);
		this.notifCopy = value;
		this.authService.notifEnable(value,"NOTIF_COPY_ENABLE").subscribe(
		  res => {  
		    //this.stepper.selectedIndex = 1;
		  },
		  (error) => {
		    alert('Erreur ! : ' + error)
		  }
		);
	}

	mail_copy_enable(value: boolean){
		this.mailCopy = value;
	 	this.authService.notifEnable(value,"MAIL_COPY_ENABLE").subscribe(
		  res => {  
		    //this.stepper.selectedIndex = 1;
		  },
		  (error) => {
		    alert('Erreur ! : ' + error)
		  }
		);
	}

	sound_enable(value: boolean){
		this.notifsound = value;
		console.log(this.notifsound);
		this.messageService.setSound(this.notifsound);
	}

	app_copy_enable(value: boolean){
		this.appCopy = value;
		this.authService.notifEnable(value,"APP_COPY_ENABLE").subscribe(
		  res => {  
		    //this.stepper.selectedIndex = 1;
		  },
		  (error) => {
		    alert('Erreur ! : ' + error)
		  }
		);	
	}


}
