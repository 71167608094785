import { Component, OnInit, ElementRef, ViewChild, HostListener, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DashboardcontentComponent } from '../content/dashboardcontent.component';
import { SingleProjectComponent } from '../single-project/single-project.component';
import { FaceTofacesProposalComponent } from 'src/app/face-tofaces-proposal/face-tofaces-proposal.component';
import { FacetofacesReunionComponent } from 'src/app/facetofaces-reunion/facetofaces-reunion.component';
import { SearchComponent } from '../search/search.component';
import { GedComponent } from '../ged/ged.component';
import { CoreServiceService } from 'src/app/services/core/core-service.service';
import { MatSidenav } from '@angular/material';
import { MessageService } from 'src/app/services/message/message.service';
import { Subscription } from 'rxjs';
import { DashboardinstcontentComponent } from '../instant/dashboardinstcontent/dashboardinstcontent.component';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit{
  type:any
  //num = 0;
  ID: any = '';
  GED: boolean = false;
  hideNav = localStorage.getItem('hideNav') && localStorage.getItem('hideNav') !== "0" ? 1 : 0;
  subscription: Subscription;
  idonglet = null;

  @ViewChild('dashboardcontent', {static: false}) dashboardcontent: DashboardcontentComponent;
  @ViewChild('dashboardinstcontent', {static: false}) dashboardinstcontent: DashboardinstcontentComponent;
  @ViewChild('singleproject', {static: false}) singleproject: SingleProjectComponent;
  @ViewChild('facetofacesproposal', {static: false}) FaceTofacesProposal: FaceTofacesProposalComponent;
  @ViewChild('facetofacesreunion', {static: false}) Facetofacesreunion: FacetofacesReunionComponent;
  //@ViewChild('appsearch') appsearch: SearchComponent;
  @ViewChild('appged', {static: false}) appged: GedComponent;
  @ViewChild('sidenav', {static: true}) public sidenav: MatSidenav;
  @ViewChild('scrollMechat', { static: false }) scrollMechat: ElementRef;
  innerWidth:any;
  constructor(private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private coreService: CoreServiceService) {
    // this.Arr = Array;
    //this.num = 20;
  }

  requestPermission(): Promise<NotificationPermission> {
    if (!("Notification" in window)) {
      console.error("This browser does not support desktop notification");
      return Promise.resolve('denied');
    } else {
      return Notification.requestPermission();
    }
  }

  ngOnInit() {
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const currentUrl = window.location.href;
    const idParam = this.route.snapshot.paramMap.get('ID');
    this.route.paramMap.subscribe(params => {
      var ID = params.get('ID');
      var specMsg = params.get('specMsg');
      var openmsg = params.get('openmsg');
      var idmsg = params.get('idmsg');
    });
    //
    this.idonglet = uuidv4();
    this.onResize(event)
    this.route.url.subscribe((res)=>{
      this.type=res;
      if(this.type[2]){
        if(this.type[1] == 'details'){
          this.type=this.type[0].path
        }else{
          this.type = this.type[2].path
        }
      }else{
        this.type=this.type[0].path
      }

      if(this.type == 'project' || this.type == 'openmsg' || this.type == 'openface'){
        this.authService.scrollTop.emit("1")
        //this.scrollToTop();
      }
    })

    if (!this.ID) {
      this.ID = this.route.params['value'].ID;
      //this.dashboardcontent.iDProjet = this.ID;
      if (this.singleproject) {
        this.singleproject.iDProjet = this.ID;
      }
    }

    if (this.hideNav == 1) {
      setTimeout(() => {
        this.sidenav.close();
      }, 1000);

    } else {
      setTimeout(() => {
        this.sidenav.open();
      }, 1000);
    }
    this.coreService.toggleSidebar.subscribe((res) => {
      localStorage.setItem('hideNav', res);
      this.hideNav = Number(res);
      this.sidenav.toggle();
    });

    this.authService.obCommonProjectChosen.subscribe((res) => {
      this.onChosenProject(res);
    });

    this.subscription = this.messageService.getRefreshFiltre().subscribe(() => {
      if (this.dashboardcontent) {
        this.dashboardcontent.refreshInfoFilter();
      }

      if (this.singleproject) {
        this.singleproject.refreshInfoFilter();
      }
    });

    this.requestPermission();

  }
  

  scrollToTop(){
    //console.log("scared")
    const element = document.querySelector('.scroll-container');
      if (element) {
        console.log("Element found using querySelector", element);
        (element as HTMLElement).scrollTop = 0;
      } else {
        console.error('Element is not found');
      }
    
    //if(this.scrollMechat && this.scrollMechat.nativeElement) this.scrollMechat.nativeElement.scrollIntoView({block: "start"});
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if(this.innerWidth<1200){
      this.sidenav.close()
    }else{
      this.sidenav.open()
    }
  }
  
  onLaunchSearch(chosenFilter) {

    if(chosenFilter && chosenFilter.transmitterinstant) return;

    this.GED = false;

    if (this.ID) {
      if (this.singleproject) {
        this.singleproject.filterMessage(chosenFilter);
      }
      if (this.FaceTofacesProposal) {
        this.FaceTofacesProposal.filterMessage(chosenFilter);
      }
      if (this.Facetofacesreunion) {
        this.Facetofacesreunion.filterMessage(chosenFilter);
      }
      if (this.appged) {
        this.appged.reset();
      }
    }
    else {
      if (this.dashboardcontent) {
        this.dashboardcontent.getAllMessages(chosenFilter);
      }
      if (this.dashboardinstcontent) {
        this.dashboardinstcontent.getAllMessages(chosenFilter);
      }

      if (this.appged) {
        this.appged.reset();
      }
    }

  }


  actionsSearchDoc(chosenFilter){
    this.GED = true;
    this.ID = null;
    if (this.appged) {
      this.appged.filterFichier(chosenFilter, chosenFilter.projectId, true);
    }
  }

  onLaunchSearchDoc(chosenFilter) {
    this.GED = true;
    this.ID = this.route.params['value'].ID;
    console.log("GED 0 "+this.GED)
    console.log("onLaunchSearchDoc from dashboard 0 "+this.appged)
    /*if (this.appged) {
      console.log("onLaunchSearchDoc from dashboard 2 ")
     this.appged.filterFichier(chosenFilter, this.ID, true);
    }*/
    setTimeout(() => {
      console.log("timeout");
      if (this.appged) {
      this.appged.filterFichier(chosenFilter, this.ID, true);
      }
    }, 200);
  }

  onLaunchSearchDocDashboard(chosenFilter) {
    this.GED = true;
    this.ID = null;
    setTimeout(() => {
      if (this.appged) {
        this.appged.filterFichier(chosenFilter, chosenFilter.projectId, true);
      }
    }, 200);
  }

  onLaunchSearchDocReunion(chosenFilter) {
    this.GED = true;
    this.ID = null;
    if (this.appged) {
      this.appged.filterFichier(chosenFilter, chosenFilter.projectId, true);
    }
  }

  onChosenProject(IdProjectChosen) {
    this.GED = false;
    this.ID = IdProjectChosen;
    if (this.appged) {
      this.appged.reset();
    }
    //this.dashboardcontent.projecthasChange(IdProjectChosen);
    if (this.singleproject) {
      this.singleproject.projecthasChange(IdProjectChosen);
    }
    //this.appsearch.iDProjet = IdProjectChosen;
    //this.appsearch.resetSearch();
  }

  onProjectChanged(libelle) {
    if (this.singleproject) {
      this.singleproject.projectlibChanged(libelle);
    }
    //this.appsearch.iDProjet = IdProjectChosen;
    //this.appsearch.resetSearch();
  }

  onImageChanged(url) {
    if (this.singleproject) {
      this.singleproject.projectImageChanged(url);
    }
  }

}
