import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from 'src/app/services/auth-service/auth.service';
@Component({
  selector: 'app-update-reunion-dialog',
  templateUrl: './update-reunion-dialog.component.html',
  styleUrls: ['./update-reunion-dialog.component.scss']
})
export class UpdateReunionDialogComponent implements OnInit {
  element:any
  project_name:any
  type:any
  libelle:any
  constructor(public dialogRef: MatDialogRef<any>,@Inject(MAT_DIALOG_DATA) public data: any,private auth:AuthService) {
    console.log({data})
    this.element=data.element
    this.project_name = data.projetName
    this.type = data.type
    this.libelle = data.element.Titre
   }
  

  ngOnInit() {
  }
  files: File[] = [];
 
  onSelect(event) {
    console.log(event);
    this.files.push(event.addedFiles);
  }
   
  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  updateBoard(){
    this.auth.updateBoard(this.element.board_id,{libelle:this.libelle}).then((res)=>{
      console.log(res)
      this.dialogRef.close()
    }).catch((err)=>{
      console.log(err)
    })
  }

}
