import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterContact'
})

export class FilterPipeContact implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) { return []; }
    if (!searchText) { return items; }
    searchText = searchText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return items.filter(it => {
		if (it['user']) {
			return it['user']['givenName'].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText) || it['user']['familyName'].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText);
		}
		else if(it['givenName'])
		{
			return it['givenName'].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText) || it['familyName'].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText);
		} else {
			return it['email'].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText) || it['email'].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText);
		}		
    });
  }
}
