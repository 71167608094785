import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { MessageService } from 'src/app/services/message/message.service';

@Component({
  selector: 'app-homes',
  templateUrl: './homes.component.html',
  styleUrls: ['./homes.component.scss']
})
export class HomesComponent implements OnInit {

  loading = true;
  wlc: any;

  constructor(private authService: AuthService, private messageService: MessageService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.wlc = params.get('wlc');
    });
    this.authService.endLoading.subscribe((term) =>{ 
      if(term == 1){
        this.loading = false
      }
    });
    //console.log("leeen "+this.messageService.getListProject());
    if(this.messageService.getListProject().length > 0) {
      //HEEEEEERE
      console.log
      this.loading = false;
    }
    /*
    */
   /*
    if(this.wlc){
      this.loading = false;
    }*/
  }

}
