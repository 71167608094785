import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {
  site = "";

  constructor(private route: ActivatedRoute, private router: Router) { }

  isIOS(): boolean {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }

redirectStore(){
  setTimeout(() => {
    // Vérifie si l'utilisateur est toujours sur la même page (l'application n'est pas installée)
    if (!document.hidden) {
      // Redirige vers le lien de téléchargement de l'App Store ou Google Play Store en fonction de la plateforme
      if (this.isIOS()) {
        window.location.href = 'https://apps.apple.com/fr/app/face2faces/id1557480419'; // Lien de l'App Store iOS
      } else {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.facework'; // Lien du Google Play Store Android
      }
    }
  }, 3000);
}

  ngOnInit() {
    console.log("redirect")
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const currentUrl = window.location.href;
    console.log('URL actuelle redirect :', currentUrl+' width : '+screenWidth);
    const idParam = this.route.snapshot.paramMap.get('ID');
    console.log("idParam : "+idParam)
    this.route.queryParams.subscribe(params => {
      const project = params['project'];
      const msg = params['message'];
      const type = params['type'];
      const invitations = params['invitations'];
      
      console.log('project:', project);
      console.log('msg:', msg);
      console.log('type:', type);
      
      if(screenWidth < 480) {
        console.log("inf")
        if(invitations){
          console.log("inv facework://invitations")
          this.site = "facework://invitations";
          window.location.replace('facework://invitations');
          this.redirectStore();
        } else if(project) {
          console.log('inv facework://Project/'+project+'/'+msg);
          this.site = 'inv facework://Project/'+project+'/'+msg;
          window.location.replace('facework://Project/'+project+'/'+msg);
          this.redirectStore();
        } else {
          console.log('inv facework://');
          this.site = ' ';
          window.location.replace('facework://');
          this.redirectStore();
        }
      } else if (project){
        console.log("supp")
        this.site = '/project/details/'+project+'/'+msg+'/'+type;
        this.router.navigate(['/project/details/'+project+'/'+msg+'/'+type]);
        //redirect for lien mail
      } else {
        this.site = '/';
        this.router.navigate(['/']);
      }
    });

    /*
    this.route.paramMap.subscribe(params => {
      var ID = params.get('ID');
      console.log("id : "+ID)
      //console.log('getCurrentProject paramètre id ==> '+params.get('ID'));
      //console.log('getCurrentProject paramètre specMsg ==> '+params.get('specMsg'));
      var specMsg = params.get('specMsg');
      console.log("specMsg : "+specMsg)
      var openmsg = params.get('openmsg');
      console.log("openmsg : "+openmsg)
      var idmsg = params.get('idmsg');
      console.log("idmsg : "+idmsg)
    });*/
    /*
    if(screenWidth < 480) {
      
      window.location.href = 'com.facework://';
      setTimeout(() => {
        // Vérifie si l'utilisateur est toujours sur la même page (l'application n'est pas installée)
        if (!document.hidden) {
          // Redirige vers le lien de téléchargement de l'App Store ou Google Play Store en fonction de la plateforme
          if (this.isIOS()) {
            window.location.href = 'https://apps.apple.com/fr/app/face2faces/id1557480419'; // Lien de l'App Store iOS
          } else {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.facework'; // Lien du Google Play Store Android
          }
        }
      }, 2000);
  } else {

  }*/
  }

}
