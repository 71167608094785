import { Component, OnInit, ViewChild, Renderer2, ElementRef, Inject, ChangeDetectorRef, OnDestroy, Input, QueryList, EventEmitter, Output, HostListener} from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { MessageService } from 'src/app/services/message/message.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import { MatButton, MatOption, MatSelect } from '@angular/material';
import {forkJoin, merge, Observable, of as observableOf, Subject, Subscription} from 'rxjs';
import {catchError, map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import { requiredFileType } from '../../../app-file-upload/upload-file-validators';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {ErrorStateMatcher} from '@angular/material/core';
import { ConfirmationDialogComponent } from '../../../components/shared/confirmation-dialog/confirmation-dialog.component';
import { Lightbox } from 'ngx-lightbox';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { SubjectsListComponent } from '../subjects-list/subjects-list.component';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { SocketService } from 'src/app/services/socket/socket.service';
import { Message } from 'src/app/services/socket/model/message';
import { Event } from 'src/app/services/socket/model/event';
import { AbstractControl, FormArray} from '@angular/forms';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DomSanitizer } from '@angular/platform-browser';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-drive',
  templateUrl: './drive.component.html',
  styleUrls: ['./drive.component.scss']
})
export class DriveComponent implements OnInit {
  allfiles: any[];
  folders: any[] = [];
  files: any[] = [];
  iddossier: null;
  idParent: null;
  showPopFolder: boolean = false;
  nameFolder: string = '';
  rootFolderId = null;
  currentFolderId = null;
  currentFolderLib = null;
  parentFolderId = null;
  folder= "";
  path="/";
  tabpath=[];
  filesimported: any = [];
  colors: string[] = ['#009dd0', '#57b031', '#ff0000', '#00ff00', '#0000ff'];
  selectedColor = '#009dd0';
  searchDrive = "";
  showBtns = false;

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.getParentFolder();
    //this.getDossiers();
  }

  toggleActions() {
    this.showBtns = !this.showBtns;
  }

  getParentFolder(): void {
    this.folders = [];
    this.files = [];
    this.authService.getParentFolder().subscribe(res => {
      this.rootFolderId = res['hydra:member'][0].id;
      this.currentFolderId = res['hydra:member'][0].id;
      this.folder = '/api/drivefiles/' + this.rootFolderId;
      this.allfiles = res['hydra:member'][0].subfile;
      console.log("bef "+this.folders.length);
      this.allfiles.forEach((element)=> {
        if(element.type == "FOLDER"){
          console.log("long "+this.folders.length);
          this.folders.push(element);
        } else this.files.push(element);
      })
      //this.files = res['hydra:member'][0].drivefiles;
    }, (error) => {
    });
  }


  getFolderContent(idfolder): void {
    this.folders = [];
    this.files = [];
    this.authService.getFolder(idfolder).subscribe(res => {
      this.parentFolderId = this.currentFolderId;
      this.currentFolderId = idfolder;
      this.allfiles = res['subfile'];
      console.log("bef "+this.folders.length);
      this.allfiles.forEach((element)=> {
        if(element.type == "FOLDER"){
          console.log("long "+this.folders.length);
          this.folders.push(element);
        } else this.files.push(element);
      })
      this.folder = '/api/drivefiles/' + idfolder;
      this.currentFolderLib = res['libelle'];
      this.path += this.currentFolderLib+"/";
      this.tabpath.push(this.currentFolderLib);
    }, (error) => {
    });
  }

  goBackFolder(): void {
    this.folders = [];
    this.files = [];
    this.authService.getFolder(this.parentFolderId).subscribe(res => {
      this.path = this.path.slice(0, -1 * (this.currentFolderLib.length + 1));
      if(this.tabpath.length > 0) this.tabpath.pop();
      this.currentFolderId = this.parentFolderId;
      if(res['parentItem']) this.parentFolderId = res['parentItem'].id;
      this.allfiles = res['subfile'];
      this.allfiles.forEach((element)=> {
        if(element.type == "FOLDER"){
          this.folders.push(element);
        } else this.files.push(element);
      })
      this.currentFolderLib = res['libelle'];
    }, (error) => {
    });
  }

  openFolderPopup(): void {
    this.showPopFolder = true;
  }

  addFolder(): void {
    if (this.nameFolder.trim() !== '') {
      this.authService.addFolder(this.nameFolder, this.folder, this.selectedColor).subscribe(res => {
        console.log("llib "+res['libelle']);
        this.folders.push(res);
        this.selectedColor = '#009dd0';
      }, (error) => {
      });
      this.closeFolderPopup();
    }
  }

  closeFolderPopup(): void {
    this.showPopFolder = false;
    this.nameFolder = '';
    this.selectedColor = '#009dd0';
  }

  addFile() {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;
    //input.accept = type; // Acceptez les fichiers de type image, vidéo et PDF
    //input.accept = type+'/*'; // Acceptez les fichiers de type image, vidéo et PDF
    input.onchange = (event) => this.onSelectFiles(event);
    input.click();
  }

  onSelectFiles(event: any) {
    const files = event.target.files;
    this.filesimported = Array.from(files);
    if(this.filesimported.length == 0) {
      alert("veuillez sélectionner des fichiers")
      return;
    }
    this.authService.addFileDrive(this.filesimported, this.folder).subscribe(res => {
      this.files.push(res);
    });
  }

  getIcon(extension, extensionfile, file){
    console.log("files "+this.files.length)
    let filelink = "";
    if(extensionfile){
    if(extensionfile.startsWith('xl') || (extensionfile == 'csv')){
      filelink = "../../..../../assets/icons/excel.png";
    }else if(extensionfile.startsWith('ppt')){
      filelink = "../../..../../assets/icons/ppt.png";
    }else if((extensionfile.startsWith('do') || extensionfile == 'odt')){
      filelink = "../../assets/icons/word.png";
    }else if(extensionfile.includes("zip") || extensionfile.includes("rar")){
      filelink = "../../assets/icons/zip.png";
    }else if(extensionfile.includes("pdf")){
      filelink = "../../../../../assets/icons/pdf.png";
    }else if(extension.includes("image")){
      console.log("ext "+extension)
      filelink = this.authService.getMediaDataDriveThumbnail(file.thumbnailPath);
    }else if(extension.includes("audio")){
      filelink = "../../assets/icons/audio.png";
    }else if(extension.includes("video")){
      filelink = "../../assets/icons/video.png";
    }}
    console.log("image "+filelink)
    return filelink ? filelink : false;
  }

  selectColor(color: string) {
    // Fonction appelée lorsqu'une couleur est sélectionnée
    this.selectedColor = color;
    console.log('Selected color:', this.selectedColor);
  }

  showFile(){
    alert("en cours de dev")
  }

}
