import { FormControl } from '@angular/forms';

//Whenever the user uploads a file, we return a true (invalid) value if its extension is not the same as the one we defined in the validator. 
//Otherwise we return null, which indicates that the validation passed.
export function requiredFileType( type: string[] ) {

  return function ( control: FormControl ) {
    const file = control.value;
    if (file) {
      for (var i = 0; i < file.length; i++) {
        const extension = file[i].name.split('.').pop();
        for (var index = 0; i < type.length; index++) {
          if ( type[index].toLowerCase() === extension.toLowerCase() ) {
            //console.log(' trouvé extension des fichier ==>'+extension+' celle choisie ==>'+type[index]);
            return null;// valid
          }
        }
        return {requiredFileType: true}; //invalid
      }
    }
    return null;
  }


}