import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MessageService } from 'src/app/services/message/message.service';

@Component({
  selector: 'app-activate-accountcmp',
  templateUrl: './activate-accountcmp.component.html',
  styleUrls: ['./activate-accountcmp.component.scss']
})
export class ActivateAccountcmpComponent implements OnInit {

  hide = true;
  error = '';
  token = '';
  lang  = 'fr';
  message = "Votre compte est en cours d'activation";
  messageEn = "Your account is being activated";

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService
    ) { 
  }

  ngOnInit() {
    if (this.authService.logIn) {
      this.router.navigate(['/']);
    }
    this.route.paramMap.subscribe(params => {
      this.token = params.get('token').replace("https:","");
      console.log("token", this.token);
    	this.activateAccount(this.token)
    });
    this.messageService.getTranslate().subscribe((data) => {
      this.lang = data;
    });
  }


 activateAccount(userToken){
 	//console.log('your userToken ' + userToken);
   this.authService.confirm(userToken)
    .subscribe(
      data => {
        //console.log('you are logging in ' + this.email + ' ' + this.password);
        //alert('Votre compte est activé, vous pouvez maintenant vous connecter.');
        if (this.lang == 'fr') {
          this.message = 'Votre compte est activé. Vous pouvez maintenant vous connectez.';
        }
        else if (this.lang == 'en') {
          this.messageEn = 'Your account is activated. You can now log in.';
        }
      },
      err => {
        //console.log('login erreur ===> '+JSON.stringify(err));
        //this.message = "Une erreur s'est produite";
        this.message = "";
        this.messageEn = 'An error has occurred';

        const error = err.error;
        const errorS = error.message;
        const errorD = error.detail;
        const errordescription = err.error['hydra:description'];
        if (errordescription) {
          this.error = errordescription;
        }
        else if (errorD){  
          this.error = JSON.stringify(errorD);
        }else if (errorS) {
          this.error = JSON.stringify(errorS);
        } else {
          this.error = JSON.stringify(error);
        }

  
        //console.log(error);
        // console.log(errorS);
        // console.log(errorD);
        // this.loading = false;
      }
    );
  }


}
