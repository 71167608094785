import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from 'src/app/services/auth-service/auth.service';

@Component({
  selector: 'app-delete-action',
  templateUrl: './delete-action.component.html',
  styleUrls: ['./delete-action.component.scss']
})
export class DeleteActionComponent implements OnInit {
  element:any
  project_name:any
  constructor(public dialogRef: MatDialogRef<any>,@Inject(MAT_DIALOG_DATA) public data: any, private auth:AuthService) {
    //console.log({data})
    this.element=data.element
    //console.log(this.element)
    this.project_name = data.projetName
   }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  deleteAction(){
    this.auth.deleteAction(this.element.action_id).then((res)=>{
      //console.log(res)
      this.dialogRef.close()
    }).catch((err)=>{
      //console.log(err)
    })
  }

}
