import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-subject-dupp',
  templateUrl: './subject-dupp.component.html',
  styleUrls: ['./subject-dupp.component.scss']
})
export class SubjectDuppComponent implements OnInit {
  arraySubject = []
  project_id :any
  action :any
  newProjectSubject = ""
  showAddForm = false
  project : any
  isUpdate : any
  updateOrojectSubject = ""
  isLoaded = false
  currentUser : any
  isAdmin = false
  chosenSubject : any
  updatedSubject : any
  currentUserId : any
  saveProject = null;
  typeproject = "PARENT";
  projects: Array<any> = [];
  addingSubject = [];
  tagsadded = [];
  tags = [];
  firstLoad = false;
  projectId = null;
  currentProject = null;
  currentId = null;
  protected _onDestroy = new Subject<void>();
  public projectCtrl: FormControl = new FormControl();
  constructor(@Inject(MAT_DIALOG_DATA) public data, private authService:AuthService, private dialogRef:MatDialogRef<any>) {
    this.projects = data.projects;
    this.currentProject = data.idProjet;
    this.currentId = data.idProjet.replace("/api/projects/", "");
    console.log("bef "+this.projects.length)
    console.log("id "+this.currentId)
    this.projects = this.projects.filter(item => item.id !== this.currentId);
    console.log("afyt "+this.projects.length)
   }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"))
    this.currentUserId = this.currentUser.id;
    this.projectCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProject();
      });
    //this.getProjectInfo()
  }

  protected filterProject() {
    if(this.saveProject == null){
      this.saveProject = this.projects;
    }

    let search = this.projectCtrl.value;
    if (!search) {
      this.projects = this.saveProject;
      return;
    } else {
      search = search.toLowerCase();
      this.projects = this.saveProject.filter(bank => bank.libelle.toLowerCase().indexOf(search) > -1);
    }
  }

  submitTags(){
    this.addingSubject.forEach(element => {
      this.addProjectToArray(element);
    });
    //alert("sent "+this.addingSubject.length)
  }


  addProjectToArray(element){
    if(element == "" || element.trim().length == 0 || this.currentProject == null){
      alert("Thème vide");
      return;
    }
    this.authService.addTagToProject({
      libelle:element,
      project:this.currentProject
    }).then((res)=>{
      //this.getProjectInfo();
      this.tagsadded.push(res);
      if(this.tagsadded.length == this.addingSubject.length){
        this.dialogRef.close(this.tagsadded);
      }
    }).catch(err=>{
      console.log(err)
    });
  }

  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }

  getGroupData(idProject){
    /*alert("ok "+idProject+" yep "+this.projectId)
    if (idProject !== this.projectId) {
      alert("noo")*/
      this.projectId = idProject;
      this.loadTags(idProject);
    //} 
    
  }

  fctRemoveTag(lib_tag){
    const index = this.addingSubject.indexOf(lib_tag);
    if (index > -1) {
      this.addingSubject.splice(index, 1);
    }
  }

  addSubject(lib_tag){
    if(this.addingSubject.includes(lib_tag)){
      this.fctRemoveTag(lib_tag);
    } else this.addingSubject.push(lib_tag);
  }

  loadTags(id: string){
    this.addingSubject = [];
    this.authService.getDataDuplicated(id).subscribe(res => {
      this.tags = [];
      this.tags = this.tags.concat(res['tag']);
      let userStatus = []; 
      let subjectGroup = []; 
        for (let index = 0; index < this.tags.length; index++) {
          if(this.tags[index]['libelle']){
            var tag = this.tags[index]['libelle'];
            this.addingSubject.push(tag);
            //var id = tag.substr(tag.lastIndexOf('/') + 1);
           // subjectGroup.push(tag);
          }
        }
        //this.subjectGroup = subjectGroup;
      //this.total_project = res['hydra:totalItems'];
    }); 
  }


  getProjectInfo(){
    this.arraySubject = []

    if (!this.project_id) {
      return;
    }

    this.authService.getProjectInfo(this.project_id).then((res)=>{
      console.log({res})
      this.arraySubject = res['tags']
      /*this.arraySubject.sort((a, b) => {
        return <any>new Date(a.dateCreated) - <any>new Date(b.dateCreated);
      })*/

      this.arraySubject.sort((a, b) => {
        if ( a.libelle.toLowerCase() < b.libelle.toLowerCase() ){
          return -1;
        }
        if ( a.libelle.toLowerCase() > b.libelle.toLowerCase() ){
          return 1;
        }
        return 0;
      })

      // this.arraySubject.reverse()
      this.isUpdate = Array(this.arraySubject.length).fill(false)
      this.project = res
      // if(this.currentUser['id'] == this.project['creator']['id'] ){
      //   this.isAdmin = true
      // }
      this.project['userProject'].map((user)=>{
        if(user.user && (user.user.id == this.currentUserId) && user.isAdmin){
          this.isAdmin = true
        }
      })
      this.isLoaded = true
    }).catch(err=>console.log(err))
  }

  showAdd(){
    this.showAddForm = !this.showAddForm
  }


  deleteTag(tag_id){
    if(confirm("Voulez vraiment suppprimer l'espace "+tag_id.libelle)) {
      this.authService.deleteTag(tag_id.id).then((res)=>{
        console.log(res)
        this.getProjectInfo();
        this.updatedSubject = tag_id;
      }).catch(err=>console.log(err))
    }
  }

  updateTag(tag, i){
    if (this.updateOrojectSubject.trim().length == 0) {
      alert("Veuillez entrer un groupe");
      return;
    }
    this.authService.updateTag(tag.id,{libelle:this.updateOrojectSubject}).then((res)=>{
      console.log(res)
      //this.getProjectInfo();
      this.arraySubject[i].libelle = this.updateOrojectSubject;
      this.updatedSubject = res;
      
      /*
      this.arraySubject.sort((a, b) => {
        if ( a.libelle.toLowerCase() < b.libelle.toLowerCase() ){
          return -1;
        }
        if ( a.libelle.toLowerCase() > b.libelle.toLowerCase() ){
          return 1;
        }
        return 0;
      })*/
      this.updateOrojectSubject = "";
      this.isUpdate[i] = !this.isUpdate[i];
      //this.isUpdate = !this.isUpdate
    }).catch(err=>console.log(err))
  }

  showUpdateTag(i, libelle){
     //this.updateOrojectSubject = "";
     this.updateOrojectSubject = libelle;
     for (var index = 0; index < this.isUpdate.length; index++) {
      if(index != i){
        this.isUpdate[index] = false;
      }
    }
    this.isUpdate[i] = !this.isUpdate[i];
  }


  chooseSujet(sujet){
    this.chosenSubject = sujet
    this.dialogRef.close()
  }


  onNoClick(){
    this.dialogRef.close()
  }

  goBack(){
    this.projectId = null;
    this.addingSubject = [];
  }

}
