import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { MessageService } from 'src/app/services/message/message.service';

@Component({
  selector: 'app-renew-confirmation',
  templateUrl: './renew-confirmation.component.html',
  styleUrls: ['./renew-confirmation.component.scss']
})
export class RenewConfirmationComponent implements OnInit {

  email = '';
  error = '';
  message = "Demande reçue, un email vous a été envoyé. Cliquez sur le lien dans l'e-mail pour réinitialiser votre mot de passe.";
  messageEn = "Registration finished. A link to activate your account has been sent to your email address, click on the link to activate the account.";
  lang  = 'fr';
  isComfirm = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService
    ) { }

  ngOnInit() {
    if (this.authService.logIn) {
      this.router.navigate(['/']);
    }
    this.messageService.getTranslate().subscribe((data) => {
      this.lang = data;
    });
  }

  renewConf() {
    //console.log('recovering password for ' + this.email);
    this.authService.renewConf(this.email)
      .pipe(first())
      .subscribe(
        data => {
          this.isComfirm = true;
        },
        err => {
          const error = err.error;
          const errorS = error.message;
          this.error = error['hydra:description'];
        }
      );
  }

}
