// clickable-link.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clickableLink'
})
export class ClickableLinkPipe implements PipeTransform {
  transform(value: string): string {
    const urlRegex = /(\b(?:https?|ftp):\/\/[^\s]+)\b/g;
    return value.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
  }
}
