import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from 'src/app/services/auth-service/auth.service';

@Component({
  selector: 'app-delete-document-confirmation',
  templateUrl: './delete-document-confirmation.component.html',
  styleUrls: ['./delete-document-confirmation.component.scss']
})
export class DeleteDocumentConfirmationComponent implements OnInit {
  element : any
  project : any
  type : any
  constructor(public dialogRef: MatDialogRef<any>,@Inject(MAT_DIALOG_DATA) public data: any, private auth:AuthService) {
    this.element = data.element
    this.project = data.projet
    this.type = data.type
   }

  ngOnInit() {
  }


  onNoClick(): void {
    this.dialogRef.close();
  }



  updateTable(){
    if(this.type == 'board'){
      this.auth.updateBoard(this.element.board_id,{document:null}).then((res)=>{
        //console.log(res)
        this.dialogRef.close()
      }).catch((err)=>{
        //console.log(err)
      })
    }else if(this.type == 'action'){
      this.auth.deleteActionDocument(this.element.action_id).then((res)=>{
      //console.log(res)
      this.dialogRef.close()
  }).catch(err=>console.log(err))
    }
   
  }

}
