import { Component, OnChanges, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatOption } from '@angular/material';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { requiredFileType } from 'src/app/app-file-upload/upload-file-validators';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { MessageService } from 'src/app/services/message/message.service';
import { environment } from 'src/environments/environment';
import { DialogAddMember, DialogMemberListe, projectInfoDialog, transfertdialog } from '../single-project/single-project.component';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-face2face',
  templateUrl: './face2face.component.html',
  styleUrls: ['./face2face.component.scss']
})
export class Face2faceComponent implements OnInit {
  docsUrl = environment.server_url
  ID: any
  projectFull: any
  currentDate = null;
  action_id: any
  openWriteFace = false
  sendMessageForm: FormGroup;
  action_manager: any
  action_responsable: any
  action: any
  existsProposal = false
  projectUsers: any
  submitted = false
  showTool = false
  isCommentActivated = true;
  files = [];
  actionTag: any
  currentUser = JSON.parse(localStorage.getItem("currentUser"))
  message: any
  anseweredProposition = false
  ansewerText = ""
  Files = []
  propositionAnsewer: any
  proposalsArray = []
  answer: any
  isAdmin = false
  currentUserId: any
  face: any
  nbrPropostion = 0;
  tmpproposal = null;
  formComment: FormGroup;
  messageInfo = null;
  remarkId = null;
  lang  = 'fr';

  @ViewChild('allSelected', {static: false}) private allSelected: MatOption;
  @ViewChild('inputMedia', {static: false}) inputMedia: ElementRef;
  @ViewChild('divComSpecMsg', {static: false}) divComSpecMsg: ElementRef;

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService,
    private dialog: MatDialog,
    private _lightbox: Lightbox) {

  }

  ngOnInit() {
    this.currentUserId = this.currentUser.id;
    // this.route.paramMap.subscribe(params => {
    //   this.ID = params.get('ID');
    //   this.action_id = params.get('IDMeet')
    // })

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    this.currentDate = dd + '/' + mm + '/' + yyyy;
    this.face = null;
    this.tmpproposal = null;
    this.remarkId = null;

    this.sendMessageForm = this.formBuilder.group({
      subProject: ['', Validators.required],
      newSubProject: '',
      recipient: ['', Validators.required],
      inCopy: [],
      private: false,
      deadLine: '',
      //messageToSend: ['', [Validators.required, Validators.minLength(1)]],
      messageToSend: '',
      files: [null, [requiredFileType(['png', 'jpeg', 'jpg', 'mov', 'mp4'])]]
    });

    this.formComment = this.formBuilder.group({
      commentMessage: ['', [Validators.required, Validators.minLength(1)]],
      files: [null, [requiredFileType(['png','jpeg','jpg','mov','mp4'])]]
    });

    this.route.paramMap.subscribe(params => {
        this.ID = params.get('ID');
        
        this.action = null;
        this.action_manager = null;
        this.action_responsable = null;
        this.actionTag = null;
        this.existsProposal = false;
        this.proposalsArray = [];

        this.action_id = params.get('IDMeet');
        this.tmpproposal = null;
        //console.log('le this.ID ==> '+this.ID);
        //console.log('paramMap le this.action_id ==> '+this.action_id);
        this.getCurrentProject();
        this.getAction();

    });

    this.route.queryParams.subscribe(queryParams => {
      if (queryParams.face) {
        this.face = queryParams.face;// pour charger que la proposition choisie, l'id est dans l'url 
        //console.log('dans le this.face ==> '+this.face);
      }
      else
      {
         this.face = null;
      }
      if (queryParams.type && queryParams.type == 'COMMENT') {
        //console.log('dans le queryParams.remarkId ==> '+queryParams.remarkId);
        this.remarkId = queryParams.remarkId;
      }
      else
      {
        this.remarkId = null;
      }
    });
    // this.getCurrentProject()
    // this.getAction()


    this.messageService.getTranslate().subscribe((data) => {
      this.lang = data;
    });

  }

  loadAllMessage() {
    this.face = null
    this.remarkId = null;
    this.getCurrentProject()
    this.getAction()
    location.href = "/#/meet/" + this.ID + "/face2face/" + this.action_id
  }
  // ngOnChanges(){
  //   this.route.paramMap.subscribe(params => {
  //     this.ID = params.get('ID');
  //     this.action_id = params.get('IDMeet')
  //   })
  //   this.getCurrentProject()
  //   this.getAction()
  // }

  get f() { return this.sendMessageForm.controls; }

  getCurrentProject() {
    if (this.ID) {
      this.authService.getTheProject(this.ID)
        .subscribe(res => {
          //console.log('fin de getTheProject');
          this.projectFull = res;
          this.projectFull['userProject'].map((user) => {
            if (user.user && (user.user.id == this.currentUserId) && user.isAdmin) {
              this.isAdmin = true
            }
          })
          //console.log(this.projectFull)
          var tmpArrayUser = res['userProject'];
          this.projectUsers = [];
          for (var i = 0; i < tmpArrayUser.length; i++) {
            if (tmpArrayUser[i].user) {
              this.projectUsers.push(tmpArrayUser[i]);
            }
          }
        })
    }
  }

  backToProject() {
    var strId = this.action['board'];
    var splittedID = strId.split("/");
    var idProject = splittedID[splittedID.length - 1];
    this.router.navigateByUrl('/project/' + this.ID + '/meet/' + idProject)
  }


  fctEcrire() {
    if (this.openWriteFace == false) {
      this.openWriteFace = true;
      this.quitComment();
    }
    else {
      this.openWriteFace = false;
    }
  }

  compareObjects(o1: any, o2: any) {
    if(o1.givenName == o2.givenName )
    return true;
    else return false
  }
  
  getMediaLink(mediaUrl){
    return this.authService.getMediaDataPAD(mediaUrl);
  }

  getAction() {
    if (this.action_id) {
      this.authService.getActionDetails(this.action_id).then((res) => {
        console.log({ MonActionDetails: res })
        //console.log('fin de get action');
        this.action = res
        this.action_manager = res['manager']
        this.action_responsable = res['resource']
        this.actionTag = res['tag']
        //console.log(this.action_manager, this.action_responsable)
       //console.log({thisAction:this.action});
        //this.getNbPropositionAction();
        this.getProposition();
         
      }).catch((err) => {
      //console.log(err)
      })
    }
  }

    
  getFiletype(file){

    if (file.type.includes("image/") == true) {
      return 1;
    }
    else if (file.type.includes("video/") == true) {
      return 2; 
    }
    else if (file.type.includes("application/") == true) {
      return 3;
    }
    else if (file.type.includes("audio/") == true) {
      return 4;
    }
    else
    {
      return -1;
    }

  }


  uploadFile(event) {
    //console.log('item récupéré '+JSON.stringify(event));
    /*for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.files[this.files.length] = element;
      this.inputMedia.nativeElement.value = "";
    }  */

    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      console.log({lefichier:element});
      this.files.push(element);
    } 
    this.inputMedia.nativeElement.value = "";
  }

  changePrivacy(val) {
    this.sendMessageForm.get('private').setValue(!val);
  }

  getAvatarlUrl(avatarLink) {
    return environment.server_url + avatarLink;
  }

  getUserListWithNoCurrentUser() {
    if (!this.projectUsers) return [];
    var arrayUser = [];
    for (var i = 0; i < this.projectUsers.length; i++) {
      if (this.projectUsers[i].user.id != this.currentUser) {
        arrayUser.push(this.projectUsers[i]);
      }
    }
    return arrayUser;
  }

  tosslePerOne(all){ 
    if (this.allSelected.selected) {  
      this.allSelected.deselect();
      return false;
    }
    /*if(this.sendMessageForm.controls.inCopy.value.length==this.projectUsers.length){ 
      this.allSelected.select();
    }*/
    //console.log(this.sendMessageForm.value);
  }

  toggleAllSelection() {
    //console.log(this.allSelected)
    if (this.allSelected.selected) {
      this.sendMessageForm.controls.inCopy.patchValue([...this.getUserListWithNoCurrentUser().map(item => item.user.id)]);
    } else {
      this.sendMessageForm.controls.inCopy.patchValue([]);
    }
   // console.log(this.sendMessageForm.value);
  }

 
  onSubmitForm(customerData) {

    // Process checkout data here
    // console.log(JSON.stringify(this.files));
    //console.log('Your order has been submitted', customerData);

    this.submitted = true;
    // stop here if form is invalid
    if (this.sendMessageForm.invalid) {
      return;
    }

    this.sendQuestion(customerData);
    if (this.showTool == false) {
      this.messageService.setShowProjectTutoIsFalse();
    }

    this.messageService.setMessageIsSend();
    this.submitted = false;
    this.sendMessageForm.reset();

  }

  sendQuestion(messageTosendData) {
    
     if (messageTosendData.messageToSend.trim() == '' && this.Files.length == 0) {
      return alert('Veuillez entrer une proposition ou choisir une pièce jointe');
    }

    if (this.proposalsArray.length > 0) {
      //pour être sur de marquer comme lu la dernière proposition faite car en fonction du trie on sait pas trop....
      //la au moins on est sûr du résultat
      this.fctMarkAsRead(this.proposalsArray[0]);
      this.fctMarkAsRead(this.proposalsArray[this.proposalsArray.length-1]);
    }

    this.authService.sendQuestion(
      messageTosendData.messageToSend,
      this.actionTag,
      /*(messageTosendData.subProject != '' ? messageTosendData.subProject : messageTosendData.newSubProject),
      (messageTosendData.subProject != '' ? false : true),*/
      this.projectFull['@id'],
      messageTosendData.recipient,
      messageTosendData.inCopy,
      messageTosendData.private,
      this.isCommentActivated,
      messageTosendData.deadLine,
      null,
      null,
      null,
      this.Files,
      this.action['@id'],
      null,
      null
    ).subscribe(res => {
        //console.log({return:res});

      this.authService.updateActionStatus(this.action.id, 'WAITING').then((res) => {
      });

      setTimeout(() => {
        this.messageService.nextReloadPropostionNotif();
      }, 1000);
      
      this.existsProposal = true;
      this.openWriteFace = false;
      this.loadAllMessage();
      //this.getAction();
    });
  }


  fctMarkAsReadbis(element){
    this.authService.markAsRead(element).subscribe(res => {
      //console.log({fctMarkAsReadReturn:res});
      this.messageService.nextReloadPropostionNotif();
      this.getProposition();
    },
    error => {
      alert("oups une erreur s'est produite!");
    });
  }

  fctMarkAsRead(element){
    this.authService.markAsRead(element).subscribe(res => {
      //console.log({fctMarkAsReadReturn:res});
      this.messageService.nextReloadPropostionNotif();
    },
    error => {
      alert("oups une erreur s'est produite!");
    });
  }


  getProposition() {
    
    this.proposalsArray = [];

    this.authService.getAllPropositionAction(this.ID, this.action_id).subscribe(res => {
        this.existsProposal = true;
        this.proposalsArray = res['hydra:member'];
        this.nbrPropostion = res['hydra:totalItems'];
        this.nbrPropostion +=1;
        console.log({proposalsArray:this.proposalsArray});
        if (this.remarkId) {
          setTimeout(() => {
            if (this.divComSpecMsg) {
              this.divComSpecMsg.nativeElement.scrollIntoView({block: "center"});
            }
          }, 500);
        } 
    },
    error => {
        alert("oups une erreur s'est produite!");
    });

    /*this.authService.getProposition(this.action_responsable['@id'], this.action_manager['@id'], this.action['@id']).then((res) => {
      var items = res['hydra:member']
      items.sort((a, b) => {
        return <any>new Date(b.dateCreated) - <any>new Date(a.dateCreated);
      })
      items.map((item) => {
        if (item.action && item.action.id == this.action.id) {
          this.existsProposal = true
          this.proposition = item
          this.proposalsArray.push(item)
          // this.getMessage(this.proposition['id'])
        }
      })
      
      this.proposalsArray = this.proposalsArray.map((item, i) => {
        this.getMessage(item['id'], i);
      });

    })*/

  }


  /*getMessage(message, i) {
    var tmpMessage = message;
    this.authService.getSingleMessage(tmpMessage).then((res) => {
      this.message = res
      this.proposalsArray[i] = res
      console.log({ msg: this.message })
      if (this.message['isMessageAnswered']) {
        this.anseweredProposition = true
        this.answer = this.message.answer
      }
    })
  }*/

 /* getNbPropositionAction(){
    this.authService.getNbPropositionAction(this.action['@id']).then((res) => {
      this.nbrPropostion = res['hydra:totalItems'];
      this.nbrPropostion +=1;
    });
  }*/

 


  writeCommentRefused(proposal){
      this.removeWriteCommentRefusedMode();
      this.tmpproposal = proposal;
  }

  removeWriteCommentRefusedMode(){
    this.files = [];
    this.ansewerText = "";
    this.tmpproposal = null;
  }

  ansewerProposition(response, proposal) {
    if (response == 'cancel') {
      this.authService.updateActionStatus(this.action.id, 'REFUSED').then((res) => {
        this.authService.sendAnswer(this.ansewerText, this.actionTag, false, this.projectFull['@id'], proposal['id'], this.files, 'CANCELED',this.action['@id'],null).subscribe((res) => {
          this.messageService.nextReloadPropostionNotif();
          this.getAction()
        })
      })
    }
    if (response == 'accept') {
      this.authService.updateActionStatus(this.action.id, 'DONE').then((res) => {
        this.authService.sendAnswer(this.ansewerText, this.actionTag, false, this.projectFull['@id'], proposal['id'], this.files, 'DONE',this.action['@id'],null).subscribe((res) => {
          this.messageService.nextReloadPropostionNotif();
          this.getAction()
        })
      })
    }

  }

  transfertByMail(messageData) {
    const dialogRef = this.dialog.open(transfertdialog, {
      width: '600px',
      data: messageData
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');       
    });
  }


  onSelect(event) {
    event.addedFiles.map((it) => {
      this.Files.push(it)
    })
    // this.Files.push(event.addedFiles[0]);
  }
  onSelectResponse(event) {
    event.addedFiles.map((it) => {
      this.files.push(it)
    })
    // this.files.push(event.addedFiles[0]);
  }
  userInCopyHasNoRead(element) {
    if (!element) return false;
    for (var i = 0; i < element.copy.length; i++) {
      if ((element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.copy[i].isRead == false)) {
        return true;
      }
    }
    return false;
  }

  userIsConcerned(element) {
    if (!element) return false;
    if (element.recipient && (element.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id'])) {
      return true;
    }
    for (var i = 0; i < element.copy.length; i++) {
      if (element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) {
        return true;
      }
    }
    return false;
  }

  userIsInCopy(element){
    if (!element) return false;
    for (var i = 0; i < element.copy.length; i++) {
      if (element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) {
        return true;
      }
    }
    return false;
  }

  userInCopyHasRead(element) {
    if (!element) return false;
    for (var i = 0; i < element.copy.length; i++) {
      if ((element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.copy[i].isRead == true)) {
        return true;
      }
    }
    return false;
  }

  isNoRead(element) {
    if ((element.recipient && element.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.isRecipientRead == false)) {
      return true;
    }
    return false;
  }

  isRead(element) {
    if ((element.recipient && element.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.isRecipientRead == true)) {
      return true;
    }
    return false;
  }

  markAsReadCopy(element) {
    //console.log('lancement de en copie');
    for (var i = 0; i < element.copy.length; i++) {
      if ((element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.copy[i].isRead == false)) {
        this.authService.markAsReadCopy(element.copy[i].id).subscribe(res => {
          // this.refreshPageObs.next();
          this.getProposition();
          this.messageService.nextReloadPropostionNotif();
          if (element.answer) {
            this.markAsReadCopy(element.answer);
          }
        },
        error => {
            alert("oups une erreur s'est produite!");
        });
        return;
      }
    }
  }

  markAsRead(element) {

    if (element && (element.isRecipientRead == false) && (element.type != "COMMENT") && (element.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id'])) {
      //console.log('lancement de en recipient');
      this.authService.markAsRead(element).subscribe(res => {
        // this.refreshPageObs.next();
        this.getProposition();
        this.messageService.nextReloadPropostionNotif();
      },
        error => {
          alert("oups une erreur s'est produite!");
        });
    }
    else if (element) {
      //console.log('lancement de en copie');
      for (var i = 0; i < element.copy.length; i++) {
        if ((element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.copy[i].isRead == false)) {
          this.authService.markAsReadCopy(element.copy[i].id).subscribe(res => {
            // this.refreshPageObs.next();
            this.getProposition();
            this.messageService.nextReloadPropostionNotif();
          },
            error => {
              alert("oups une erreur s'est produite!");
            });
          return;
        }
      }
    }
  }

  fctListeMembre() {
    const dialogRef = this.dialog.open(DialogMemberListe, {
      width: '500px',
      data: { idProjet: this.ID }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed result '+result);
      if (result) {
        //TO Do dialog ajout membre 
        this.fctAddMember();
      }
    });
  }

  fctAddMember() {
    const dialogRef = this.dialog.open(DialogAddMember, {
      width: '500px',
      data: { project: this.projectFull, projectUsers: this.projectUsers }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed result add membre '+result);    
    });
  }

  fctinfoProjet(): void {
    const dialogRef = this.dialog.open(projectInfoDialog, {
      width: '500px',
      data: this.projectFull
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed fctinfoProjet '+result); 
      if (result && result.deleteReturn) {
        this.authService.obRefreshProjet.next();
        this.router.navigate(['/']);
      } else if (result) {
        this.projectFull = result;
        this.authService.obRefreshProjet.next();
      }

    });
  }

  exiteWriteMode() {
    this.openWriteFace = false
  }


  onRemove(event) {
    //console.log(event);
    this.Files.splice(this.Files.indexOf(event), 1);
  }

  onRemoveAnswer(event) {
    //console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  testCurrentmanager(){
    if (this.action_manager && (this.currentUser.id == this.action_manager.id)) {
      return true;
    }
    else
    {
      return false;
    }
  }

  get fcomment() { return this.formComment.controls; }

  commentMode(messageData){
    console.log({proposition:messageData})
    this.quitComment();
    this.messageInfo = messageData;
  }

  quitComment(){
    this.messageInfo = null;
    this.submitted = false;
    this.files = [];
    this.resetCommentForm();  
  }


  onSubmitFormComment(commentFormData){

    // Process checkout data here
    //console.log('Your order has been submitted onSubmitFormComment ', commentFormData);

    this.submitted = true;

     // stop here if form is invalid
    if (this.formComment.invalid) {
      return;
    }
    
    this.sendComment(commentFormData);

    //scroll in view
    window.scroll(0,0);
    //this.divHeaderTable.nativeElement.scrollIntoView();

  }

  sendComment(commentFormData) {
    this.authService.sendCommentProposition(
      commentFormData.commentMessage,
      this.actionTag,
      false,
      this.projectFull['@id'],
      this.messageInfo.id,
      this.files,
      this.action['@id']
      // commentFormData.files
    )
    .subscribe(res => {
      /* 
      this.writeMode = -1;
     
      this.messageService.messageRefreshConv();
      this.messageService.messageUpdated();*/
      this.quitComment();
      this.loadAllMessage();
       
    },error => {
      alert("oups une erreur s'est produite!");
    });
  }

   resetCommentForm(){

    // reset form
    this.formComment.get('commentMessage').clearValidators();
    this.formComment.get('commentMessage').updateValueAndValidity();
    this.formComment.get('files').clearValidators();
    this.formComment.get('files').updateValueAndValidity();
    this.formComment.reset();

    //set defaut form values
    this.formComment.get('commentMessage').setValue('');
    this.formComment.get('commentMessage').setValidators([Validators.required, Validators.minLength(1)]);
    this.formComment.get('commentMessage').updateValueAndValidity();
    this.formComment.get('files').setValue(null);
    this.formComment.get('files').setValidators([requiredFileType(['png','jpeg','jpg','mov','mp4'])]);
    this.formComment.get('files').updateValueAndValidity();

  }


  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }


  deleteAttachment(index) {
    this.files.splice(index, 1);
    //console.log(this.files)
  }

  open(file){

    const reader = new FileReader();
    reader.onload = (e: any) => {
       //console.log('csv content', e.target.result);
       const album = {
          src: e.target.result
       };
       let albums = [];
       albums.push(album);
       this._lightbox.open(albums, 0);

       setTimeout(() => {
        document.querySelector( 'img.lb-image' ).removeAttribute("hidden");
        if(document.querySelector( '.lb-loader' )) document.querySelector( '.lb-loader' ).setAttribute("hidden", "true");
        if(document.querySelector( '.lb-nav' )) document.querySelector( '.lb-nav' ).setAttribute("hidden", "true");
       }, 1500);

    };
    reader.readAsDataURL(file);

  }

  playVideoOrAudio(file, source){
    source.src = URL.createObjectURL(file);
  }

  createFileUrl(file, source){
    source.href = URL.createObjectURL(file);
  }

}


