import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-taches-actions',
  templateUrl: './taches-actions.component.html',
  styleUrls: ['./taches-actions.component.scss']
})
export class TachesActionsComponent implements OnInit, OnChanges {
  text = "e lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès quil est prêt ou que la mise en page est achevée."
  taches = []
  alphabet = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
  maxLengthIndex = 0
  maxLength = 0
  sousTachesLength = Array(this.taches.length).fill([])
  asign = Array(this.taches.length).fill([])
  @Input() Task_id: any
  @Input() team: any
  task_data: any
  tasks: any
  currentUser = JSON.parse(localStorage.getItem("currentUser"))
  addTaskBody = {
    libelle: "",
    description: "",
    status: "WAITING",
    col: "2",
    row: "1",
    action: "",
    documentFile: ""
  }
  resource: any
  dataTasks = []
  dataTasksTemp = []
  maxArrayLength = 0
  TasksArray: any
  imageFil: any
  img: any
  animate = true
  showDescription: any
  docUrl = environment.server_url
  ID: any
  projectFull: any
  currentUserId: any
  sortedDataArray: any
  showMore: any
  re = /(?:\.([^.]+))?$/;
  extensions: any
  isAdmin = false
  rowTitles = []
  columnsTitles = []
  action = ""
  canValidate = false
  actionDetails: any
  activeTask = ""
  isResource = false
  isDone = false
  countTasksGreen = 0
  constructor(private auth: AuthService, private cd: ChangeDetectorRef, private route: ActivatedRoute, private authService: AuthService) {
    this.currentUserId = this.currentUser.id
  }
  ngOnChanges() {

    this.task_data = this.Task_id
    this.resource = this.team
    //console.log(this.resource)
    this.addTaskBody.action = "/api/actions/" + this.task_data
    //console.log({ action: this.task_data })

    if (this.task_data) {
      // this.getAction()
      this.getTasks()
    }
  }


  ngOnInit() {
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams.task) {
        this.activeTask = queryParams.task
      }
    });
    this.route.paramMap.subscribe(params => {
      //console.log({ params })
      this.ID = params.get('ID');
    })

    this.getCurrentProject()

    this.img = "../../../../assets/img/fileTache.png"
    // this.getAction()
  }



  getAction() {
    this.auth.getRiderectAction("/api/actions/" + this.task_data, environment.server_url).then((res) => {
      this.actionDetails = res
      if (res['manager'].id == this.currentUserId) {
        this.canValidate = true
      }
      if (res['status'] == 'DONE') {
        this.isDone = true
      }
    })
  }

  getCurrentProject() {
    if (!this.ID) {
      return;
    }
    this.auth.getTheProject(this.ID)
      .subscribe(res => {
        this.projectFull = res;
        this.projectFull['userProject'].map((user) => {
          if (user.user && (user.user.id == this.currentUserId) && user.isAdmin) {
            this.isAdmin = true
          }
        })
      })
  }
  // showMore(i,x){
  //   console.log(i,x)
  //   this.sousTachesLength[i][x]=400
  //   console.log(this.sousTachesLength)
  // }



  getTasks() {
    this.dataTasksTemp = []
    this.dataTasks = []
    this.taches = []
    this.tasks = []
    this.getAction()
    this.auth.getActionTasks(this.task_data).then((res) => {
      this.taches = res['hydra:member']
      this.taches.map((it) => {
        if (it.status == 'DONE') {
          this.countTasksGreen++
        }
      })
      if (this.taches.length == 0 && this.isDone == false) {
        this.dataTasksTemp.push([{ status: "empty", libelle: "", dateCreated: new Date() }])
      } else {
        let row = 1
        let i = 0
        while (i < this.taches.length) {
          if (this.taches[i].row == row) {
            this.dataTasks.push(this.taches[i].row);
            row++;
            i = 0;
          } else {
            i++
          }
        }
        this.dataTasks.map((row, i) => {
          this.dataTasksTemp.push([])
          let data = this.taches.filter((task) => {
            if (task.row == row && parseInt(task.col) > 0) {
              return task
            }
          })
          data.map((t) => {
            this.dataTasksTemp[i].push(t)
          })
        })

        let max = 0
        this.dataTasksTemp.map((data, i) => {
          if (data.length > max) {
            max = data.length
            this.maxArrayLength = i
          }
        })
      }
      this.showMore = Array(this.dataTasksTemp.length).fill([])
      for (let i = 0; i < this.dataTasksTemp.length; i++) {
        this.showMore[i] = Array(this.dataTasksTemp.length).fill(false)
        this.dataTasksTemp[i].sort((a, b) => {
          return <any>new Date(a.dateCreated) - <any>new Date(b.dateCreated);
        })
        if (this.dataTasksTemp[i][this.dataTasksTemp[i].length - 1].status == 'CANCELED' || this.dataTasksTemp[i][this.dataTasksTemp[i].length - 1].status == 'WAITING') {
          this.dataTasksTemp[i].push({ status: "empty", libelle: "", dateCreated: new Date() })
        }
      }
      //console.log('trying',this.dataTasks.length > 0 && this.dataTasks.length == (this.countTasksGreen / 2))
      if (this.dataTasks.length > 0 && this.dataTasks.length == (this.countTasksGreen / 2)) {
        //console.log('heeere')
        this.authService.updateActionStatus(this.actionDetails.id, 'DONE').then((res) => {
          //console.log({ validation: res })
        })
      }
      //console.log({ meeeeee: this.dataTasksTemp })
    })



  }

  // sortTasks(taskArray){
  //   return taskArray.sort((a,b)=>{
  //     return <any>new Date(a.dateCreated) - <any>new Date(b.dateCreated);
  //   })
  // }
  getfileExtensions(file) {
    return file.substr(file.lastIndexOf('.') + 1)
  }
  showMoreDesc(i, x) {
    this.showMore[i][x] = !this.showMore[i][x]
  }
  addEmptyTask() {
    this.imageFil = null
    // if(this.isAdmin){
    //console.log(this.isDone)
    if (this.taches.length > 0 && this.isDone == false) {
      this.dataTasksTemp.push([{ status: "empty", libelle: "" }])
    }
    // }

  }


  addTask() {
    this.auth.addTask(this.addTaskBody).then((res) => {
     // console.log(res)
      this.getTasks()
    }).catch((err) => {
     // console.log(err)
    })
  }

  addFirstTask(row, col, libelle) {
    //console.log(libelle)
    //console.log(this.addTaskBody)
    if (this.taches.length == 0) {
      let formData = new FormData()
      formData.append('libelle', "")
      formData.append('description', libelle)
      formData.append('status', "WAITING")
      formData.append('col', "1")
      formData.append('row', "1")
      formData.append('action', "/api/actions/" + this.task_data)
      formData.append('documentFile', this.addTaskBody.documentFile)
      this.auth.addTask(formData).then((res) => {
        this.getTasks()
        this.addTaskBody.description = ""
      }).catch((err) => {
        //console.log(err)
      })
    } else {
      let col1 = col + 1
      let row1 = row + 1
      let formData = new FormData()
      formData.append('libelle', "")
      formData.append('description', libelle)
      formData.append('status', "WAITING")
      formData.append('col', col1.toString())
      formData.append('row', row1.toString())
      formData.append('action', "/api/actions/" + this.task_data)
      formData.append('documentFile', this.addTaskBody.documentFile)
      this.auth.addTask(formData).then((res) => {
        this.getTasks()
        this.addTaskBody.libelle = ""
        this.addTaskBody.description = ""
        this.imageFil = null
      }).catch((err) => {
        //console.log(err)
      })
    }

  }

  addComment(task) {
    this.auth.updateTask(task.id, {
      status: "CANCELED"
    }).then((res) => {
      this.getTasks()
    })
    this.dataTasksTemp[parseInt(task.row) - 1].push({ status: "empty", libelle: "" })
  }


  validateTask(task) {
    this.auth.updateTask(task.id, {
      status: "DONE"
    }).then((res) => {
      this.getTasks()
    })
  }


  PropTask(row, col, libelle, last_task, stat) {
    let col1 = col + 1
    let row1 = row + 1
    let formData = new FormData()
    formData.append('libelle', "")
    formData.append('description', libelle)
    if (stat == 'CANCELED') {
      formData.append('status', "CANCELED")
    } else {
      formData.append('status', "DONE")
    }
    formData.append('col', col1.toString())
    formData.append('row', row1.toString())
    formData.append('action', "/api/actions/" + this.task_data)
    formData.append('documentFile', this.addTaskBody.documentFile)
    this.auth.addTask(formData).then((res) => {
      this.addTaskBody.description = ""
    }).catch((err) => {
     // console.log(err)
    })
    if (stat == 'CANCELED') {
      this.addComment(last_task)
    } else {
      this.validateTask(last_task)
    }
    // this.getTasks()
  }


  ChoseImage(event): void {

    const reader = new FileReader();
    var files = event.files;
    this.addTaskBody.documentFile = files[0]
    // loop through files
    // for (var i = 0; i < files.length; i++) {
    //   this.imageFil = files[i];
    // }
    this.imageFil = files[0];
    reader.readAsDataURL(this.imageFil);

    reader.onload = () => {
      this.cd.markForCheck();
      this.imageFil = reader.result;
    };

  }


  deleteFile(task) {
    this.auth.deleteTaskDocument(task).then((res) => {
      //console.log(res)
      this.getTasks()
    }).catch(err => console.log(err))
  }


  deleteTaskDoc(element) {
    this.auth.updateTask(element.id, { document: null }).then((res) => {
     // console.log(res)
      this.getTasks()
    }).catch((err) => {
      //console.log(err)
    })
  }
  readURLUpdate(input, element) {
    console.log(input.files)
    let formData = new FormData()
    formData.append("documentFile", input.files[0])
    this.auth.appendDocumentTask(element.id, formData).then((res) => {
     // console.log(res)
      this.getTasks()
    }).catch(err => console.log(err))
  }

  addColumnTitle(i) {
    let formData = new FormData()
    formData.append('libelle', (<HTMLInputElement>document.getElementById('col-' + i)).value)
    formData.append('description', this.addTaskBody.description)
    formData.append('status', "WAITING")
    formData.append('col', (i + 1).toString())
    formData.append('row', '0')
    formData.append('action', "/api/actions/" + this.task_data)
    formData.append('documentFile', this.addTaskBody.documentFile)
    // formData.append('manager',this.addTaskBody.manager)
    // formData.append('resource',this.addTaskBody.resource)
    this.auth.addTask(formData).then((res) => {
      this.getTasks()
    }).catch((err) => {
     // console.log(err)
    })
  }
  addRowTitle(i) {
    let formData = new FormData()
    formData.append('libelle', (<HTMLInputElement>document.getElementById('row-' + i)).value)
    formData.append('description', this.addTaskBody.description)
    formData.append('status', "WAITING")
    formData.append('col', '0')
    formData.append('row', (i + 1).toString())
    formData.append('action', "/api/actions/" + this.task_data)
    formData.append('documentFile', this.addTaskBody.documentFile)
    // formData.append('manager',this.addTaskBody.manager)
    // formData.append('resource',this.addTaskBody.resource)
    this.auth.addTask(formData).then((res) => {
      this.getTasks()
    }).catch((err) => {
      //console.log(err)
    })
  }


  updateTask(task, i) {
    //console.log(task)
    this.auth.updateTask(task.id, { libelle: (<HTMLInputElement>document.getElementById('col-' + i)).value }).then((res) => {
      //console.log(res)
      this.getTasks()
    }).catch((err) => {
     // console.log(err)
    })
  }



}
