import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/services/message/message.service';

@Component({
  selector: 'app-discoverview',
  templateUrl: './discoverview.component.html',
  styleUrls: ['./discoverview.component.scss']
})
export class DiscoverviewComponent implements OnInit {

  lang='fr';
  
  constructor(private messageService: MessageService) { }

  ngOnInit() {

  	this.messageService.getTranslate().subscribe((data) => {
      this.lang= data;
    });

  }

}
