import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-journal-service',
  templateUrl: './journal-service.component.html',
  styleUrls: ['./journal-service.component.scss']
})
export class JournalServiceComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<JournalServiceComponent>) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
