import { Component, OnInit, Inject, ViewChild, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { MessageService } from 'src/app/services/message/message.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { MatButton } from '@angular/material/button';
import { merge, Subject, Observable, of as observableOf, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Location } from "@angular/common";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from "@angular/common/http";
import { JournalWorkflowComponent } from '../journal-workflow/journal-workflow.component';
import { JournalKpiComponent } from '../journal-kpi/journal-kpi.component';
import { JournalServiceComponent } from '../journal-service/journal-service.component';
import { ProfileComponent } from 'src/app/pages/profile/profile.component';
import { NotificationSettingComponent } from 'src/app/notification-setting/notification-setting.component';
import { SocketService } from 'src/app/services/socket/socket.service';
import { Message } from 'src/app/services/socket/model/message';

export interface ContactDialogData {
  name: string;
  profile: string;
  commonRef: string;
}

export interface DialogDataNotif {
  copyNotifCount: number;
  receiveNotifCount: number;
  sentNotifCount: number;
  commentNotifCount: number;
  idprojet: string;
  name: string;
}

export interface ITransfer {
  projectUsers: [];
  projectTags: [];
  projects: [];
  project: any;
  idprojet: string;
  transfermsg: string;
  idmsg: string;
  iddoc: string;
  typeDialog: number;
}

export interface Contacts {
  '@id': string;
  '@type': string;
  familyName: string;
  fullName: string;
  gender: string;
  givenName: string;
  id: string;
  image: string;
  imageUrl: string;
  jobTitle: string;
  position: number;
}

@Component({
  selector: 'app-dashboard-menu',
  templateUrl: './dashboard-menu.component.html',
  styleUrls: ['./dashboard-menu.component.scss']
})
export class DashboardMenuComponent implements OnInit, OnDestroy {
  name: string;
  profile: string;
  commonRef: string;
  notifAcion = null;
  // currentUser: string;
  currentUserName: string;
  subscription: Subscription;
  isOpenMenu = false;
  topay = false;

  //notificationload = [];
  notification = [];
  contactsTemp: Contacts[];
  contacts: Contacts[];
  inviteBadge = 0;
  notifBadge = 0;
  sentNotifCount = 0;
  receiveNotifCount = 0;
  copyNotifCount = 0;
  commentNotifCount = 0;

  mettingsentNotifCount = 0;
  mettingreceiveNotifCount = 0;
  mettingcopyNotifCount = 0;
  mettingcommentNotifCount = 0;

  userEmail = '';
  userManager = false;
  dashbordbtnBackground = 'transparent';
  dashbordbtnColor = '#a386bd';
  boardBackground = 'transparent';
  boardColor = '#a386bd';
  nbToolNotif = 0;
  showTool = false;
  route: string;
  lang = 'fr';
  countActions : any
  actionLength:any
  isdashboard = false
  isBoard = false
  sentNotifCountAction : any
  receiveNotifCountAction : any
  copyNotifCountAction : any
  answerNotifCountAction :any
  commentNotifCountAction :any
  actionBadge : any;
  currentUser = JSON.parse(localStorage.getItem('currentUser'))
  notifBoard = null;
  selectedImg = null;
  docsUrl = environment.server_url;
  secteur = "";
  isCurrentPage = "dashboard";
  notificationicon = false;
  filicon = false;
  invitationicon = false;
  contactsicon = false;
  profilicon= false;
  parametreicon = false;
  hideNotif = false;
  inproject = 0;
  typeProject = "";
  ioConnection: any;
  socket_service_onMessage: Subscription;



  constructor(
    private translate: TranslateService,
    public authService: AuthService,
    public dialog: MatDialog,
    private router: Router,
    location: Location,
    private messageService: MessageService,
    protected _sanitizer: DomSanitizer,
    private httpClient: HttpClient,
    private socketService: SocketService) {

    this.sentNotifCountAction = 0;
    this.receiveNotifCountAction  = 0;
    this.copyNotifCountAction = 0;
    this.answerNotifCountAction  = 0;
    this.commentNotifCountAction = 0;

    this.router.events.subscribe(val => {
      if (location.path() != "") {
        this.route = location.path();
      } else {
        this.route = "Home";
      }
      if (this.route == '/dashboard') {
        this.isdashboard = true
        this.dashbordbtnBackground = '#57b031';
        this.dashbordbtnColor = "#ffffff"
      }
      else {
        this.isdashboard = false
        this.dashbordbtnBackground = 'transparent';
        this.dashbordbtnColor = "#57b031"
      }
      if(this.route == '/journal/actions'){
        this.isBoard = true
        this.boardBackground =  '#a386bd'
        this.boardColor = "#ffffff"
      }else{
        this.isBoard = false
        this.boardBackground = 'transparent'
        this.boardColor =  "#a386bd"
      }
      if (this.route !== '/project') {
        this.typeProject = ""
      }
      if (this.route == '/dashboard') {
        this.typeProject = "Discussions Organisées"
      }
      //console.log("route "+this.route);
    });


    this.messageService.getShowProjectTutoIsFalse().subscribe(() => {
      this.showTool = this.messageService.getShowNotifTuto();
    });


    this.getNotifBoard();
    this.messageService.obsReloadBoardNotif().subscribe(() => {
      this.getNotifBoard();
    });
    this.authService.hideNotif.subscribe((hideNotif) =>{ 
      //alert("hidden "+hideNotif);
      if(hideNotif == "true"){
        this.hideNotif =true
      } else this.hideNotif = false;
    });
    this.authService.typeProject.subscribe((typeProject) =>{ 
      //alert("hidden "+typeProject);
      if(typeProject == "2"){
        this.typeProject = "Discussions Organisées"
      } else if(typeProject == "1"){
        this.typeProject = "Messages Instantannés"
      } else this.typeProject = "";
    });

  }

  getNotifBoard(){
    this.authService.getNotifBoard().subscribe(res => {
      if(res['total'] > 0){
        this.notifBoard = res['total'];
      }else{
        this.notifBoard = null;
      }
    }, (error) => {

    });
  }


  getAllActionsNotification(){


    //console.log('refresh des notification des actions');

    this.authService.getActionsNotifications(0, 1, 10).subscribe((res) => {
      //console.log({sentNotifCount:res})
      this.sentNotifCountAction = res['hydra:totalItems'];
      this.countActions = true
    });


    this.authService.getActionsNotifications(1, 1, 10).subscribe((res) => {
      //console.log({receiveNotifCount:res})
      this.receiveNotifCountAction = res['hydra:totalItems'];
      /*this.authService.getActionsNotifications(4, 1, 10).subscribe((res) => {
        //console.log({receiveNotifCount:res})
        this.receiveNotifCountAction += res['hydra:totalItems'];
      });*/
    });

    /*this.authService.getActionsNotifications(2, 1, 10).subscribe((res) => {
      //console.log({receiveNotifCount:res})
      this.answerNotifCountAction = res['hydra:totalItems'];
    })  */

    this.authService.getActionsNotifications(2, 1, 10).subscribe((res) => {
      //console.log({receiveNotifCount:res})
      this.copyNotifCountAction = res['hydra:totalItems'];
    });


    this.authService.getActionsNotifications(3, 1, 10).subscribe((res) => {
      console.log({commentNotifCountAction:res})
      this.commentNotifCountAction = res['hydra:totalItems'];
    });


    /*this.authService.getActionsBisNotifications().subscribe((res) => {
      this.actionBadge = res['Notif'];
    }); */

    this.authService.getAllActionsNotRead(this.currentUser.id, 1, 10, null).subscribe((res) => {
      this.actionBadge = res['hydra:totalItems'];
    });

  }


  getImg(mediaType){
      this.selectedImg = mediaType;
  }

  closeModal(){
    this.selectedImg = null;
  }


  guide() {
    this.messageService.setGuide(1);
  }

  redirectJournal(){
    this.router.navigateByUrl('journal/actions')
  }

  gotoActionNotifBis(){
    this.router.navigateByUrl('notifsActionsBis');
  }

  gotoJournalAction(){
    //alert("En cours de développement");
    this.router.navigateByUrl('journalActionBis');
  }

  gotoJournalWorkflow(){
    //alert("En cours de développement");
    this.router.navigateByUrl('JournalWorkflow');
  }

  onDevAction(){
    alert("En cours de développement");
    //this.router.navigateByUrl('journalActionBis');
  }

  openDialog() {
    this.dialog.closeAll();
    this.contactsicon = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'contactpanel';
    dialogConfig.id = 'balchal717';
    dialogConfig.width = '550px';
    dialogConfig.autoFocus = false;
    dialogConfig.data = { name: this.name, profile: this.profile, commonRef: this.commonRef };
    const dialogRef = this.dialog.open(DashboardDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.profile = result;
        this.router.navigate(['/profile/' + result]);
      }
      this.contactsicon = false;
      if(window.location.href.endsWith("/dashboard")){
        this.filicon = true;
      } else if(window.location.href.endsWith("/notificationsSetting") || window.location.href.endsWith("/statistique")){
        this.parametreicon = true;
      } else if(window.location.href.endsWith("/profile")){
        this.profilicon = true;
      }
    });

  }


  openInfo() {
    const dialogRef = this.dialog.open(tutoStepdialog, {
      width: '850px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
    })
  }

  ngOnInit() {
    /*
    var parent = this;

    this.authService.initHeader.subscribe((term) =>{ 
      if(term == 1){
        parent.changeColor("all");
      }
    });
    */

    /*this.messageService.obsReloadPropostionNotif().subscribe(() => {
      this.getAllActionsNotification();
    });*/

    this.secteur = localStorage.getItem('secteur');
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userEmail = this.currentUser['email'];
    var roles = this.currentUser['roles']; 
    if(this.currentUser['roles'].includes("ROLE_MANAGER")){
      this.userManager = true;
    }

    this.currentUserName = this.currentUser['givenName'];
    this.authService.obNotifBadge.subscribe((res) => {
      this.notification = <any>res;
      this.notifBadge = 0;
      for (var i = 0; i < this.notification.length; i++) {
        //console.log('reoutr get notif => '+ JSON.stringify(this.notification));
        this.notifBadge += this.notification[i].total;
      }
    });
    this.authService.obInviteBadge.subscribe((res) => {
      this.inviteBadge = res['hydra:totalItems'];
    });

    this.authService.obInviteClic.subscribe((res) => {
      if(res == "1"){
        this.showInvitation();
      }
    });

    this.authService.obInviteInstant.subscribe((res) => {
      if(res === 1){
        this.inviteBadge += 1;
      } else this.inviteBadge -= 1;
    });

    this.getNotification();
    this.updateNotifications();

    this.subscription = this.messageService.getMessage().subscribe(() => {
      this.updateNotifications();
    });


    this.messageService.obsReloadNotifAction().subscribe(() => {
      /*this.authService.getActionsBisNotifications().subscribe((res) => {
        //console.log({receiveNotifCount:res})
        this.actionBadge = res['Notif'];
      })  */
     this.authService.getAllActionsNotRead(this.currentUser.id, 1, 10, null).subscribe((res) => {
        this.actionBadge = res['hydra:totalItems'];
      });

    });

    this.refreshActionNotif();
    this.messageService.obsReloadActionNotif().subscribe(() => {
      this.refreshActionNotif();
    });

  }

  refreshActionNotif(){
    /*this.authService.getNotificationJAction().subscribe(res => {
      if(res['total'] > 0){
        this.notifAcion = res['total'];
      }else{
        this.notifAcion = null;
      }
    });*/

    this.authService.getAllActions(1,1,null,null,'/api/users/'+this.currentUser.id,null,null,null,'UNDONE',null,null,
      'dateEcheance')
      .then((data) => {
        if(data["hydra:totalItems"] > 0){
          this.notifAcion = data["hydra:totalItems"];
        }else{
          this.notifAcion = null;
        }
      });

  }

  getActionsNotifications(){
    this.authService.actionsNotifications().then((res)=>{
      // return res['total']
      this.actionLength=res
      //console.log("hjk",this.actionLength)
      for(let i=0;i<this.actionLength.length;i++){
        //console.log("fazejfzjefze",this.actionLength[i])
      }
    }).catch((err)=>{
      //console.log(err)
    })
  }

  public openNavbarmobile() {
    this.isOpenMenu = !this.isOpenMenu;
    //console.log(this.isOpenMenu)
  }
  changeLang() {
    this.translate.use(this.lang);
    this.messageService.setTranslate(this.lang);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  updateNotifications() {
    this.authService.getProjectNotification(0, 1, 10).subscribe((res) => {
      this.sentNotifCount = res['hydra:totalItems'];
      this.messageService.setSentNotifCount(this.sentNotifCount);
      console.log("started notifcount")
    });
    
    this.authService.getProjectNotification(1, 1, 10).subscribe((res) => {
      this.receiveNotifCount = res['hydra:totalItems'];
      this.messageService.setRereiveNotifCount(this.receiveNotifCount);
    });

    this.authService.getProjectNotification(2, 1, 10).subscribe((res) => {
      this.copyNotifCount = res['hydra:totalItems'];
      this.messageService.setCopyNotifCount(this.copyNotifCount);
    });

    this.authService.getProjectNotification(3, 1, 10).subscribe((res) => {
      this.commentNotifCount = res['hydra:totalItems'];
      this.messageService.setCommentNotifCount(this.commentNotifCount);
    });

  }





  getNotification() {
    this.authService.getNotification().subscribe(res => {
      this.authService.obNotifBadge.next(res);
    });
    this.authService.getUserInvitation(0, 10).subscribe(res => {
      this.authService.obInviteBadge.next(res);
    });
  }

  changeColor(term){
    this.notificationicon = false;
    this.filicon = false;
    this.invitationicon = false;
    this.contactsicon = false;
    this.profilicon = false;
    this.parametreicon = false;
    switch(term){
      case "notificationicon": this.notificationicon = true; break;
      case "filicon": this.filicon = true; break;
      case "invitationicon": this.invitationicon = true; break;
      case "contactsicon": this.contactsicon = true; break;
      case "profilicon": this.profilicon = true; break;
      case "parametreicon": this.parametreicon = true; break;
      default: break;
    }
  }

  openDialogNotif(): void {
    this.dialog.closeAll();
    this.notificationicon = true;
    if (this.showTool == true) {
      this.showTool = false;
      this.messageService.setShowNotifTuto(false);
    }

    const dialogRef = this.dialog.open(DashboardDialogNotif, {
      width: '1400px',
      data: { sentNotifCount: this.sentNotifCount, receiveNotifCount: this.receiveNotifCount, copyNotifCount: this.copyNotifCount, commentNotifCount: this.commentNotifCount }
    });

    dialogRef.afterClosed().subscribe(result => {
       //console.log({result:result});
      if (result)
      {
        if (result.type == 'COMMENT')
        {
          this.router.navigate(['/project/details/' + result.id + '/' + result.number + '/' + result.type]);
        }
        else
        {
          this.router.navigate(['project/details/' + result.id + '/' + result.consult + '/' + result.type]);
        }
      }
      this.notificationicon = false;
      if(window.location.href.endsWith("/dashboard")){
        this.filicon = true;
      } else if(window.location.href.endsWith("/notificationsSetting") || window.location.href.endsWith("/statistique")){
        this.parametreicon = true;
      } else if(window.location.href.endsWith("/profile")){
        this.profilicon = true;
      }
      //this.animal = result;
    });
  }


  openDialogWorkflow(): void {
    //alert("En cours de développement");
    
    if (this.showTool == true) {
      this.showTool = false;
      this.messageService.setShowNotifTuto(false);
    }

    const dialogRef = this.dialog.open(JournalWorkflowComponent, {
      width: '55rem',
      data: { /*sentNotifCount: this.sentNotifCountAction, receiveNotifCount: this.receiveNotifCountAction, copyNotifCount: this.copyNotifCountAction, commentNotifCount: this.commentNotifCountAction*/ },
      panelClass: 'workflowmodal'
    });

    dialogRef.afterClosed().subscribe(result => {

      /*
      if (result)
      {
        if (result.type == 'COMMENT' && !result.isProposal)
        {
          this.router.navigate(['/faceTofacesProposal/details/' + result.id + '/' + result.number + '/' + result.type]);
        }
        else if (result.type == 'COMMENT' && result.isProposal)
        {
          this.router.navigate(['/faceTofacesProposal/details/' + result.id + '/' + result.number + '/' + result.type + '/' + result.isProposal]);
        }
        else
        {
          this.router.navigate(['faceTofacesProposal/details/' + result.id + '/' + result.consult + '/' + result.type]);
        }
      }
      */

    });
    
  }


  openDialogKpi(): void {
    //alert("En cours de développement");
    
    if (this.showTool == true) {
      this.showTool = false;
      this.messageService.setShowNotifTuto(false);
    }

    const dialogRef = this.dialog.open(JournalKpiComponent, {
      width: '75rem',
      data: { /*sentNotifCount: this.sentNotifCountAction, receiveNotifCount: this.receiveNotifCountAction, copyNotifCount: this.copyNotifCountAction, commentNotifCount: this.commentNotifCountAction*/ },
      panelClass: 'workflowmodal'
    });

    dialogRef.afterClosed().subscribe(result => {

      /*
      if (result)
      {
        if (result.type == 'COMMENT' && !result.isProposal)
        {
          this.router.navigate(['/faceTofacesProposal/details/' + result.id + '/' + result.number + '/' + result.type]);
        }
        else if (result.type == 'COMMENT' && result.isProposal)
        {
          this.router.navigate(['/faceTofacesProposal/details/' + result.id + '/' + result.number + '/' + result.type + '/' + result.isProposal]);
        }
        else
        {
          this.router.navigate(['faceTofacesProposal/details/' + result.id + '/' + result.consult + '/' + result.type]);
        }
      }
      */

    });
    
  }

  openDialogService(): void {
    //alert("En cours de développement");
    
    if (this.showTool == true) {
      this.showTool = false;
      this.messageService.setShowNotifTuto(false);
    }

    const dialogRef = this.dialog.open(JournalServiceComponent, {
      width: '75rem',
      data: { /*sentNotifCount: this.sentNotifCountAction, receiveNotifCount: this.receiveNotifCountAction, copyNotifCount: this.copyNotifCountAction, commentNotifCount: this.commentNotifCountAction*/ },
      panelClass: 'workflowmodal'
    });

    dialogRef.afterClosed().subscribe(result => {

      /*
      if (result)
      {
        if (result.type == 'COMMENT' && !result.isProposal)
        {
          this.router.navigate(['/faceTofacesProposal/details/' + result.id + '/' + result.number + '/' + result.type]);
        }
        else if (result.type == 'COMMENT' && result.isProposal)
        {
          this.router.navigate(['/faceTofacesProposal/details/' + result.id + '/' + result.number + '/' + result.type + '/' + result.isProposal]);
        }
        else
        {
          this.router.navigate(['faceTofacesProposal/details/' + result.id + '/' + result.consult + '/' + result.type]);
        }
      }
      */

    });
    
  }

  private newMethod() {
    return '0';
  }


  logout() {
    //console.log('logging out');
    this.authService.logout();
  }

  /*getNotification() {
    this.authService.getNotification()
      .subscribe(res => {
        this.notificationload = res;
      });

    this.notificationload.forEach(dataN => {
      this.authService.getTheProject(dataN.id)
        .subscribe(response => {
          dataN.name = response['libelle'];
          this.notification.push(dataN);
        });
    });
  }*/

  openProfilDialog() {
    this.dialog.closeAll();
    this.contactsicon = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'contactpanel';
    dialogConfig.id = 'balchal718';
    dialogConfig.width = '550px';
    dialogConfig.autoFocus = false;
    dialogConfig.data = { name: this.name, profile: this.profile, commonRef: this.commonRef };
    const dialogRef = this.dialog.open(ProfileComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      /*
      if (result) {
        this.profile = result;
        this.router.navigate(['/profile/' + result]);
      }
      this.contactsicon = false;
      if(window.location.href.endsWith("/dashboard")){
        this.filicon = true;
      } else if(window.location.href.endsWith("/notificationsSetting") || window.location.href.endsWith("/statistique")){
        this.parametreicon = true;
      } else if(window.location.href.endsWith("/profile")){
        this.profilicon = true;
      }*/
    });

  }


  openNotifDialog() {
    this.dialog.closeAll();
    this.contactsicon = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'contactpanel';
    dialogConfig.id = 'balchal718';
    dialogConfig.width = '850px';
    dialogConfig.autoFocus = false;
    dialogConfig.data = { name: this.name, profile: this.profile, commonRef: this.commonRef };
    const dialogRef = this.dialog.open(NotificationSettingComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      
    });

  }

  showInvitation() {
    this.dialog.closeAll();
    this.invitationicon = true;
    const dialogRef = this.dialog.open(InvitationDialogComponent, {
      width: '650px',
      data: {},
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      if (result) {
        //console.log('/single/'+result.project.id);
        this.router.navigate(['/project/' + result.project.id+'/openmsg']);
        //this.authService.obRefreshProjet.next(result.project.id);
        console.log("dash-menu "+JSON.stringify(result.project))
        this.authService.obProjetAdded.next(result.project);
        this.authService.getUserInvitation(0, 10).subscribe(res => {
          this.authService.obInviteBadge.next(res);
        });
        this.messageService.messageUpdated();
      }
      this.invitationicon = false;
      if(window.location.href.endsWith("/dashboard")){
        this.filicon = true;
      } else if(window.location.href.endsWith("/notificationsSetting") || window.location.href.endsWith("/statistique")){
        this.parametreicon = true;
      } else if(window.location.href.endsWith("/profile")){
        this.profilicon = true;
      }

    });
  }

  /*
    openDialogNotifReunion(): void {

      
      const dialogRef = this.dialog.open(DashboardDialogNotifReunion, {
        width: '1400px',
        data: { sentNotifCount: this.mettingsentNotifCount, receiveNotifCount: this.mettingreceiveNotifCount, copyNotifCount: this.mettingcopyNotifCount, commentNotifCount: this.mettingcommentNotifCount }
      });

      dialogRef.afterClosed().subscribe(result => {
        //console.log({result:result});
        if (result)
        {
          if (result.type == 'COMMENT')
          {
            this.router.navigate(['/faceTofacesReunion/details/' + result.id + '/' + result.number + '/' + result.type]);
          }
          else
          {
            this.router.navigate(['faceTofacesReunion/details/' + result.id + '/' + result.consult + '/' + result.type]);
          }
        }
        //this.animal = result;
      });

    }
  */


}


/////////////////// contact view dialog ///////////////////

@Component({
  selector: 'app-dashboard-dialog',
  templateUrl: 'dashboard-dialog.html',
  styleUrls: ['dashboard-dialog.scss']
})
export class DashboardDialogComponent {

  contacts: Array<Contacts> = [];
  searchContact: any;
  secteur = "";

  constructor(
    public dialogRef: MatDialogRef<ContactDialogData>,
    @Inject(MAT_DIALOG_DATA) public data: ContactDialogData,
    private authService: AuthService,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.secteur = localStorage.getItem('secteur');
    this.loadContact();
  }


  loadContact(url?: string) {
    url = url ? url : undefined;
    this.authService.getContacts(url).subscribe(res => {
      this.contacts = this.contacts.concat(res['hydra:member']);
      this.contacts.sort((a, b) => {
        if ( a.fullName < b.fullName){
          return -1;
        }
        if ( a.fullName > b.fullName ){
          return 1;
        }
        return 0;
      });
      //this.contacts = res['hydra:member'];
      if (res['hydra:view']['hydra:next']) {
        this.loadContact(res['hydra:view']['hydra:next'].replace("/api", ""));
      }
    });
  }

  goToProfile(profilId) {
    this.dialog.closeAll();
      const dialogConfig = new MatDialogConfig();
      dialogConfig.panelClass = 'contactpanel';
      dialogConfig.id = 'balchal718';
      dialogConfig.width = '550px';
      dialogConfig.autoFocus = false;
      dialogConfig.data = { idUser: profilId};
      const dialogRef = this.dialog.open(ProfileComponent, dialogConfig);
  
      dialogRef.afterClosed().subscribe((result: any) => {
        /*
        if (result) {
          this.profile = result;
          this.router.navigate(['/profile/' + result]);
        }
        this.contactsicon = false;
        if(window.location.href.endsWith("/dashboard")){
          this.filicon = true;
        } else if(window.location.href.endsWith("/notificationsSetting") || window.location.href.endsWith("/statistique")){
          this.parametreicon = true;
        } else if(window.location.href.endsWith("/profile")){
          this.profilicon = true;
        }*/
      });
  }

  goToCommonGroupe(profilId) {

    const commondialogRef = this.dialog.open(commonGroupeComponent, {
      width: '500px',
      data: { id: profilId }
    });

    commondialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      if (result) {
        this.onNoClick();
        this.authService.obCommonProjectChosen.next(result);
        this.router.navigate(['/project/' + result]);
      }

    });

  }

  getAvatarlUrl(avatarLink) {
    return environment.server_url + avatarLink;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}


/////////////////// common groupe view dialog ///////////////////

export interface Project {
  '@id': string;
  '@type': string;
  imageUrl: string;
  libelle: string;
  id: string;
  dateCreated: string;
  lastmsgextrait: string;
}

@Component({
  selector: 'app-commongroupe-dialog',
  templateUrl: 'commongroupe-dialog.html',
  styleUrls: ['commongroupe-dialog.scss']
})
export class commonGroupeComponent implements AfterViewInit {

  perPage = 10;
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  projectArray: Project[];
  displayedColumns: string[] = ['Project'];
  dataSource: any;
  searchContact = null;
  secteur = "";

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private router: Router
  ) {
      this.secteur = localStorage.getItem('secteur');
  }

  ngAfterViewInit() {

    // If the user changes the sort order, reset back to the first page.
    //this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.authService.getCommonProjects(this.paginator.pageIndex, this.perPage, this.data.id);
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data['hydra:totalItems'];;
          return data['hydra:member'];;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => {
        this.projectArray = data;
        this.dataSource = new MatTableDataSource(this.projectArray);
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  fctprojectChosen(projectId): void {
    this.dialogRef.close(projectId);
  }

  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }

}

/////////////////// notification view dialog ///////////////////

@Component({
  selector: 'dashboard-dialogNotif',
  templateUrl: 'dashboard-dialogNotif.html',
  styleUrls: ['dashboard-dialogNotif.scss']
})
export class DashboardDialogNotif implements OnInit, AfterViewInit {

  /*displayedColumns: string[] = ['receptiondate','sender','receiver','project', 'suject',
    'Statut','consult'];*/

  displayedColumns: string[] = ['receptiondate', 'sender', 'receiver', 'project', 'suject', 'consult'];
  displayedColumnsbis: string[] = ['receptiondate', 'sender', 'receiver', 'project', 'suject', 'consult'];

  dataSource: any[];
  resultsLength = 0;
  perPage = 30;
  notifMode = 1;
  isLoadingResults = true;
  isRateLimitReached = false;
  projectArray: any[];
  lastDate = new Date('01/01/4970');
  sendbtnColor = 'white';
  sendbtnBackground = '#57b031'
  receivebtnColor = '#57b031';
  receivebtnBackground = 'white'
  copybtnColor = '#57b031';
  copybtnBackground = 'white'
  answerbtnColor = '#57b031';
  answerbtnBackground = 'white'

  sentNotifCount = 0;
  receiveNotifCount = 0;
  copyNotifCount = 0;
  commentNotifCount = 0;
  secteur = "";
  idprojet = null;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;


  public sendObs = new Subject();
  public sendObs$ = this.sendObs.asObservable();



  constructor(private authService: AuthService,
    public dialogRef: MatDialogRef<DashboardDialogNotif>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataNotif) {

    this.sentNotifCount = data.sentNotifCount;
    console.log("seeent "+this.sentNotifCount);
    this.receiveNotifCount = data.receiveNotifCount;
    this.copyNotifCount = data.copyNotifCount;
    this.commentNotifCount = data.commentNotifCount;
    if(data.idprojet){
    this.idprojet = data.idprojet;
  }
    this.secteur = localStorage.getItem('secteur');
    var notifselected = Number(localStorage.getItem('notifMode'));
    if (notifselected !== 100) {
      this.notifMode = notifselected;
    }
    else if (this.sentNotifCount > 0) {
      this.notifMode = 0;
    }
    else if (this.receiveNotifCount > 0) {
      this.notifMode = 1;
    }
    else if (this.copyNotifCount > 0) {
      this.notifMode = 2;
    }
    else if (this.commentNotifCount > 0) {
      this.notifMode = 3;
    }

  }

  ngOnInit() {

    merge(this.sendObs, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          if (this.notifMode == 0) {
            this.sendbtnColor = 'white';
            this.sendbtnBackground = '#57b031';
            this.receivebtnColor = '#57b031';
            this.receivebtnBackground = 'white';
            this.copybtnColor = '#57b031';
            this.copybtnBackground = 'white';
            this.answerbtnColor = '#57b031';
            this.answerbtnBackground = 'white';

          }
          else if (this.notifMode == 1 ) {
            this.sendbtnColor = '#57b031';
            this.sendbtnBackground = 'white';
            this.receivebtnColor = 'white';
            this.receivebtnBackground = '#57b031';
            this.copybtnColor = '#57b031';
            this.copybtnBackground = 'white';
            this.answerbtnColor = '#57b031';
            this.answerbtnBackground = 'white';
          }
          else if (this.notifMode == 2) {
            this.sendbtnColor = '#57b031';
            this.sendbtnBackground = 'white';
            this.receivebtnColor = '#57b031';
            this.receivebtnBackground = 'white';
            this.copybtnColor = 'white';
            this.copybtnBackground = '#57b031';
            this.answerbtnColor = '#57b031';
            this.answerbtnBackground = 'white';
          }
          else if (this.notifMode == 3) {
            this.sendbtnColor = '#57b031';
            this.sendbtnBackground = 'white';
            this.receivebtnColor = '#57b031';
            this.receivebtnBackground = 'white';
            this.copybtnColor = '#57b031';
            this.copybtnBackground = 'white';
            this.answerbtnColor = 'white';
            this.answerbtnBackground = '#57b031';
          }
          //console.log('dans map notifmode ==>'+this.notifMode);
          return this.authService.getProjectNotification(this.notifMode, (this.paginator.pageIndex+1), this.perPage);
        }),
        map(data => {

          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;

          this.resultsLength = data['hydra:totalItems'];

          return data['hydra:member'];

        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the  API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => {

          this.projectArray = [];
          this.lastDate = new Date('01/01/4970');

          //data = data.filter(item=>!item.action);

          for (let index = 0; index < data.length; index++) {
            let currentProjet = data[index];
            this.projectArray.push(this.dataParse(currentProjet));
          };

          let afterIsNewDay = false;
          this.projectArray.reverse().forEach((element, index) => {
            if (afterIsNewDay || index == 0) {
              this.projectArray[index]["afterIsNewDay"] = true;
              afterIsNewDay = false;
              //console.log("afterIsNewDay")
            }
            if (element.isNewDay == true) {
              afterIsNewDay = true;
            }
          });

          this.dataSource = this.projectArray.reverse();

      });

  }

  updateTalbeData(type){
    console.log("updateTalbeData "+type);
    this.notifMode = type;
    localStorage.setItem('notifMode', type);
    this.dataSource = [];
    this.paginator.pageIndex = 0;
    this.sendObs.next(null);
    this.getNotification();
    this.updateNotifications();
  }


  ngAfterViewInit() {
    //console.log("menuuuu "+notifselected);
    /*
    var notifselected = Number(localStorage.getItem('notifMode'));
    
    if (notifselected !== 100) {
      this.updateTalbeData(notifselected);
    }
    else if (this.sentNotifCount > 0) {
      this.updateTalbeData(0);
    }
    else if (this.receiveNotifCount > 0) {
      this.updateTalbeData(1);
    }
    else if (this.copyNotifCount > 0) {
      this.updateTalbeData(2);
    }
    else if (this.commentNotifCount > 0) {
      this.updateTalbeData(3);
    }
    */

  }


  dataParse(data: any) {

    var strId = data['project']['@id'];
    var splittedID = strId.split("/");
    var idProject = splittedID[splittedID.length - 1];
    var listTag: any[] = data['tags'];
    var date = data['dateCreated'];
    var isNewDay = this.isNewDay(date);
    var typeMessage = 'QUESTION';

    if (data['type'] == 'QUESTION') {
      typeMessage = 'Question';
      const rowData: any = {
        'id': idProject,
        'receptiondate': data['dateModified'],
        'type': data['type'],
        'typeParse': typeMessage,
        'project': data['project']['libelle'],
        'suject': (listTag[0] ? listTag[0].libelle : ''),
        'sender': (data['author'] ? data['author']['fullName'] : ''),
        'senderid': (data['author'] ? data['author']['id'] : ''),
        'receiver': (data['recipient'] ? data['recipient']['fullName'] : ''),
        'receiverid': (data['recipient'] ? data['recipient']['id'] : ''),
        'isGeneral': data['isGeneral'],
        'consult': data['smartNumber'],
        'number': data['number'],
        'filegeneric': data['filegeneric'],
        'isMessageAnswered': (data['isMessageAnswered'] === true ? 'Répondu' : 'Non répondu'),
        'msgAnswered': data['isMessageAnswered'],
        'isNewDay': isNewDay
      };
      return rowData;

    }
    else if (data['type'] == 'COMMENT') {
      typeMessage = 'Commentaire';
      const rowData: any = {
        'id': idProject,
        'receptiondate': data['dateModified'],
        'type': data['type'],
        'typeParse': typeMessage,
        'project': data['project']['libelle'],
        'suject': (listTag[0] ? listTag[0].libelle : ''),
        'sender': (data['author'] ? data['author']['fullName'] : ''),
        'receiver': (data['recipient'] ? data['recipient']['fullName'] : ''),
        'consult': data['smartNumber'],
        'number': data['number'],
        'parentItem': data['parentItem'],
        'filegeneric': data['filegeneric'],
        'isMessageAnswered': (data['isMessageAnswered'] === true ? 'Répondu' : 'Non répondu'),
        'msgAnswered': data['isMessageAnswered'],
        'isNewDay': isNewDay
      };
      return rowData;

    }
    else if (data['type'] == 'ANSWER') {
      typeMessage = 'Réponse';
      const rowData: any = {
        'id': idProject,
        'receptiondate': data['dateModified'],
        'type': data['type'],
        'typeParse': typeMessage,
        'project': data['project']['libelle'],
        'suject': (listTag[0] ? listTag[0].libelle : ''),
        'sender': (data['recipient'] ? data['recipient']['fullName'] : ''),
        'receiver': (data['author'] ? data['author']['fullName'] : ''),
        'consult': data['smartNumber'],
        'number': data['number'],
        'filegeneric': data['filegeneric'],
        'isMessageAnswered': (data['isMessageAnswered'] === true ? 'Répondu' : 'Non répondu'),
        'msgAnswered': data['isMessageAnswered'],
        'isNewDay': isNewDay
      };
      return rowData;
    }
    else
    {
      const rowData: any = {
        'id': idProject,
        'receptiondate': data['dateModified'],
        'type': data['type'],
        'typeParse': typeMessage,
        'project': data['project']['libelle'],
        'suject': (listTag[0] ? listTag[0].libelle : ''),
        'sender': (data['author'] ? data['author']['fullName'] : ''),
        'receiver': (data['recipient'] ? data['recipient']['fullName'] : ''),
        'consult': data['smartNumber'],
        'number': data['number'],
        'filegeneric': data['filegeneric'],
        'isMessageAnswered': (data['isMessageAnswered'] === true ? 'Répondu' : 'Non répondu'),
        'msgAnswered': data['isMessageAnswered'],
        'isNewDay': isNewDay
      };
      return rowData;
    }

  }

  onNoClick(): void {
    //console.log('data dialog close');
    this.dialogRef.close();
  }

  isNewDay(messageDate) {
    let currentDate = new Date(messageDate.substring(0, 10));
    //console.log('this.lastDate ==> '+this.lastDate);
    //console.log('currentDate ==> '+currentDate);
    if (currentDate < this.lastDate) {
      //console.log('Une section ==> '+ currentDate);
      this.lastDate = currentDate;
      return true;
    }
    else {
      this.lastDate = currentDate;
      return false;
    }
  }

  isRoundFirst(afterIsNewDay, isNewDay) {
    if (afterIsNewDay && isNewDay) {
      return "divcontaine afterIsNewDayBL afterIsNewDayTL";
    } else if (afterIsNewDay) {
      return "divcontaine afterIsNewDayBL";
    } else if (isNewDay) {
      return "divcontaine afterIsNewDayTL";
    }
    return "divcontaine";
  }

  isRoundLast(afterIsNewDay, isNewDay) {
    if (afterIsNewDay && isNewDay) {
      return "divcontaine afterIsNewDayBR afterIsNewDayTR";
    } else if (afterIsNewDay) {
      return "divcontaine afterIsNewDayBR";
    } else if (isNewDay) {
      return "divcontaine afterIsNewDayTR";
    }
    return "divcontaine";
  }

  updateNotifications() {
    this.authService.getProjectNotification(0, 1, 10).subscribe((res) => {
      this.sentNotifCount = res['hydra:totalItems'];
    });

    this.authService.getProjectNotification(1, 1, 10).subscribe((res) => {
      this.receiveNotifCount = res['hydra:totalItems'];
    });

    this.authService.getProjectNotification(2, 1, 10).subscribe((res) => {
      this.copyNotifCount = res['hydra:totalItems'];
    });

    this.authService.getProjectNotification(3, 1, 10).subscribe((res) => {
      this.commentNotifCount = res['hydra:totalItems'];
    });

   /* this.authService.getProjectNotification(2, 1, 10).subscribe((res) => {
      this.copyNotifCount = res['hydra:totalItems'];
    });

    this.authService.getProjectNotification(3, 1, 10).subscribe((res) => {
      this.answerNotifCount = res['hydra:totalItems'];
    });*/

  }


  getNotification() {
    console.log("getnotification ");
    this.authService.getNotificationJAction().subscribe(res => {
      //console.log("res", res)
    });
    this.authService.getNotification().subscribe(res => {
      this.authService.obNotifBadge.next(res);
    });
    this.authService.getUserInvitation(0, 10).subscribe(res => {
      this.authService.obInviteBadge.next(res);
    });
  }


}

@Component({
  selector: 'dashboard-dialogNotif',
  templateUrl: 'dashboard-dialogProjectNotif.html',
  styleUrls: ['dashboard-dialogProjectNotif.scss']
})
export class DashboardDialogProjectNotif implements OnInit, AfterViewInit {

  /*displayedColumns: string[] = ['receptiondate','sender','receiver','project', 'suject',
    'Statut','consult'];*/

  displayedColumns: string[] = ['receptiondate', 'sender', 'receiver', 'project', 'suject', 'consult'];
  displayedColumnsbis: string[] = ['receptiondate', 'sender', 'receiver', 'project', 'suject', 'consult'];

  dataSource: any[];
  resultsLength = 0;
  perPage = 30;
  notifMode = 1;
  isLoadingResults = true;
  isRateLimitReached = false;
  projectArray: any[];
  lastDate = new Date('01/01/4970');
  sendbtnColor = 'white';
  sendbtnBackground = '#57b031'
  receivebtnColor = '#57b031';
  receivebtnBackground = 'white'
  copybtnColor = '#57b031';
  copybtnBackground = 'white'
  answerbtnColor = '#57b031';
  answerbtnBackground = 'white'

  sentNotifCount = 0;
  receiveNotifCount = 0;
  copyNotifCount = 0;
  commentNotifCount = 0;
  secteur = "";
  idprojet = null;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;


  public sendObs = new Subject();
  public sendObs$ = this.sendObs.asObservable();



  constructor(private authService: AuthService,
    public dialogRef: MatDialogRef<DashboardDialogNotif>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataNotif) {
    console.log("data "+JSON.stringify(data))
    this.sentNotifCount = data.sentNotifCount;
    this.receiveNotifCount = data.receiveNotifCount;
    this.copyNotifCount = data.copyNotifCount;
    this.commentNotifCount = data.commentNotifCount;
    if(data.idprojet){
      this.idprojet = data.idprojet;
    }
    console.log("onlyproject "+this.sentNotifCount);
    this.secteur = localStorage.getItem('secteur');
    /*var notifselected = Number(localStorage.getItem('notifprojectMode'));
    if (notifselected !== 100) {
      this.notifMode = notifselected;
    }
    else */
    if (this.receiveNotifCount > 0) {
      this.notifMode = 1;
    } else if (this.copyNotifCount > 0) {
      this.notifMode = 2;
    } else if (this.commentNotifCount > 0) {
      this.notifMode = 3;
    } else if (this.sentNotifCount > 0) {
      this.notifMode = 0;
    } 
    console.log("notifMode const "+this.notifMode)

  }

  ngOnInit() {

    merge(this.sendObs, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          //console.log("not modee "+this.notifMode)
          if (this.notifMode == 1 ) {
            //console.log("receive before "+this.receiveNotifCount)
            this.notifMode == 1
            //console.log("nooot sent : "+this.sentNotifCount)
            this.sendbtnColor = '#57b031';
            this.sendbtnBackground = 'white';
            this.receivebtnColor = 'white';
            this.receivebtnBackground = '#57b031';
            this.copybtnColor = '#57b031';
            this.copybtnBackground = 'white';
            this.answerbtnColor = '#57b031';
            this.answerbtnBackground = 'white';
          } else if (this.notifMode == 0) {
            console.log("sent before "+this.sentNotifCount)
            this.notifMode == 0
            this.sendbtnColor = 'white';
            this.sendbtnBackground = '#57b031';
            this.receivebtnColor = '#57b031';
            this.receivebtnBackground = 'white';
            this.copybtnColor = '#57b031';
            this.copybtnBackground = 'white';
            this.answerbtnColor = '#57b031';
            this.answerbtnBackground = 'white';

          } else if (this.notifMode == 2) {
            console.log("copy before "+this.copyNotifCount)
            this.notifMode == 2
            this.sendbtnColor = '#57b031';
            this.sendbtnBackground = 'white';
            this.receivebtnColor = '#57b031';
            this.receivebtnBackground = 'white';
            this.copybtnColor = 'white';
            this.copybtnBackground = '#57b031';
            this.answerbtnColor = '#57b031';
            this.answerbtnBackground = 'white';
          } else if (this.notifMode == 3) {
            console.log("comment before "+this.commentNotifCount)
            this.notifMode == 3
            this.sendbtnColor = '#57b031';
            this.sendbtnBackground = 'white';
            this.receivebtnColor = '#57b031';
            this.receivebtnBackground = 'white';
            this.copybtnColor = '#57b031';
            this.copybtnBackground = 'white';
            this.answerbtnColor = 'white';
            this.answerbtnBackground = '#57b031';
          }
          //console.log('dans map notifmode ==>'+this.notifMode);
          return this.authService.getOnlyProjectNotification(this.notifMode, (this.paginator.pageIndex+1), this.perPage, this.idprojet);
        }),
        map(data => {

          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;

          this.resultsLength = data['hydra:totalItems'];
          if(this.notifMode == 0){
            const startIndex = (this.paginator.pageIndex) * this.perPage;
            const endIndex = startIndex + this.perPage;
            return data['hydra:member'].slice(startIndex, endIndex);
          }

          return data['hydra:member'];

        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the  API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => {

          this.projectArray = [];
          this.lastDate = new Date('01/01/4970');

          //data = data.filter(item=>!item.action);

          for (let index = 0; index < data.length; index++) {
            let currentProjet = data[index];
            this.projectArray.push(this.dataParse(currentProjet));
          };

          let afterIsNewDay = false;
          this.projectArray.reverse().forEach((element, index) => {
            if (afterIsNewDay || index == 0) {
              this.projectArray[index]["afterIsNewDay"] = true;
              afterIsNewDay = false;
              //console.log("afterIsNewDay")
            }
            if (element.isNewDay == true) {
              afterIsNewDay = true;
            }
          });

          this.dataSource = this.projectArray.reverse();
          if(this.resultsLength > 0) this.dataSource[0]["first"] = true;

      });

  }

  updateTalbeData(type){
    console.log("updateTalbeData change "+type);
    this.notifMode = type;
    localStorage.setItem('notifprojectMode', type);
    this.dataSource = [];
    this.paginator.pageIndex = 0;
    this.sendObs.next(null);
    this.getProjectNotification();
    this.updateProjectNotifications();
  }


  ngAfterViewInit() {
    //console.log("menuuuu "+notifselected);
    /*
    var notifselected = Number(localStorage.getItem('notifprojectMode'));
    
    if (notifselected !== 100) {
      this.updateTalbeData(notifselected);
    }
    else if (this.sentNotifCount > 0) {
      this.updateTalbeData(0);
    }
    else if (this.receiveNotifCount > 0) {
      this.updateTalbeData(1);
    }
    else if (this.copyNotifCount > 0) {
      this.updateTalbeData(2);
    }
    else if (this.commentNotifCount > 0) {
      this.updateTalbeData(3);
    }*/

  }


  dataParse(data: any) {

    var strId = data['project']['@id'];
    var splittedID = strId.split("/");
    var idProject = splittedID[splittedID.length - 1];
    var listTag: any[] = data['tags'];
    var date = data['dateCreated'];
    var isNewDay = this.isNewDay(date);
    var typeMessage = 'QUESTION';

    if (data['type'] == 'QUESTION') {
      typeMessage = 'Question';
      const rowData: any = {
        'id': idProject,
        'receptiondate': data['dateModified'],
        'first': false,
        'type': data['type'],
        'typeParse': typeMessage,
        'project': data['project']['libelle'],
        'suject': (listTag[0] ? listTag[0].libelle : ''),
        'sender': (data['author'] ? data['author']['fullName'] : ''),
        'senderid': (data['author'] ? data['author']['id'] : ''),
        'receiver': (data['recipient'] ? data['recipient']['fullName'] : ''),
        'receiverid': (data['recipient'] ? data['recipient']['id'] : ''),
        'isGeneral': data['isGeneral'],
        'consult': data['smartNumber'],
        'number': data['number'],
        'filegeneric': data['filegeneric'],
        'isMessageAnswered': (data['isMessageAnswered'] === true ? 'Répondu' : 'Non répondu'),
        'msgAnswered': data['isMessageAnswered'],
        'isNewDay': isNewDay
      };
      return rowData;

    }
    else if (data['type'] == 'COMMENT') {
      typeMessage = 'Commentaire';
      const rowData: any = {
        'id': idProject,
        'receptiondate': data['dateModified'],
        'first': false,
        'type': data['type'],
        'typeParse': typeMessage,
        'project': data['project']['libelle'],
        'suject': (listTag[0] ? listTag[0].libelle : ''),
        'sender': (data['author'] ? data['author']['fullName'] : ''),
        'receiver': (data['recipient'] ? data['recipient']['fullName'] : ''),
        'consult': data['smartNumber'],
        'number': data['number'],
        'parentItem': data['parentItem'],
        'filegeneric': data['filegeneric'],
        'isMessageAnswered': (data['isMessageAnswered'] === true ? 'Répondu' : 'Non répondu'),
        'msgAnswered': data['isMessageAnswered'],
        'isNewDay': isNewDay
      };
      return rowData;

    }
    else if (data['type'] == 'ANSWER') {
      typeMessage = 'Réponse';
      const rowData: any = {
        'id': idProject,
        'receptiondate': data['dateModified'],
        'first': false,
        'type': data['type'],
        'typeParse': typeMessage,
        'project': data['project']['libelle'],
        'suject': (listTag[0] ? listTag[0].libelle : ''),
        'sender': (data['recipient'] ? data['recipient']['fullName'] : ''),
        'receiver': (data['author'] ? data['author']['fullName'] : ''),
        'consult': data['smartNumber'],
        'number': data['number'],
        'filegeneric': data['filegeneric'],
        'isMessageAnswered': (data['isMessageAnswered'] === true ? 'Répondu' : 'Non répondu'),
        'msgAnswered': data['isMessageAnswered'],
        'isNewDay': isNewDay
      };
      return rowData;
    }
    else
    {
      const rowData: any = {
        'id': idProject,
        'receptiondate': data['dateModified'],
        'first': false,
        'type': data['type'],
        'typeParse': typeMessage,
        'project': data['project']['libelle'],
        'suject': (listTag[0] ? listTag[0].libelle : ''),
        'sender': (data['author'] ? data['author']['fullName'] : ''),
        'receiver': (data['recipient'] ? data['recipient']['fullName'] : ''),
        'consult': data['smartNumber'],
        'number': data['number'],
        'filegeneric': data['filegeneric'],
        'isMessageAnswered': (data['isMessageAnswered'] === true ? 'Répondu' : 'Non répondu'),
        'msgAnswered': data['isMessageAnswered'],
        'isNewDay': isNewDay
      };
      return rowData;
    }

  }

  onNoClick(): void {
    //console.log('data dialog close');
    this.dialogRef.close();
  }

  isNewDay(messageDate) {
    let currentDate = new Date(messageDate.substring(0, 10));
    //console.log('this.lastDate ==> '+this.lastDate);
    //console.log('currentDate ==> '+currentDate);
    if (currentDate < this.lastDate) {
      //console.log('Une section ==> '+ currentDate);
      this.lastDate = currentDate;
      return true;
    }
    else {
      this.lastDate = currentDate;
      return false;
    }
  }

  isRoundFirst(afterIsNewDay, isNewDay) {
    if (afterIsNewDay && isNewDay) {
      return "divcontaine afterIsNewDayBL afterIsNewDayTL";
    } else if (afterIsNewDay) {
      return "divcontaine afterIsNewDayBL";
    } else if (isNewDay) {
      return "divcontaine afterIsNewDayTL";
    }
    return "divcontaine";
  }

  isRoundLast(afterIsNewDay, isNewDay) {
    if (afterIsNewDay && isNewDay) {
      return "divcontaine afterIsNewDayBR afterIsNewDayTR";
    } else if (afterIsNewDay) {
      return "divcontaine afterIsNewDayBR";
    } else if (isNewDay) {
      return "divcontaine afterIsNewDayTR";
    }
    return "divcontaine";
  }

  updateProjectNotifications() {
    this.authService.getAllProjectNotification(0, 1, 10, this.idprojet).subscribe((res) => {
      this.sentNotifCount = res['hydra:member']['sent'];
      this.receiveNotifCount = res['hydra:member']['receive'];
      this.copyNotifCount = res['hydra:member']['copy'];
      this.commentNotifCount = res['hydra:member']['comment'];
      console.log("afteeer "+this.sentNotifCount)
      console.log("receive "+this.receiveNotifCount)
    });

  }

  updateProjectNotificationsOld() {
    console.log("noot "+this.sentNotifCount)
    this.authService.getOnlyProjectNotification(0, 1, 10, this.idprojet).subscribe((res) => {
      this.sentNotifCount = res['hydra:totalItems'];
      console.log("afteeer "+this.sentNotifCount)
    });

    this.authService.getOnlyProjectNotification(1, 1, 10, this.idprojet).subscribe((res) => {
      this.receiveNotifCount = res['hydra:totalItems'];
    });

    this.authService.getOnlyProjectNotification(2, 1, 10, this.idprojet).subscribe((res) => {
      this.copyNotifCount = res['hydra:totalItems'];
    });

    this.authService.getOnlyProjectNotification(3, 1, 10, this.idprojet).subscribe((res) => {
      this.commentNotifCount = res['hydra:totalItems'];
    });

   /* this.authService.getProjectNotification(2, 1, 10).subscribe((res) => {
      this.copyNotifCount = res['hydra:totalItems'];
    });

    this.authService.getProjectNotification(3, 1, 10).subscribe((res) => {
      this.answerNotifCount = res['hydra:totalItems'];
    });*/

  }


  getProjectNotification() {
    console.log("getnotification ");
    this.authService.getNotification().subscribe(res => {
      this.authService.obNotifBadge.next(res);
    });
    this.authService.getUserInvitation(0, 10).subscribe(res => {
      this.authService.obInviteBadge.next(res);
    });
  }


}

/////////////////// View invitation ///////////////////

export interface invitationElement {
  projectId: string;
  projectName: string;
  givenName: string;
  familyName: string;
  projectImage: string;
  userhote: string;
  dateCreated: string;
  idProject: string;
}

@Component({
  selector: 'invitation-dialog',
  templateUrl: 'invitation-dialog.html',
  styleUrls: ['invitation-dialog.scss']
})
export class InvitationDialogComponent implements AfterViewInit {

  invitationList = null;
  isLoadingResults = true;
  isRateLimitReached = false;
  resultsLength = 0;
  perPage = 10;
  secteur = "";

  private refreshObs = new Subject();
  public refreshObs$ = this.refreshObs.asObservable();
  currentUser: any;

  ELEMENT_DATA: invitationElement[] = [];
  //@Input() ongletId: string;

  displayedColumns: string[] = ['projectImage', 'projectName', 'idProject'];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(public dialogRef: MatDialogRef<InvitationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dataParam: any,
    private authService: AuthService,
    private socketService: SocketService,
    private messageService: MessageService
  ) {
    this.secteur = localStorage.getItem('secteur');
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))['id'];
    /*this.authService.getUserInvitation().subscribe( invitationData => {
        //console.log('retour liste invitation '+invitationData);
        this.invitationList = invitationData['hydra:member'];
    });*/

  }

  ngAfterViewInit() {

    this.authService.obreceiveInvitation.subscribe((idMessage) =>{ 
      console.log("Iinvitation meme compte ");
      this.refreshObs.next();
    });

    merge(this.paginator.page, this.refreshObs)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.authService.getUserInvitation(this.paginator.pageIndex, this.perPage);
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data['hydra:totalItems'];

          return data['hydra:member'];
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => {
        //var ELEMENT_DATA: invitationElement[] = [];
        this.ELEMENT_DATA = [];
        for (var i = 0; i < data.length; i++) {
          this.ELEMENT_DATA.push(this.parseData(data[i]));
        }
        this.invitationList = new MatTableDataSource(this.ELEMENT_DATA);
      });

  }

  parseData(itemData) {
    var item: invitationElement = {
      projectId: itemData.project.id,
      projectName: itemData.project.libelle,
      projectImage: itemData.userhote.imageUrl,
      userhote: itemData.userhote.fullName,
      givenName: itemData.userhote.givenName,
      familyName: itemData.userhote.familyName,
      dateCreated: itemData.createdAt,
      idProject: itemData.id
    };
    return item;
  }

  applyFilter(filterValue: string) {
    this.invitationList.filter = filterValue.trim().toLowerCase();
  }


  getAvatarlUrl(avatarLink) {
    return environment.server_url + avatarLink;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  accepteProjet(invitationID) {
    this.authService.accepterProjet(invitationID).subscribe(dataAccepte => {
      //console.log('retour projet accepte '+JSON.stringify(dataAccepte));
    }, (error) => {
      alert('une erreur est survenue');
    });
  }

  onClickAccepter(invitationID, projectId) {
    console.log("idproooj : "+projectId);
    console.log("iduser : "+this.currentUser);
    this.authService.accepterProjet(invitationID).subscribe(dataAccepte => {
      console.log("before socket accept : ");
      //console.log('retour projet accepte '+JSON.stringify(dataAccepte));
      this.socketService.sendInstant({
        id: "0",
        action: "0",
        type: "PROJECTINVITATION",
        author: this.currentUser,
        author_name: "",
        recipient: projectId,
        text: "",
        copy: "",
        private: false,
        res: "",
        project_lib: "",
        date: ""
      });
      console.log("after socket accept : ");
      this.messageService.messageUpdated();
      /*
      this.messageService.nextReloadPropostionNotif();
      this.messageService.nextReloadNotifAction();*/
      this.dialogRef.close(dataAccepte);
    }, (error) => {
      //console.log('Uh-oh, an error occurred! : ' + JSON.stringify(error));
      alert('une erreur est survenue');
    });
  }

  onClickRefuser(invitationID, projectId) {
    console.log("idproooj : "+projectId);
    console.log("iduser : "+this.currentUser);
    this.authService.declineProjet(invitationID).subscribe(dataDecline => {
      console.log("before socket refuser : ");
      this.socketService.sendInstant({
        id: "0",
        action: "0",
        type: "PROJECTINVITATION",
        author: this.currentUser,
        author_name: "",
        recipient: projectId,
        text: "",
        copy: "",
        private: false,
        res: "",
        project_lib: "",
        date: ""
      });
      this.refreshObs.next();
      console.log("after socket refuser : ");
      this.authService.obInviteInstant.next(0);
    }, (error) => {
      //console.log('Uh-oh, an error occurred! : ' + JSON.stringify(error));
      alert('une erreur est survenue');
    });
  }

  accepteAll() {
    //console.log(' accepteAll ==> '+this.ELEMENT_DATA.length);
    for (var i = 0; i < this.ELEMENT_DATA.length; i++) {
      if (i == (this.ELEMENT_DATA.length - 1)) {
        this.onClickAccepter(this.ELEMENT_DATA[i].idProject, "all");
      }
      else {
        this.accepteProjet(this.ELEMENT_DATA[i].idProject);
      }
    }
    this.messageService.nextReloadPropostionNotif();
    this.messageService.nextReloadNotifAction();
  }

}



@Component({
  selector: 'tutoStepdialog',
  templateUrl: 'tutoStepdialog.html',
  styleUrls: ['tutoStepdialog.scss']
})
export class tutoStepdialog implements OnInit {
  lang = 'fr';

  constructor(
    public dialogRef: MatDialogRef<tutoStepdialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private messageService: MessageService,
    private _formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.messageService.getTranslate().subscribe((data) => {
      this.lang = data;
    });
  }



  onNoClick(): void {
    this.dialogRef.close();
  }



}

/////////////////// notification view dialog reunion ///////////////////
/*
@Component({
  selector: 'dashboard-dialogNotifReunion',
  templateUrl: 'dashboard-dialogNotifReunion.html',
  styleUrls: ['dashboard-dialogNotifReunion.scss']
})
export class DashboardDialogNotifReunion implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['receptiondate', 'sender', 'receiver',  'project', 'suject',  'consult'];
  displayedColumnsbis: string[] = ['receptiondate', 'sender', 'receiver',  'project', 'suject', 'consult'];

  dataSource: any[];
  resultsLength = 0;
  perPage = 30;
  notifMode = 2;
  isLoadingResults = true;
  isRateLimitReached = false;
  projectArray: any[];
  lastDate = new Date('01/01/4970');
  sendbtnColor = 'white';
  sendbtnBackground = '#5d97eb'
  receivebtnColor = '#5d97eb';
  receivebtnBackground = 'white'
  copybtnColor = '#5d97eb';
  copybtnBackground = 'white'
  answerbtnColor = '#5d97eb';
  answerbtnBackground = 'white'

  sentNotifCount = 0;
  receiveNotifCount = 0;
  copyNotifCount = 0;
  commentNotifCount = 0;
  secteur = "";

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;


  public sendObs = new Subject();
  public sendObs$ = this.sendObs.asObservable();



  constructor(private authService: AuthService,
    public dialogRef: MatDialogRef<DashboardDialogNotifReunion>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataNotif) {

    this.sentNotifCount = data.sentNotifCount;
    this.receiveNotifCount = data.receiveNotifCount;
    this.copyNotifCount = data.copyNotifCount;
    this.commentNotifCount = data.commentNotifCount;
    var notifselected = Number(localStorage.getItem('notifModeReunion'));
    this.secteur = localStorage.getItem('secteur');

    if (notifselected !== 100) {
      this.notifMode = notifselected;
    }
    else if (this.copyNotifCount > 0) {
      this.notifMode = 2;
    }
    else  {
      this.notifMode = 3;
    }

  }

  ngOnInit() {

    merge(this.sendObs, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          if (this.notifMode == 0) {
            this.sendbtnColor = 'white';
            this.sendbtnBackground = '#5d97eb';
            this.receivebtnColor = '#5d97eb';
            this.receivebtnBackground = 'white';
            this.copybtnColor = '#5d97eb';
            this.copybtnBackground = 'white';
            this.answerbtnColor = '#5d97eb';
            this.answerbtnBackground = 'white';

          }
          else if (this.notifMode == 1 ) {
            this.sendbtnColor = '#5d97eb';
            this.sendbtnBackground = 'white';
            this.receivebtnColor = 'white';
            this.receivebtnBackground = '#5d97eb';
            this.copybtnColor = '#5d97eb';
            this.copybtnBackground = 'white';
            this.answerbtnColor = '#5d97eb';
            this.answerbtnBackground = 'white';
          }
          else if (this.notifMode == 2) {
            this.sendbtnColor = '#5d97eb';
            this.sendbtnBackground = 'white';
            this.receivebtnColor = '#5d97eb';
            this.receivebtnBackground = 'white';
            this.copybtnColor = 'white';
            this.copybtnBackground = '#5d97eb';
            this.answerbtnColor = '#5d97eb';
            this.answerbtnBackground = 'white';
          }
          else if (this.notifMode == 3) {
            this.sendbtnColor = '#5d97eb';
            this.sendbtnBackground = 'white';
            this.receivebtnColor = '#5d97eb';
            this.receivebtnBackground = 'white';
            this.copybtnColor = '#5d97eb';
            this.copybtnBackground = 'white';
            this.answerbtnColor = 'white';
            this.answerbtnBackground = '#5d97eb';
          }
          //console.log('dans map notifmode ==>'+this.notifMode);
          return this.authService.getMettingNotification(this.notifMode, (this.paginator.pageIndex+1), this.perPage);
        }),
        map(data => {

          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;

          this.resultsLength = data['hydra:totalItems'];

          return data['hydra:member'];

        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the  API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => {

          this.projectArray = [];
          this.lastDate = new Date('01/01/4970');

          //data = data.filter(item=>!item.action);

          for (let index = 0; index < data.length; index++) {
            let currentProjet = data[index];
            this.projectArray.push(this.dataParse(currentProjet));
          };

          let afterIsNewDay = false;
          this.projectArray.reverse().forEach((element, index) => {
            if (afterIsNewDay || index == 0) {
              this.projectArray[index]["afterIsNewDay"] = true;
              afterIsNewDay = false;
              //console.log("afterIsNewDay")
            }
            if (element.isNewDay == true) {
              afterIsNewDay = true;
            }
          });

          this.dataSource = this.projectArray.reverse();
          //console.log("project = "+JSON.stringify(this.dataSource));

      });

  }

  updateTalbeData(type){
    this.notifMode = type;
    this.dataSource = [];
    this.paginator.pageIndex = 0;
    this.sendObs.next(null);
    this.getNotification();
    this.updateNotifications();
    localStorage.setItem('notifModeReunion', type);
  }


  ngAfterViewInit() {
    var notifselected = Number(localStorage.getItem('notifModeReunion'));
    if (notifselected !== 100) {
      this.updateTalbeData(notifselected);
    }
    else if (this.copyNotifCount > 0) {
      this.updateTalbeData(2);
    }
    else {
      this.updateTalbeData(3);
    }

  }


  dataParse(data: any) {

    var strId = data['project']['@id'];
    var splittedID = strId.split("/");
    var idProject = splittedID[splittedID.length - 1];
    var listTag: any[] = data['tags'];
    var date = data['dateCreated'];
    var isNewDay = this.isNewDay(date);
    var typeMessage = 'QUESTION';

    if (data['type'] == 'QUESTION') {
      typeMessage = 'Question';
      const rowData: any = {
        'id': idProject,
        'receptiondate': data['dateModified'],
        'type': data['type'],
        'typeParse': typeMessage,
        'project': data['project']['libelle'],
        'suject': (listTag[0] ? listTag[0].libelle : ''),
        'sender': (data['author'] ? data['author']['fullName'] : ''),
        'receiver': (data['recipient'] ? data['recipient']['fullName'] : ''),
        'consult': data['smartNumber'],
        'number': data['number'],
        'filegeneric': data['filegeneric'],
        'isMessageAnswered': (data['isMessageAnswered'] === true ? 'Répondu' : 'Non répondu'),
        'msgAnswered': data['isMessageAnswered'],
        'isNewDay': isNewDay
      };
      return rowData;

    }
    else if (data['type'] == 'COMMENT') {
      typeMessage = 'Commentaire';
      const rowData: any = {
        'id': idProject,
        'receptiondate': data['dateModified'],
        'type': data['type'],
        'typeParse': typeMessage,
        'project': data['project']['libelle'],
        'suject': (listTag[0] ? listTag[0].libelle : ''),
        'sender': (data['author'] ? data['author']['fullName'] : ''),
        'receiver': (data['recipient'] ? data['recipient']['fullName'] : ''),
        'consult': data['smartNumber'],
        'number': data['number'],
        'filegeneric': data['filegeneric'],
        'isMessageAnswered': (data['isMessageAnswered'] === true ? 'Répondu' : 'Non répondu'),
        'msgAnswered': data['isMessageAnswered'],
        'isNewDay': isNewDay
      };
      return rowData;

    }
    else if (data['type'] == 'ANSWER') {
      typeMessage = 'Réponse';
      const rowData: any = {
        'id': idProject,
        'receptiondate': data['dateModified'],
        'type': data['type'],
        'typeParse': typeMessage,
        'project': data['project']['libelle'],
        'suject': (listTag[0] ? listTag[0].libelle : ''),
        'sender': (data['recipient'] ? data['recipient']['fullName'] : ''),
        'receiver': (data['author'] ? data['author']['fullName'] : ''),
        'consult': data['smartNumber'],
        'number': data['number'],
        'filegeneric': data['filegeneric'],
        'isMessageAnswered': (data['isMessageAnswered'] === true ? 'Répondu' : 'Non répondu'),
        'msgAnswered': data['isMessageAnswered'],
        'isNewDay': isNewDay
      };
      return rowData;
    }
    else
    {
      const rowData: any = {
        'id': idProject,
        'receptiondate': data['dateModified'],
        'type': data['type'],
        'typeParse': typeMessage,
        'project': data['project']['libelle'],
        'suject': (listTag[0] ? listTag[0].libelle : ''),
        'sender': (data['author'] ? data['author']['fullName'] : ''),
        'receiver': (data['recipient'] ? data['recipient']['fullName'] : ''),
        'consult': data['smartNumber'],
        'number': data['number'],
        'filegeneric': data['filegeneric'],
        'isMessageAnswered': (data['isMessageAnswered'] === true ? 'Répondu' : 'Non répondu'),
        'msgAnswered': data['isMessageAnswered'],
        'isNewDay': isNewDay
      };
      return rowData;
    }

  }

  onNoClick(): void {
    //console.log('data dialog close');
    this.dialogRef.close();
  }

  isNewDay(messageDate) {
    let currentDate = new Date(messageDate.substring(0, 10));
    //console.log('this.lastDate ==> '+this.lastDate);
    //console.log('currentDate ==> '+currentDate);
    if (currentDate < this.lastDate) {
      //console.log('Une section ==> '+ currentDate);
      this.lastDate = currentDate;
      return true;
    }
    else {
      this.lastDate = currentDate;
      return false;
    }
  }

  isRoundFirst(afterIsNewDay, isNewDay) {
    if (afterIsNewDay && isNewDay) {
      return "divcontaine afterIsNewDayBL afterIsNewDayTL";
    } else if (afterIsNewDay) {
      return "divcontaine afterIsNewDayBL";
    } else if (isNewDay) {
      return "divcontaine afterIsNewDayTL";
    }
    return "divcontaine";
  }

  isRoundLast(afterIsNewDay, isNewDay) {
    if (afterIsNewDay && isNewDay) {
      return "divcontaine afterIsNewDayBR afterIsNewDayTR";
    } else if (afterIsNewDay) {
      return "divcontaine afterIsNewDayBR";
    } else if (isNewDay) {
      return "divcontaine afterIsNewDayTR";
    }
    return "divcontaine";
  }


  getNotification() {
    this.authService.getNotificationJAction().subscribe(res => {
      //console.log("res", res)
    });
    this.authService.getNotification().subscribe(res => {
      this.authService.obNotifBadge.next(res);
    });
    this.authService.getUserInvitation(0, 10).subscribe(res => {
      this.authService.obInviteBadge.next(res);
    });
  }

  


}
*/




