import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm} from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  
  	//@ViewChild('theForm') theForm: FormGroupDirective;
 	sendMessageForm: FormGroup;
 	particulier = false;
  	professionnel = false;
	submitted = false;
	partenaire = null;

  	constructor( private authService: AuthService,
    private formBuilder: FormBuilder) {

  		/*this.sendMessageForm = this.formBuilder.group({
		  givenName: ['', Validators.required],
		  lastName: ['', Validators.required],
		  email: ['', Validators.required],
		  messageToSend: ['', [Validators.required, Validators.minLength(1)]],
		});*/

	this.sendMessageForm = this.formBuilder.group({
        type: [''],
        objet: ['', Validators.required],
        adresse: ['', Validators.required],
        message: ['', Validators.required]
     });

   	}

  	ngOnInit() {
  	}	
  	
  	onSubmitMessage(){

	    this.submitted = true;
	     
	    if (this.particulier == true){
	      this.sendMessageForm.patchValue({
	        type: 'particulier'
	      });
	    } 
	    else if (this.professionnel == true ) {
	       this.sendMessageForm.patchValue({
	        type: 'professionnel'
	      });
	    }

	    console.log("valeur formulaire => "+ JSON.stringify(this.sendMessageForm.value));

	     // stop here if form is invalid
	    if (this.sendMessageForm.invalid) {
	      return;
	    }
	    const { type, objet, adresse, message} = this.sendMessageForm.value;
	    this.authService.contactMessage(
	        type,
	        objet,
	        adresse,
	        message,
	      )
	      .subscribe(res => {
	         //console.log('reponse envoi message: ',res);
	      alert("Message envoyé");
	    },
	    error => {
	       alert("oups une erreur s'est produite!");
	    });

	    this.sendMessageForm.reset();
	    this.submitted = false;  
 
  	}

  	/*onSubmitForm(customerData) {

	     // Process checkout data here
	    // console.log(JSON.stringify(this.files));
	    
	    //console.log('Your order has been submitted', customerData);

	    this.submitted = true;
	     // stop here if form is invalid
	    if (this.sendMessageForm.invalid) {
	      return;
	    }
	    
	    //this.sendQuestion(customerData);

	    this.authService.sendQuestionFormContact(
	      customerData.givenName,
	      customerData.lastName,
	      customerData.email,
	      customerData.messageToSend,
	    )
	    .subscribe(res => {
	       //console.log('reponse envoi message: ',res);
			alert("Message envoyé");
	    },
 		error => {
	       alert("oups une erreur s'est produite!");
	    });

    	this.submitted = false;
    	this.theForm.resetForm();
  	  	 
  	}*/

  	// convenience getter for easy access to form fields
 	get f() { return this.sendMessageForm.controls; }

}
