// outside-click.directive.ts

import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[appOutsideClick]'
})
export class OutsideClickDirective {
  @Output() appOutsideClick = new EventEmitter();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: any): void {
    console.log("cliekced")
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
        console.log("hors")
      this.appOutsideClick.emit();
    } else 
    console.log("in")
  }
}
