import {first} from 'rxjs/internal/operators';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'src/app/services/message/message.service';

@Component({
  selector: 'app-setnew',
  templateUrl: './setnew.component.html',
  styleUrls: ['./setnew.component.scss']
})
export class SetnewComponent implements OnInit {

  Rtoken: any = '';
  password: any = '';
  isNew = false;
  error: any = '';
  lang  = 'fr';
  hide = true;
   
  constructor( private authService: AuthService, private router: Router, private route: ActivatedRoute, private messageService: MessageService) { }

  ngOnInit() {
    if (this.authService.logIn) {
      this.router.navigate(['/']);
    }
    this.messageService.getTranslate().subscribe((data) => {
      this.lang = data;
    });
    //console.log('dans le set new component '+this.route.params['value'].token);
    this.Rtoken = this.route.params['value'].token;
  }

  setnewPass() {
    //console.log('Canging password with ' + this.password);
    this.authService.setNew( this.password, this.Rtoken )
      .pipe(first())
      .subscribe(
        () => {
          this.error = '';
          this.isNew = true;
          // console.log(data);
        },
        err => {
          const error = err.error;
          this.error = error['hydra:description'];
          //console.log(this.error);
        }
      );
  }

}
