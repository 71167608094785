import { Component, OnInit, Input, EventEmitter, Output, ViewChildren, QueryList, ElementRef} from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Lightbox } from 'ngx-lightbox';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs} from 'file-saver';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

// Composant de dialogue en ligne
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Inject } from '@angular/core';

@Component({
  selector: 'video-dialog',
  template: `
    <div class="dialogHeader" style="height: 20px; background: white">
      <div class="dialogCloseIcon" (click)="onNoClick()">
          <mat-icon aria-hidden="false" class="iconColor" aria-label="close icon" style="margin-left: 0px; color: #fb0707 !important; font-weight: bold;">close</mat-icon>
      </div>
      <div class="clearfix"></div>
    </div>
    <div mat-dialog-content>
      <video style="max-width: 330px; width: 330px;max-height: 340px;" controls>
        <source src="{{getMediaLink(data.media.contentUrl)}}" type="{{data.media.extension}}">
        Your browser does not support the video tag.
      </video>
    </div>
  `
})
export class VideoDialog {
  constructor(
    public dialogRef: MatDialogRef<VideoDialog>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: { media: any }
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  getMediaLink(mediaUrl, thumbnailPath=null){
    return this.authService.getMediaData(mediaUrl);
  }
}

@Component({
  selector: 'app-custom-accordion-face',
  templateUrl: './custom-accordion-face.component.html',
  styleUrls: ['./custom-accordion-face.component.scss']
})
export class CustomAccordionFaceComponent implements OnInit {

  openPdf= false;
  selectedPdf = null;
  displayBlock = "none";
  mediaLinks: {[key: string]: string} = {};
  @Input() media: any;
  @Input() position: any;
  @Input() type: any;
  @Input() instanttype: any;
  @Input() isTransfered: any;
  @Output() sendData: EventEmitter<any> = new EventEmitter<any>();
  @ViewChildren('videoPlayer') videoElements: QueryList<ElementRef>;
  public image = ["jpg", "jpeg", "png", "heic"];
  public video = ["video/mp4", "video/webm", "png", "heic"];

  constructor(public dialog: MatDialog, private authService: AuthService, protected _sanitizer: DomSanitizer, private _lightbox: Lightbox, private httpClient: HttpClient) { }

  ngOnInit() {
    if(this.media.type == "fake" && this.media.extension.startsWith('image')){
      console.log("faake "+this.media.extension);
      this.getMediaLinkFace2(this.media);
    }
    //console.log("posi "+this.position);
  }

  openVideo(media: any): void {
    const dialogRef = this.dialog.open(VideoDialog, {
      data: { media: media }
    });
  }

  truncate(value: string, limit = 20, completeWords = false, ellipsis = '...') {
    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(' ');
    }
    let val =  value.length > limit ? value.substr(0, limit) + ellipsis : value;
    return val.replace(/(http?s:\/\/[^\s]+)/g, "<a href='$1' target='__blank'>$1</a>");
  }

  hidingAndshowing(){
  if (this.displayBlock === "block") {
      this.displayBlock = "none";
      if (this.videoElements) {
        this.videoElements.forEach(videoElement => {
          if (videoElement.nativeElement) {
            videoElement.nativeElement.pause();
          }
        });
      }
    } else {
      this.displayBlock = "block";
    }
  }

  getIcon(media, extensionfile, url){
    let filelink = "";
    var extension = media.extension;
    if(extensionfile){
    if(extensionfile.startsWith('xl') || (extensionfile == 'csv')){
      filelink = "../../../../assets/icons/excel.png";
    }else if(extensionfile.startsWith('ppt')){
      filelink = "../../../../assets/icons/ppt.png";
    }else if((extensionfile.startsWith('do') || extensionfile == 'odt')){
      filelink = "../../../../assets/icons/word.png";
    }else if(extensionfile.includes("zip") || extensionfile.includes("rar")){
      filelink = "../../../../assets/icons/zip.png";
    }else if(extensionfile.includes("pdf")){
      filelink = "../../../../assets/icons/pdf.png";
    }else if(extension.includes("image")){
      //filelink = "../../../../assets/icons/image.png";
      filelink = this.getMediaLink(media.contentUrl, media.thumbnailPath);
    }else if(extension.includes("audio")){
      filelink = "../../../../assets/icons/audio.png";
    }else if(extension.includes("video")){
      filelink = "../../../../assets/icons/video.png";
    }else if(extensionfile.startsWith("txt")){
      filelink = "../../../../assets/icons/txt.png";
    } else filelink = "../../../../assets/img/filedoc.png";
  }

    return filelink ? filelink : false;
  }

  getMediaLinkFace2(media): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const result = e.target.result;
          this.mediaLinks[media.id] = result; // Stockez le résultat dans l'objet
          resolve(result);
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(media.file);
    });
  }


  getMediaLinkFace(media){
    if(media.type == "fake" && media.file){
      const reader = new FileReader();
      reader.onload = (e: any) => {
        console.log("target "+e.target.result)
        return e.target.result;
      };

      reader.readAsDataURL(media.file);
    }
  }

  downloadFile(media: any): void {
    console.log("downloaded");
    const link = document.createElement('a');
    link.href = this.getMediaLink(media.contentUrl);
    link.download = media.originalName;  // Spécifiez le nom de fichier souhaité
    link.click();
    link.remove();  // Supprimez le lien après téléchargement
  }
  //**************** */
  getMediaLink(mediaUrl, thumbnailPath=null){
    if(this.instanttype == "yes"|| this.isTransfered =="yes"){
      if(thumbnailPath){
        return this.authService.getMediaDataInstantThumbnail(thumbnailPath);
      } else return this.authService.getMediaDataInstant(mediaUrl);
    } else return this.authService.getMediaData(mediaUrl);
  }

  getDownloadMediaLink(media, thumbnailPath=null){
    console.log("getDownloadMediaLink")
    var mediaId  = media.id;
    if(this.isTransfered =="yes"){
      return this.authService.getDownloadMediaInstant(mediaId, media.originalName);
    } else  {
      this.authService.getDownloadMediaLink(mediaId, media.originalName);
      /*.subscribe(res => {
        console.log(res);
      });*/
    }
  }

  getPdfLink(mediaUrl){
    console.log(this.authService.getMediaData(mediaUrl));
    if(this.instanttype == "yes" || this.isTransfered =="yes"){
      return this._sanitizer.bypassSecurityTrustResourceUrl(this.authService.getMediaDataInstant(mediaUrl));
    } else return this._sanitizer.bypassSecurityTrustResourceUrl(this.authService.getMediaData(mediaUrl));
    //return this._sanitizer.bypassSecurityTrustResourceUrl(this.authService.getMediaData(mediaUrl));

  }

  downloadImg(img){
    //this.authService.downloadImg(img);
    this.authService.telechargerImage();
  }
  /*
  sendDataToParent(data: any) {
    this.sendData.emit(data);
  }
  */

  afficherFichier(media) {
    const url = this.getMediaLink(media.contentUrl)
    this.httpClient.get(url, { headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
    }),responseType: 'blob' }).subscribe(response => {
      const blob = new Blob([response], { type: response.type });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }

  telechargerDocument(media) {
    const url = this.getMediaLink(media.contentUrl)
    this.httpClient.get(url, {responseType: 'blob' }).subscribe(response => {
      const blob = new Blob([response], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = media.originalName; // Remplacez par le nom réel du fichier
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }

  getImage(media: any){
    //this.sendData.emit(media);
   }

  getPdf(media: any){
    //this.sendData.emit(media);
   }
   closeModal(){
    this.selectedPdf=null;
   }

  getMediaThumbail(mediaUrl){
    return this.authService.getMediaThumbail(mediaUrl);
  }

  openWithUrl(url){

    url = this.getMediaLink(url);
    // alert(url)
    const album = {
      src: url
   };
   let albums = [];
   albums.push(album);
   this._lightbox.open(albums, 0);
   setTimeout(() => {
   const lbOuterContainer = document.querySelector('.lb-outerContainer');
    const lbDataContainer = document.querySelector('.lb-dataContainer');
   
    if (lbOuterContainer && lbDataContainer) {
      lbOuterContainer.parentNode.insertBefore(lbDataContainer, lbOuterContainer);
    }
  }, 100);

   setTimeout(() => {
    document.querySelector( 'img.lb-image' ).removeAttribute("hidden");
    if(document.querySelector( '.lb-loader' )) document.querySelector( '.lb-loader' ).setAttribute("hidden", "true");
    if(document.querySelector( '.lb-nav' )) document.querySelector( '.lb-nav' ).setAttribute("hidden", "true");
   }, 1500);

  }

  openWithData(file){

    const reader = new FileReader();
    reader.onload = (e: any) => {
       //console.log('csv content', e.target.result);
       const album = {
          src: e.target.result
       };
       let albums = [];
       albums.push(album);
       this._lightbox.open(albums, 0);

       setTimeout(() => {
        document.querySelector( 'img.lb-image' ).removeAttribute("hidden");
        if(document.querySelector( '.lb-loader' )) document.querySelector( '.lb-loader' ).setAttribute("hidden", "true");
        if(document.querySelector( '.lb-nav' )) document.querySelector( '.lb-nav' ).setAttribute("hidden", "true");
       }, 1500);

    };
    reader.readAsDataURL(file);

  }


  createFileUrl(url, source){
    source.href = URL.createObjectURL(this.getMediaLink(url));
  }


}
